import List from "@mui/joy/List"
import ListItem from "@mui/joy/ListItem"
import ListItemButton from "@mui/joy/ListItemButton"
import ListItemContent from "@mui/joy/ListItemContent"
import ListItemDecorator from "@mui/joy/ListItemDecorator"
import Typography from "@mui/joy/Typography"
import { useTranslation } from "react-i18next"
import { getWallet, type WalletType } from "@levana/chain/wallet"
import { UAParser } from "ua-parser-js"
import { useState } from "react"

import InstallIcon from "@common/icons/InstallIcon"

import ConnectAvatar from "./ConnectAvatar"

interface ConnectListProps {
  walletTypes: WalletType[]
  onConnectorClick: (walletType: WalletType) => void
}

const ConnectList = (props: ConnectListProps) => {
  const { t } = useTranslation("common")
  const [retryWalletType, setRetryWalletType] = useState<WalletType>()

  const handleInstall = (walletType: WalletType) => {
    setRetryWalletType(walletType)
    openInstallLink(walletType)
  }

  return (
    <List
      sx={(theme) => ({
        "--List-gap": theme.spacing(1.5),
        "--ListItem-gap": "auto",
        "--ListItem-minHeight": "3.6rem",
        "--ListItem-radius": theme.radius.sm,
      })}
    >
      {props.walletTypes.map((walletType) => {
        const wallet = getWallet(walletType)
        const isInstallable = !wallet.provider().installed

        return (
          <ListItem key={walletType} variant="outlined">
            <ListItemButton
              onClick={() =>
                isInstallable
                  ? handleInstall(walletType)
                  : props.onConnectorClick(walletType)
              }
              sx={{ gap: 2 }}
              aria-label={`${wallet.info.name} wallet`}
            >
              <ListItemDecorator>
                <ConnectAvatar icon={wallet.info.icon} />
              </ListItemDecorator>
              <ListItemContent>
                <Typography>{wallet.info.name}</Typography>
                {retryWalletType === walletType && (
                  <Typography level="body-xs" textColor="text.tertiary">
                    {t("wallet.dialog.refresh")}
                  </Typography>
                )}
              </ListItemContent>
              {isInstallable && <InstallIcon color="success" />}
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

const openInstallLink = (walletType: WalletType) => {
  const walletInfo = getWallet(walletType).info
  const userAgent = new UAParser()

  const url = (() => {
    switch (userAgent.getOS().name) {
      case "Android":
        return walletInfo.install.android
      case "iOS":
        return walletInfo.install.ios
    }

    switch (userAgent.getBrowser().name) {
      case "Edge":
        return walletInfo.install.edge
      case "Firefox":
        return walletInfo.install.firefox
      default:
        return walletInfo.install.chrome
    }
  })()

  window.open(url ?? walletInfo.install.chrome, "_blank")
}

export default ConnectList
