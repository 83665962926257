import Avatar from "@mui/joy/Avatar"
import type { SvgIconProps } from "@mui/joy/SvgIcon"

import WalletIcon from "@common/icons/WalletIcon"

interface ConnectAvatarProps {
  icon:
    | string
    | React.JSXElementConstructor<SvgIconProps>
    | React.JSXElementConstructor<Partial<JSX.Element>>
}

const ConnectAvatar = (props: ConnectAvatarProps) => {
  const src = typeof props.icon === "string" ? props.icon : undefined

  return (
    <Avatar
      variant="plain"
      src={src}
      sx={({ spacing }) => ({
        "--Avatar-radius": spacing(1),
        "& svg": {
          width: "100%",
          height: "100%",
        },
      })}
    >
      {props.icon !== "string" ? (
        <props.icon
          fontSize="xl2"
          sx={({ palette }) => ({ color: palette.text.primary })}
        />
      ) : (
        <WalletIcon
          fontSize="xl2"
          sx={({ palette }) => ({ color: palette.text.secondary })}
        />
      )}
    </Avatar>
  )
}

export default ConnectAvatar
