import { createStore, type StoreApi } from "zustand"

import type { Chain } from "@future/chain/types"
import type { FullMarketInfo } from "@perps/sdk/client/CosmosClient"
import type { Route, RouteState } from "@future/router/types"
import type { MarketId } from "@perps/sdk/types"

import type { TargetInfo } from "../target/info"
import type { MarketLookup } from "./createMarketLookup"

export type ContextStoreStateKey = "initial" | "standard"

export interface ContextStoreProp<State extends ContextStoreStateKey> {
  contextStore: ContextStore<State>
}

export type ContextStore<State extends ContextStoreStateKey> = StoreApi<
  ContextStoreState<State>
>

interface ContextStoreActions {
  isMarketRoute: () => boolean
}

interface InitialContextStoreProps {
  route: Route
  routeState: RouteState
  targetInfo: TargetInfo
}

export type InitialContextStoreState = InitialContextStoreProps &
  ContextStoreActions

export interface StandardContextStoreProps extends InitialContextStoreProps {
  chain: Chain
  /**
   * A map of all the markets for the current chain
   *
   * The `markets` data is regularly updated, so this object should not be used
   * for memoization checks.
   */
  markets: Map<MarketId, FullMarketInfo>
  /**
   * Lookup a market from alternative identities
   *
   * This object will only change if the `markets` identities have changed. It's
   * safe to use for memoization checks.
   */
  marketLookup: MarketLookup
  /**
   * When on a market page, this value will come from the current market. On
   * other pages this value will come from the most recently visited market or
   * the default market.
   */
  fullMarketInfo: FullMarketInfo
}

export type StandardContextStoreState = StandardContextStoreProps &
  ContextStoreActions

type InternalContextStoreProps = InitialContextStoreProps &
  Partial<StandardContextStoreProps>

type InternalContextStoreState = InternalContextStoreProps & ContextStoreActions

export type ContextStoreState<State extends ContextStoreStateKey> =
  State extends "initial"
    ? InitialContextStoreState
    : State extends "standard"
      ? StandardContextStoreState
      : never

export const createContextStore = (initProps: InternalContextStoreProps) => {
  return createStore<InternalContextStoreState>()((_set, get) => ({
    ...initProps,
    isMarketRoute: () => {
      const { routeState } = get()
      return isMarketRoute(routeState.rootId)
    },
  }))
}

export const isStandardContextState = (
  state: InitialContextStoreState,
): state is StandardContextStoreState => {
  const standardState = state as StandardContextStoreState
  return (
    isStandardRoute(standardState.routeState.rootId) &&
    "fullMarketInfo" in standardState
  )
}

export const isStandardContextStore = (
  store: ContextStore<"initial">,
): store is ContextStore<"standard"> => {
  return isStandardContextState(store.getState())
}

const isStandardRoute = (rootId: RouteState["rootId"]): boolean => {
  switch (rootId) {
    case "earnList":
    case "earnMarket":
    case "history":
    case "leaderboard":
    case "markets":
    case "stats":
    case "trade":
      return true
    case "unknown":
      return false
  }
}

const isMarketRoute = (rootId: RouteState["rootId"]): boolean => {
  switch (rootId) {
    case "earnMarket":
    case "leaderboard":
    case "trade":
      return true
    case "earnList":
    case "history":
    case "markets":
    case "stats":
    case "unknown":
      return false
  }
}
