import Box from "@mui/joy/Box"

import FooterLegal from "../FooterLegal"

const FooterLayout = () => {
  return (
    <Box
      sx={{
        pt: 6,
        pb: { xs: 10, md: 2 },
        display: "flex",
        justifyContent: "center",
      }}
    >
      <FooterLegal />
    </Box>
  )
}

export default FooterLayout
