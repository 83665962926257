const walletInfo = {
    name: "Wallet Connect",
    icon: "", // TODO:
    install: {
        chrome: "", // TODO:
    },
    isChainSupported: () => {
        return true;
    },
    errors: {
        generic: [],
    },
};
export const walletConnectWallet = {
    provider: () => {
        const installed = false;
        return {
            installed,
        };
    },
    info: walletInfo,
};
