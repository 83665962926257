import type { RefObject, PropsWithChildren } from "react"
import {
  type UseFormReturn,
  FormProvider,
  type FieldValues,
  type SubmitHandler,
  type SubmitErrorHandler,
} from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Report } from "@future/libs/error/report"
import { AppError } from "@future/libs/error/AppError"

export interface FormProps<Values extends FieldValues>
  extends PropsWithChildren {
  form: UseFormReturn<Values>
  onSubmit: SubmitHandler<Values>
  formRef?: RefObject<HTMLFormElement>
  id?: string
}

const Form = <Values extends FieldValues>(props: FormProps<Values>) => {
  const { id, form, formRef, onSubmit, children } = props
  const { t } = useTranslation("error")

  const onInvalidSubmit: SubmitErrorHandler<Values> = (errors) => {
    const { dirtyFields, isDirty, isValid } = form.formState

    const error = AppError.fromKey("form.invalid", {
      level: "fatal",
      extra: {
        values: form.getValues(),
        dirtyFields,
        isDirty,
        isValid,
        errors,
      },
    })

    Report.error(error, t)
  }

  return (
    <FormProvider {...form}>
      <form
        id={id}
        ref={formRef}
        onSubmit={form.handleSubmit(onSubmit, onInvalidSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
