import Drawer from "@mui/joy/Drawer"
import DialogContent from "@mui/joy/DialogContent"
import DialogTitle from "@mui/joy/DialogTitle"
import ModalClose from "@mui/joy/ModalClose"

import ConsoleView from "./ConsoleView"
import { useDebugStore } from "./store"

const ConsoleDrawer = () => {
  const consoleVisible = useDebugStore((state) => state.consoleVisible)
  const closeConsole = useDebugStore((state) => state.closeConsole)

  return (
    <Drawer size="lg" open={consoleVisible} onClose={closeConsole}>
      <ModalClose />
      <DialogTitle>Console</DialogTitle>
      <DialogContent>
        <ConsoleView />
      </DialogContent>
    </Drawer>
  )
}

export default ConsoleDrawer
