export const termsOfServiceUrl =
  "https://static.levana.finance/legal/terms-of-service.pdf"
export const privacyPolicyUrl =
  "https://static.levana.finance/legal/privacy-policy.pdf"
export const disclaimerUrl =
  "https://static.levana.finance/legal/disclaimer.pdf"
export const riskDisclosureUrl =
  "https://static.levana.finance/legal/risk-disclosure.pdf"
export const competitionTermsAndConditionsUrl =
  "https://levanaexchange.zendesk.com/hc/en-us/articles/15517378302363"
