// private typechecking and keeping everything in sync with the taxonomy doc
export enum EventName {
  CONNECT_WALLET = "connect_wallet",
  TAP_FAUCET = "tap_faucet",
  OPEN_POSITION = "open_position",
  UPDATE_POSITION = "update_position",
  CLOSE_POSITION = "close_position",
  DEPOSIT_LIQUIDITY = "deposit_liquidity",
  VIEW_PAGE = "view_page",
  ERROR_OCCURRED = "error_occurred",
  OPEN_LIMIT_ORDER = "open_limit_order",
  CANCEL_LIMIT_ORDER = "cancel_limit_order",
  VIEW_TAKE_PROFIT_EXPLAINER = "view_take_profit_explainer",
  VIEW_STOP_LOSS_EXPLAINER = "view_stop_loss_explainer",
  VIEW_ESTIMATED_PNL_EXPLAINER = "view_estimated_pnl_explainer",
  INITIATE_OPEN_POSITION = "initiate_open_position",
  INITIATE_UPDATE_POSITION = "initiate_update_position",
  INITIATE_CLOSE_POSITION = "initiate_close_position",
  INITIATE_OPEN_LIMIT_ORDER = "initiate_open_limit_order",
  INITIATE_CANCEL_LIMIT_ORDER = "initiate_cancel_limit_order",
  CHANGE_COLLATERAL_INPUT_CURRENCY = "change_collateral_input_currency",
  USE_MAX_COLLATERAL = "use_max_collateral",
  SELECT_ORDER_TYPE = "select_order_type",
  SELECT_DIRECTION = "select_direction",
  OPEN_WALLET_MENU = "open_wallet_menu",
  COPY_ADDRESS = "copy_address",
  DISCONNECT_WALLET = "disconnect_wallet",
  INITIATE_TAP_FAUCET = "initiate_tap_faucet",
  OPEN_OVERFLOW_MENU = "open_overflow_menu",
  VIEW_SETTINGS = "view_settings",
  CHANGE_DISPLAY_MODE = "change_display_mode",
  ADJUST_MAX_SLIPPAGE = "adjust_max_slippage",
  VIEW_TRADE_PAGE = "view_trade_page",
  VIEW_EARN_PAGE = "view_earn_page",
  VIEW_POOL_PAGE = "view_pool_page",
  VIEW_HISTORY_PAGE = "view_history_page",
  VIEW_LEARN_PAGE = "view_learn_page",
  VIEW_STATS_PAGE = "view_stats_page",
  INITIATE_DEPOSIT_LIQUIDITY = "initiate_deposit_liquidity",
  INITIATE_REINVEST_YIELD = "initiate_reinvest_yield",
  REINVEST_YIELD = "reinvest_yield",
  INITIATE_WITHDRAW_LIQUIDITY = "initiate_withdraw_liquidity",
  WITHDRAW_LIQUIDITY = "withdraw_liquidity",
  VIEW_TRADE_HISTORY_DETAILS = "view_trade_history_details",
  VIEW_PNL_CELEBRATION = "view_pnl_celebration",
  SHARE_PNL_CELEBRATION = "share_pnl_celebration",
  VIEW_PNL_SHARE_PAGE = "view_pnl_share_page",
  VIEW_TOS_MODAL = "view_tos_modal",
  APPROVE_TOS_MODAL = "approve_tos_modal",
  VIEW_FEE_BREAKDOWN = "view_fee_breakdown",
  INITIATE_WALLET_CONNECTION = "initiate_wallet_connection",
  SELECT_WALLET = "select_wallet",
  OPEN_MARKETS_MENU = "open_markets_menu",
  SELECT_MARKET = "select_market",
  SELECT_STATS_NETWORK = "select_stats_network",
  SELECT_STATS_MARKET = "select_stats_market",
  SWITCH_TAKE_PROFIT_ENTRY_METHOD = "switch_take_profit_entry_method",
  SWITCH_STOP_LOSS_ENTRY_METHOD = "switch_stop_loss_entry_method",
  LIMIT_ORDER_TRIGGERED = "limit_order_triggered",
  TAKE_PROFIT_TRIGGERED = "take_profit_triggered",
  STOP_LOSS_TRIGGERED = "stop_loss_triggered",
  LIQUIDATION_TRIGGERED = "liquidation_triggered",
  CHANGE_OLD_WALLET_SETTING = "change_old_wallet_setting",
  CLICK_APR_PROMO_BUTTON = "click_apr_promo_button",
  VIEW_NETWORKS = "view_networks",
  SELECT_NETWORK = "select_network",
  WITHDRAW_YIELD = "withdraw_yield",
  CONVERT_XLP_TO_LP = "convert_xlp_to_lp",
  GEOBLOCKED = "geoblocked",
  OPENED_BRIDGE = "opened_bridge",
  OPENED_BRIDGE_MULTI_CHOICE = "opened_bridge_multi_choice",
}

export enum EventKey {
  RESULT = "result",
  DIRECTION = "direction",
  MARKET = "market",
  COLLATERAL_AMOUNT_USD = "collateral_amount",
  POSITION_ID = "position_id",
  DEPOSIT_COLLATERAL_SIGN = "deposit_collateral_sign",
  POSITION_SIZE = "position_size",
  LEVERAGE = "leverage",
  STOP_LOSS_PRICE = "stop_loss_price",
  AMOUNT = "amount",
  TOKEN = "token",
  DOMAIN = "domain",
  CHAIN = "chain",
  URL_PATH = "url_path",
  NETWORK = "network",
  MESSAGE_TO_USER = "message_to_user",
  FRONTEND_ERROR_ID = "frontend_error_id",
  CONTRACT_ERROR_ID = "contract_error_id",
  CONTRACT_MESSAGE = "contract_message",
  REASON = "reason",
  FAILURE_REASON = "failure_reason",
  LIMIT_PRICE = "limit_price",
  PNL = "pnl",
  TAKE_PROFIT_PRICE = "take_profit_price",
  RAW_ERROR_MESSAGE = "raw_error_message",
  SOURCE = "source",
  ORDER_TYPE = "order_type",
  DISPLAY_MODE = "display_mode",
  SLIPPAGE_AMOUNT = "slippage_amount",
  OLD_WALLET_SETTING = "old_wallet_setting",
  APR = "apr",
  COUNTRY_CODE = "country_code",
  KIND = "kind",
  URL = "url",
  WALLET = "wallet",
}

export enum EventValue {
  SUCCESS = "success",
  FAILURE = "failure",
}
