import Typography, { type TypographyProps } from "@mui/joy/Typography"
import type { ReactNode } from "react"

import ArrowDropUp from "@common/icons/ArrowDropUp"
import ArrowDropDown from "@common/icons/ArrowDropDown"
import type { Percentage } from "@future/numerics"

export interface PriceChangeProps extends TypographyProps {
  percentage: Percentage
  timePeriod: ReactNode
}

const PriceChange = (props: PriceChangeProps) => {
  return (
    <Typography
      color={props.percentage.isLessThan(0) ? "danger" : "success"}
      sx={{ display: "flex", alignItems: "center" }}
    >
      {props.percentage.isLessThan(0) ? (
        <ArrowDropDown size="sm" />
      ) : (
        <ArrowDropUp size="sm" />
      )}
      {props.percentage.toAsset()} ({props.timePeriod})
    </Typography>
  )
}

export default PriceChange
