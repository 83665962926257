import { localStorageStateBuilder } from "@common/storage/localStorage"
import type { ChainName, TargetId } from "@common/target/types"
import { MarketSlug } from "@future/market/config/constants"
import type { TargetInfo, TargetSlug } from "@future/target/info"
import {
  chainNetworkIdToChainName,
  targetIdToChainNetworkId,
} from "@future/chain/utils"
import { domainConfig } from "@future/target/domain"

/**
 * @deprecated use defaultMarketSlug2
 */
const defaultMarketSlug_DEPRECATED = (chainName: ChainName) => {
  return MarketSlug.get(
    (() => {
      switch (chainName) {
        case "sei":
          return "SEI_USD"
        case "injective":
          return "INJ_USD"
        case "osmosis":
          return "ATOM_USD"
        case "neutron":
          return "NTRN_USD"
        case "dymension":
          return "DYM_USD"
      }
    })(),
  )
}

export const defaultMarketSlug = (targetId: TargetId) => {
  return MarketSlug.get(
    (() => {
      switch (targetId) {
        case "dymbeta":
          return "DYM_USD"
        case "injdebug":
        case "injbeta":
        case "injmainnet1":
          return "INJ_USD"
        case "ntrnmainnet1":
        case "ntrndev":
        case "ntrnbeta":
          return "NTRN_USD"
        case "osmomainnet1":
        case "osmobeta":
        case "osmodev":
        case "osmodebug":
        case "osmoci":
        case "osmoqa":
          return "ATOM_USD"
        case "seimainnet1":
        case "seibeta":
        case "seidev":
        case "seici":
        case "seidebug":
          return "SEI_USD"
      }
    })(),
  )
}

/**
 * Visited Target slug
 *
 * Should only be used with router loaders.
 */
export const visitedTargetSlugStorage =
  localStorageStateBuilder<TargetSlug | null>("VisitedTargetSlug", null)

const visitedTargetMarketSlugKey = "VisitedTargetMarketSlug"

/**
 * Maps from target slug to market slug
 */
export const visitedTargetMarketSlugStorage = localStorageStateBuilder<
  Record<string, string>
>(visitedTargetMarketSlugKey, {} as Record<string, string>)

export const getDefaultTargetInfo = (): TargetInfo => {
  const storedSlug = visitedTargetSlugStorage.get()
  if (storedSlug === null) {
    return domainConfig.defaultTargetInfo
  }
  return (
    domainConfig.getTargetBySlug(storedSlug) ?? domainConfig.defaultTargetInfo
  )
}

export const getDefaultMarketSlug = (targetInfo: TargetInfo): MarketSlug => {
  const targetMarketMap = visitedTargetMarketSlugStorage.get()
  if (targetInfo.slug in targetMarketMap) {
    const marketSlugStr = targetMarketMap[targetInfo.slug]
    if (typeof marketSlugStr === "string") {
      return MarketSlug.get(marketSlugStr)
    }
  }

  const chainNetworkId = targetIdToChainNetworkId(targetInfo.id)
  const chainName = chainNetworkIdToChainName(chainNetworkId)
  return defaultMarketSlug_DEPRECATED(chainName)
}
