import type { ChainInfo } from "@keplr-wallet/types"

import { chainConfigMap } from "@common/target/constants"
import type { ChainName, ChainNetworkId } from "@common/target/types"
import type { TargetId } from "@common/target/types"
import { createChainInfo } from "@future/libs/chain/cosmosKit/utils"
import { domainConfig } from "@future/target/domain"

export const targetIdToChainNetworkId = (
  targetId: TargetId,
): ChainNetworkId => {
  switch (targetId) {
    case "dymbeta":
      return "dymension-testnet"
    case "injbeta":
    case "injdebug":
      return "injective-testnet"
    case "injmainnet1":
      return "injective-mainnet"
    case "ntrnbeta":
    case "ntrndev":
      return "neutron-testnet"
    case "ntrnmainnet1":
      return "neutron-mainnet"
    case "osmobeta":
    case "osmoci":
    case "osmodebug":
    case "osmodev":
    case "osmoqa":
      return "osmosis-testnet"
    case "osmomainnet1":
      return "osmosis-mainnet"
    case "seibeta":
    case "seici":
    case "seidebug":
    case "seidev":
      return "sei-testnet"
    case "seimainnet1":
      return "sei-mainnet"
  }
}

export const chainNetworkIdToChainName = (
  network: ChainNetworkId,
): ChainName => {
  switch (network) {
    case "osmosis-mainnet":
    case "osmosis-testnet":
      return "osmosis"
    case "sei-mainnet":
    case "sei-testnet":
      return "sei"
    case "injective-testnet":
    case "injective-mainnet":
      return "injective"
    case "neutron-testnet":
    case "neutron-mainnet":
      return "neutron"
    case "dymension-testnet":
      return "dymension"
  }
}

export const chainNetworkIdToTargetId = (network: ChainNetworkId): TargetId => {
  switch (network) {
    case "osmosis-mainnet":
      return "osmomainnet1"
    case "osmosis-testnet":
      return "osmodev"
    case "sei-mainnet":
      return "seimainnet1"
    case "sei-testnet":
      return "seidev"
    case "injective-testnet":
      return "injbeta"
    case "injective-mainnet":
      return "injmainnet1"
    case "neutron-testnet":
      return "ntrndev"
    case "neutron-mainnet":
      return "ntrnmainnet1"
    case "dymension-testnet":
      return "dymbeta"
  }
}

const chainInfoMap = domainConfig.availableTargets.reduce(
  (map, targetInfo) => {
    const chainNetworkId = targetIdToChainNetworkId(targetInfo.id)
    const chainConfig = chainConfigMap[chainNetworkId]

    if (!map[chainConfig.chainId]) {
      map[chainConfig.chainId] = createChainInfo(chainConfig)
    }

    return map
  },
  {} as Record<string, ChainInfo>,
)

export const chainInfos = Object.values(chainInfoMap)
