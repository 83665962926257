import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const BridgeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M4 8C3.44772 8 3 8.44772 3 9V16C3 16.5523 3.44772 17 4 17H4.92864C5.35243 17 5.72367 16.7293 5.90617 16.3469C6.99149 14.0722 9.31164 12.5 12 12.5C14.6884 12.5 17.0085 14.0722 18.0938 16.3469C18.2763 16.7293 18.6476 17 19.0714 17H20C20.5523 17 21 16.5523 21 16V9C21 8.44772 20.5523 8 20 8H4Z" />
    </SvgIcon>
  )
}

export default BridgeIcon
