import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const LearnIcon = (props: SvgIconProps) => {
  const Svg = () => (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7587 13.7566C16.3178 14.1843 16.0096 14.7483 16.0096 15.3626C16.0096 15.7146 15.7242 16 15.3722 16H8.62782C8.2758 16 7.99043 15.7146 7.99043 15.3626C7.99043 14.7483 7.68224 14.1843 7.24129 13.7566C6.0114 12.5637 5.25 10.9115 5.25 9.08583C5.25 5.44858 8.27208 2.5 12 2.5C15.7279 2.5 18.75 5.44858 18.75 9.08583C18.75 10.9115 17.9886 12.5637 16.7587 13.7566ZM11.7517 6.25743C12.1651 6.23266 12.4802 5.87739 12.4555 5.46392C12.4307 5.05045 12.0754 4.73534 11.662 4.76012C10.9739 4.80134 10.3014 4.96799 9.68276 5.25056C9.06411 5.53313 8.51146 5.92608 8.05636 6.40697C7.60125 6.88787 7.2526 7.4473 7.03033 8.0533C6.80806 8.65931 6.7165 9.30004 6.76089 9.9389C6.78961 10.3521 7.14786 10.6638 7.56108 10.6351C7.9743 10.6064 8.286 10.2481 8.25729 9.83492C8.22766 9.40851 8.28846 8.97914 8.43859 8.56983C8.58884 8.16018 8.82715 7.77475 9.14583 7.43802C9.46475 7.10102 9.85785 6.81965 10.306 6.61497C10.7542 6.41024 11.2456 6.28775 11.7517 6.25743Z"
      />
      <path d="M7.5 17.875C7.5 17.2537 8.00368 16.75 8.625 16.75H15.375C15.9963 16.75 16.5 17.2537 16.5 17.875C16.5 18.4963 15.9963 19 15.375 19H8.625C8.00368 19 7.5 18.4963 7.5 17.875Z" />
      <path d="M8.25 19.75H15.75C15.75 20.9926 14.7426 22 13.5 22H10.5C9.25736 22 8.25 20.9926 8.25 19.75Z" />
    </g>
  )

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <Svg />
    </SvgIcon>
  )
}

export default LearnIcon
