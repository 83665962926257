import { create } from "zustand"
import { persist } from "zustand/middleware"

import { Amount } from "@future/numerics/amount"
import { Usd } from "@future/numerics"
import type { TargetId } from "@common/target/types"
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "@common/storage/localStorage"

type WalletAddress = string

interface SettingsStoreProps {
  defaultSlippage: () => string
  slippages: Record<WalletAddress, string>
  slippage: (walletAddress: WalletAddress) => string
  setSlippage: (walletAddress: WalletAddress, slippage: string) => void
  chainSlippage: (walletAddress: WalletAddress) => string

  defaultReserveCollateral: () => string
  reserveCollaterals: Record<WalletAddress, string>
  reserveCollateral: (walletAddress: WalletAddress) => string
  setReserveCollateral: (
    walletAddress: WalletAddress,
    reserveCollateral: string,
  ) => void
  reserveCollateralUsd: (walletAddress: WalletAddress) => Usd

  endpoints: Record<string, string>
  endpoint: (targetId: TargetId) => string
  setEndpoint: (targetId: TargetId, endpoint: string) => void

  defaultGas: () => string
  gases: Record<string, string>
  gas: (chainId: string) => string
  setGas: (chainId: string, gas: string) => void

  defaultGasMultiplier: () => string
  gasMultipliers: Record<string, string>
  gasMultiplier: (chainId: string) => string
  setGasMultiplier: (chainId: string, gasMultiplier: string) => void
}

export const useSettingsStore = create<SettingsStoreProps>()(
  persist(
    (set, get) => ({
      defaultSlippage: () => "0.5",
      slippages: {},
      slippage: (walletAddress) => {
        const { slippages } = get()
        if (walletAddress in slippages) {
          return slippages[walletAddress]
        }
        // Migrate local storage
        const key = `SettingsMaxSlippage-${walletAddress}`
        const slippage = getLocalStorageItem(key)
        if (slippage && typeof slippage === "string") {
          get().setSlippage(walletAddress, slippage)
          removeLocalStorageItem(key)
          return slippage
        }
        return ""
      },
      setSlippage: (walletAddress, slippage) => {
        const slippages = { ...get().slippages }
        slippages[walletAddress] = slippage
        set({ slippages })
      },
      chainSlippage: (walletAddress) => {
        const amount = get().slippages[walletAddress] || get().defaultSlippage()
        return new Amount(amount).divide(100).toChain()
      },

      defaultReserveCollateral: () => "1",
      reserveCollaterals: {},
      reserveCollateral: (walletAddress: WalletAddress) => {
        const { reserveCollaterals } = get()
        if (walletAddress in reserveCollaterals) {
          return reserveCollaterals[walletAddress]
        }
        // Migrate local storage
        const key = `ReserveCollateralUsd-${walletAddress}`
        const reserveCollateral = getLocalStorageItem(key)
        if (reserveCollateral && typeof reserveCollateral === "string") {
          get().setReserveCollateral(walletAddress, reserveCollateral)
          removeLocalStorageItem(key)
          return reserveCollateral
        }
        return ""
      },
      setReserveCollateral: (walletAddress, reserveCollateral) => {
        const reserveCollaterals = { ...get().reserveCollaterals }
        reserveCollaterals[walletAddress] = reserveCollateral
        set({ reserveCollaterals })
      },
      reserveCollateralUsd: (walletAddress) => {
        const reserveCollateral =
          get().reserveCollaterals[walletAddress] ||
          get().defaultReserveCollateral()
        return new Usd(reserveCollateral)
      },

      endpoints: {},
      endpoint: (targetId) => {
        const { endpoints } = get()
        if (targetId in endpoints) {
          return endpoints[targetId]
        }
        const endpoint = useRpcEndpointStore.getState().endpoint(targetId)
        if (endpoint) {
          get().setEndpoint(targetId, endpoint)
          return endpoint
        }
        return ""
      },
      setEndpoint: (targetId, endpoint) => {
        const endpoints = { ...get().endpoints }
        endpoints[targetId] = endpoint
        set({ endpoints })
      },

      defaultGas: () => "",
      gases: {},
      gas: (chainId) => {
        const { gases } = get()
        if (chainId in gases) {
          return gases[chainId]
        }
        // Migrate local storage
        const key = `SettingsGasPriceOverride-${chainId}`
        const gas = getLocalStorageItem(key)
        if (gas && typeof gas === "string") {
          get().setGas(chainId, gas)
          removeLocalStorageItem(key)
          return gas
        }
        return ""
      },
      setGas: (chainId, gas) => {
        const gases = { ...get().gases }
        gases[chainId] = gas
        set({ gases })
      },

      defaultGasMultiplier: () => "",
      gasMultipliers: {},
      gasMultiplier: (chainId) => {
        const { gasMultipliers } = get()
        if (chainId in gasMultipliers) {
          return gasMultipliers[chainId]
        }
        // Migrate local storage
        const key = `SettingsGasMultiplierOverride-${chainId}`
        const gasMultiplier = getLocalStorageItem(key)
        if (gasMultiplier && typeof gasMultiplier === "string") {
          get().setGasMultiplier(chainId, gasMultiplier)
          removeLocalStorageItem(key)
          return gasMultiplier
        }
        return ""
      },
      setGasMultiplier: (chainId, gasMultiplier) => {
        const gasMultipliers = { ...get().gasMultipliers }
        gasMultipliers[chainId] = gasMultiplier
        set({ gasMultipliers })
      },
    }),
    { name: "LevanaSettings" },
  ),
)

interface UseRpcEndpointStoreProps {
  endpoints: Record<string, string>
  endpoint: (targetId: TargetId) => string
}

/**
 * @deprecated
 */
const useRpcEndpointStore = create<UseRpcEndpointStoreProps>()(
  persist(
    (_set, get) => ({
      endpoints: {},
      endpoint: (targetId) => get().endpoints[targetId] ?? "",
    }),
    { name: "RpcEndpoint" },
  ),
)
