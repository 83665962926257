import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const EarnIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0469 21.75C17.4317 21.75 21.7969 17.3848 21.7969 12C21.7969 6.61522 17.4317 2.25 12.0469 2.25C6.6621 2.25 2.29688 6.61522 2.29688 12C2.29688 17.3848 6.6621 21.75 12.0469 21.75ZM12.0469 20.25C16.6032 20.25 20.2969 16.5563 20.2969 12C20.2969 7.44365 16.6032 3.75 12.0469 3.75C7.49053 3.75 3.79688 7.44365 3.79688 12C3.79688 16.5563 7.49053 20.25 12.0469 20.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0479 19.2157C16.0331 19.2157 19.2638 15.9851 19.2638 11.9998C19.2638 8.0146 16.0331 4.78394 12.0479 4.78394C8.0627 4.78394 4.83203 8.0146 4.83203 11.9998C4.83203 15.9851 8.0627 19.2157 12.0479 19.2157ZM11.1836 17.4104H12.7366L12.7451 16.4347C14.574 16.2953 15.465 15.3196 15.4692 13.8539C15.465 12.4136 14.3795 11.6491 12.8758 11.3112C12.2546 11.2163 10.717 10.8069 10.7301 10.0525C10.7301 8.66547 13.4662 8.77799 13.5981 10.1623H15.351C15.3298 8.76422 14.3544 7.76317 12.7282 7.58577L12.7366 6.59317L11.2005 6.59317L11.1921 7.57732C10.7725 7.64379 10.4068 7.75717 10.0962 7.93184C8.55126 8.80069 8.75334 11.4824 10.3561 12.2392C10.6066 12.3575 10.8909 12.4549 11.2116 12.5319C11.3368 12.5619 11.4819 12.5904 11.6376 12.6209C12.4736 12.7851 13.616 13.0093 13.6234 13.8497C13.6165 14.6878 12.7572 14.8677 12.0944 14.8677C11.276 14.8677 10.4595 14.5664 10.3964 13.609H8.6266C8.66884 15.3154 9.40117 16.2742 11.1921 16.4305L11.1836 17.4104Z"
      />
    </SvgIcon>
  )
}

export default EarnIcon
