// this is for the case where a contract does not have a pyth feed configured
// the frontend still needs to display a price

// so we manually construct the pyth price source here
export const mockPythPriceSource = (marketId: string) => {
  const { feeds, feeds_usd } = (() => {
    switch (marketId) {
      case "ATOM_USD":
        return {
          feeds: [
            {
              id: "b00b60f88b03a6a625a8d1c048c3f66653edf217439983d037e7222c4e612819",
              inverted: false,
            },
          ],
          feeds_usd: [],
        }

      case "OSMO_USD":
        return {
          feeds: [
            //OSMO/USD
            {
              id: "5867f5683c757393a0670ef0f701490950fe93fdb006d181c8265a831ac0c5c6",
              inverted: false,
            },
          ],
          feeds_usd: [],
        }

      case "OSMO_USDC":
        return {
          feeds: [
            //OSMO/USD
            {
              id: "5867f5683c757393a0670ef0f701490950fe93fdb006d181c8265a831ac0c5c6",
              inverted: false,
            },
            // inverted USDC/USD
            {
              id: "eaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a",
              inverted: true,
            },
          ],

          feeds_usd: [
            {
              id: "5867f5683c757393a0670ef0f701490950fe93fdb006d181c8265a831ac0c5c6",
              inverted: false,
            },
          ],
        }

      case "ETH_BTC":
        return {
          feeds: [
            // ETH/USD
            {
              id: "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
              inverted: false,
            },
            // inverted BTC/USD
            {
              id: "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
              inverted: true,
            },
          ],
          feeds_usd: [
            {
              id: "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
              inverted: false,
            },
          ],
        }

      case "ETH_USD":
      case "axlETH_USD":
        return {
          feeds: [
            {
              id: "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
              inverted: false,
            },
          ],
          feeds_usd: [],
        }

      case "BTC_USD":
        return {
          feeds: [
            {
              id: "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
              inverted: false,
            },
          ],
          feeds_usd: [],
        }

      case "SEI_USD":
        return {
          feeds: [
            {
              id: "53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb",
              inverted: false,
            },
          ],
          feeds_usd: [],
        }

      default:
        return { feeds: [], feeds_usd: [] }
    }
  })()

  if (!feeds.length) {
    throw new Error(
      "Could not construct a mock pyth price source for this market",
    )
  }

  return {
    feeds,
    feeds_usd: feeds_usd.length ? feeds_usd : undefined,
  }
}
