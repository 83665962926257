/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  type DefaultValues,
  type FieldValues,
  type Path,
  type SubmitHandler,
  type UseFormReturn,
  useForm,
} from "react-hook-form"

import type {
  FormFieldState,
  FormFields,
  FormInputFieldState,
  FormPlaceholderValues,
  ValidateIssue,
} from "@future/libs/form/utils"
import { notification } from "@future/notification"
import { type Breadcrumb, Report } from "@future/libs/error/report"
import { AppError } from "@future/libs/error/AppError"

export type FormControllerOnSubmit<Values extends FieldValues> = (
  values: Values,
  event?: React.BaseSyntheticEvent,
) => Promise<FormSubmitResponse>

export type FormSubmitResponse =
  | { success: string }
  | { transactionHash: string }
  | null

export interface UseFormControllerProps<
  Values extends FieldValues,
  PlaceholderValues extends FieldValues,
> {
  defaultValues: Values
  placeholderValues?: FormPlaceholderValues<PlaceholderValues>
  onSubmit: FormControllerOnSubmit<Values>
  keepValuesAfterSubmit?: Path<Values>[]
  // TODO: once the transactions are all replace by mutations, than this explorer prop wont be needed since notifications will be handled by the queryClient cache callback
  explorerHashUrl: (hash: string) => string | undefined
}

export const useFormController = <
  Values extends FieldValues,
  PlaceholderValues extends FieldValues,
>(
  props: UseFormControllerProps<Values, PlaceholderValues>,
) => {
  const propsRef = useRef(props)

  useEffect(() => {
    propsRef.current = props
  }, [props])

  const form = useForm<Values>({
    defaultValues: props.defaultValues as DefaultValues<Values>,
  })

  const fields = useFields(form, props)

  const resetValues = useCallback(() => {
    const resetValues = propsRef.current.keepValuesAfterSubmit?.reduce(
      (accumulator, key) => {
        accumulator[key] = form.getValues(key)
        return accumulator
      },
      { ...propsRef.current.defaultValues },
    )

    if (resetValues) {
      form.reset(resetValues)
    } else {
      form.reset(propsRef.current.defaultValues)
    }
  }, [form])

  const handleSubmit = useCallback<SubmitHandler<Values>>(
    async (values, event) => {
      const { explorerHashUrl, onSubmit } = propsRef.current

      Report.addBreadcrumb({
        level: "info",
        type: "user",
        category: "form",
        message: "Form submit started",
        data: values,
      })

      try {
        const response = await onSubmit(values, event)
        resetValues()

        if (response) {
          const breadcrumb: Breadcrumb = {
            level: "info",
            type: "user",
            category: "form",
            message: "Form submit completed",
          }

          if ("success" in response) {
            breadcrumb.data = { message: response.success }

            notification.success(response.success)
          } else if ("transactionHash" in response) {
            breadcrumb.data = { hash: response.transactionHash }

            notification.transactionSuccess({
              href: explorerHashUrl(response.transactionHash),
            })
          }

          Report.addBreadcrumb(breadcrumb)
        }
      } catch (error) {
        notification.error(
          AppError.fromError(error, {
            text: "Form submit failed",
          }),
        )
      }
    },
    [resetValues],
  )

  return {
    form,
    fields,
    handleSubmit,
  }
}

const useFields = <
  Values extends FieldValues,
  PlaceholderValues extends FieldValues,
>(
  form: UseFormReturn<Values>,
  props: UseFormControllerProps<Values, PlaceholderValues>,
) => {
  // It's important for the default keys to never change their order so the loop
  // below can function as expected. The useRef will do this since it only
  // stores the first instance.
  const defaultKeys = useRef(fieldsKeys(props.defaultValues))

  return defaultKeys.current.reduce(
    (accumulator, key) => {
      const { isSubmitting } = form.formState
      const { isDirty } = form.getFieldState(key)
      const keepValue = props.keepValuesAfterSubmit?.includes(key) ?? false
      const isUsingDefaultValue = !isDirty && !keepValue && !isSubmitting
      const defaultValue = props.defaultValues[key]

      useEffect(() => {
        if (isUsingDefaultValue) {
          form.setValue(key, defaultValue, {
            shouldValidate: true,
          })
        }
      }, [form, key, isUsingDefaultValue, defaultValue])

      const value = (() => {
        if (isUsingDefaultValue) {
          return defaultValue
        } else {
          // Nullish empty string prevents an unexpected undefined when focusing
          // and then leaving a field
          return form.getValues(key) ?? ("" as Values[Path<Values>])
        }
      })()
      const placeholder = props.placeholderValues?.[key]
      const extractIssue = (type: ValidateIssue["type"]) => {
        const fieldError = form.formState.errors[key]

        if (
          (type === "error" && fieldError?.type !== "warning") ||
          (type === "warning" && fieldError?.type === "warning")
        ) {
          return fieldError?.message?.toString() || undefined
        }

        return undefined
      }
      const error = extractIssue("error")
      const warning = extractIssue("warning")

      // biome-ignore lint/correctness/useExhaustiveDependencies: need value dep
      useEffect(() => {
        const excludeChangedKeys = defaultKeys.current.filter(
          (filterKey) => filterKey !== key,
        )
        // Validate every other field
        form.trigger(excludeChangedKeys)
      }, [form, value])

      // For some unknown reason if a changing state is not set at the same time
      // as the form value, the input's cursor will jump to the end.
      const [, setRenderHack] = useState("")

      const setValue: FormFieldState<typeof value>["setValue"] = useCallback(
        (value) => {
          form.setValue(key, value, {
            shouldDirty: true,
            shouldValidate: true,
          })
          setRenderHack(value)
        },
        [form, key],
      )

      const field = useMemo<
        | FormFieldState<Values[typeof key]>
        | FormInputFieldState<Values[typeof key]>
      >(() => {
        return {
          key,
          value,
          setValue,
          issue: error
            ? { type: "error", message: error }
            : warning
              ? { type: "warning", message: warning }
              : undefined,
          isDirty,
          ...(placeholder && {
            placeholder,
          }),
        }
      }, [key, value, setValue, error, warning, isDirty, placeholder])

      return Object.assign(accumulator, {
        [key]: field,
      })
    },
    {} as FormFields<Values, PlaceholderValues>,
  )
}

const fieldsKeys = <Values extends object>(object: Values) => {
  return Object.keys(object) as Path<Values>[]
}
