import type { TargetId } from "@common/target/types"

import type { EventWithData } from "./eventData"
import { EventKey } from "./constants"

let analyticsChain = ""
export const setAnalyticsChain = (targetId: TargetId) => {
  analyticsChain = targetId
}

export const track = (
  { eventName, data }: EventWithData,
  isStatsPage?: boolean,
) => {
  const defaultData = {
    // the stats page is _special_ in that it isn't connected to a specific chain
    // so we do not mix that in
    [EventKey.CHAIN]: !isStatsPage ? analyticsChain : undefined,
    [EventKey.DOMAIN]: window.location.host,
  }

  // some events, like chain switching, may override the defaults
  trackInner(eventName, data ? { ...defaultData, ...data } : defaultData)
}

interface TrackEventInnerData {
  [EventKey.CHAIN]?: string
  [EventKey.DOMAIN]: string
}

const trackInner = (eventName: string, data: TrackEventInnerData) => {
  gtag("event", eventName, data)
}
