import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const EyeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 22 16">
      <path d="M11 0.5C16 0.5 20.27 3.61 22 8C20.27 12.39 16 15.5 11 15.5C6 15.5 1.73 12.39 0 8C1.73 3.61 6 0.5 11 0.5ZM11 13C13.76 13 16 10.76 16 8C16 5.24 13.76 3 11 3C8.24 3 6 5.24 6 8C6 10.76 8.24 13 11 13ZM11 5C12.66 5 14 6.34 14 8C14 9.66 12.66 11 11 11C9.34 11 8 9.66 8 8C8 6.34 9.34 5 11 5Z" />
    </SvgIcon>
  )
}

export default EyeIcon
