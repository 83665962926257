import { ChainId as InjectiveChainId } from "@injectivelabs/ts-types";
import { WalletType, getWallet } from "graz";
import { base64Image } from "./icon";
import { WalletErrorType } from "../../shared/error";
const injectiveErrors = [
    {
        message: "You do not have enough funds.",
        type: WalletErrorType.insufficientFunds,
    },
];
const walletInfo = {
    name: "Keplr",
    icon: base64Image,
    install: {
        chrome: "https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap",
        firefox: "https://addons.mozilla.org/en-US/firefox/addon/keplr",
        edge: "https://microsoftedge.microsoft.com/addons/detail/keplr/ocodgmmffbkkeecmadcijjhkmeohinei",
        ios: "https://apps.apple.com/us/app/keplr-wallet/id1567851089",
        android: "https://play.google.com/store/apps/details?id=com.chainapsis.keplr",
    },
    isChainSupported: () => {
        return true;
    },
    errors: {
        generic: [
            {
                message: "key doesn't exist",
                type: WalletErrorType.noAccount,
            },
            {
                message: "Request rejected",
                type: WalletErrorType.rejected,
            },
            {
                message: "Proposal expired",
                type: WalletErrorType.rejected, // QR Code expired
            },
        ],
        chain: {
            [InjectiveChainId.Mainnet]: injectiveErrors,
            [InjectiveChainId.Testnet]: injectiveErrors,
            [InjectiveChainId.Devnet]: injectiveErrors,
        },
    },
};
export const keplrWallet = {
    provider: () => {
        const installed = typeof window.keplr !== "undefined";
        if (!installed) {
            return { installed };
        }
        const subscription = (reconnect) => {
            const listener = () => {
                reconnect();
            };
            window.addEventListener("keplr_keystorechange", listener);
            return () => {
                window.removeEventListener("keplr_keystorechange", listener);
            };
        };
        const provider = getWallet(WalletType.KEPLR);
        return {
            installed,
            getKey: provider.getKey,
            getOfflineSigner: provider.getOfflineSigner,
            subscription,
        };
    },
    info: walletInfo,
};
