import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"

import { useBannerContext } from "@perps/banner/BannerContext"
import { announcementsQueryOptions } from "@future/network/announcements"

const AnnouncementsBanner = () => {
  const bannerContext = useBannerContext()
  const { show, hide } = bannerContext.system

  const announcementsResult = useQuery(announcementsQueryOptions())
  const announcement = announcementsResult.data?.global

  useEffect(() => {
    if (announcement) {
      const key = "announcement.banner"
      show(key, announcement)
      return () => hide(key)
    }
  }, [show, hide, announcement])

  return null
}

export default AnnouncementsBanner
