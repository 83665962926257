import type {
  FieldValues,
  UseFormReturn,
  ValidationValueMessage,
  RegisterOptions,
  ValidationRule,
  FormState,
  FieldErrors,
  Path,
} from "react-hook-form"

import { Amount } from "@future/numerics/amount"
import type { ChangeValues } from "@future/utils/typeScript"

export type Coordinator<
  Variant extends string,
  Values extends FormFieldValues,
  Map extends {
    // biome-ignore lint/suspicious/noExplicitAny:
    [_Key in Variant]: HookSignature<Values, any>
  } & {
    // biome-ignore lint/suspicious/noExplicitAny:
    field?: ComponentSignature<any>
  },
> = {
  [Key in Variant as `use${Capitalize<Key>}`]: Map[Key]
} & {
  Field: (props: ComponentProps<Values> & Map["field"]) => React.ReactNode
}

interface HookSignatureOverride {
  initProps?: object | void
  validationProps?: object | void
}

interface HookSignatureReturn<
  Values extends FormFieldValues,
  Override extends HookSignatureOverride = object,
> {
  defaultValues: () => Values["values"]
  placeholderValues: () => Values["inputValues"]
  useValidation: (
    props: OptionalValue<Override["validationProps"]>,
  ) => UseValidationReturn<Values>
}

export type HookSignature<
  Values extends FormFieldValues,
  Override extends HookSignatureOverride = object,
> = (
  initProps: OptionalValue<Override["initProps"]>,
) => HookSignatureReturn<Values, Override>

export type ComponentSignature<
  Override extends {
    props?: object | void
  } = object,
> = OptionalValue<Override["props"]>

interface ComponentProps<Values extends FormFieldValues> {
  form: UseFormReturn<Values["values"]>
  fields: FormFields<Values["values"], Values["inputValues"]>
  validation: ChangeValues<Values["inputValues"], Validation>
}

type OptionalValue<Value1, Value2 = void> = Value1 extends object
  ? Value1
  : Value2

export type UseValidationReturn<Values extends FormFieldValues> = ChangeValues<
  Values["inputValues"],
  Validation
>

export interface FormFieldValues<
  Values extends FieldValues = FieldValues,
  InputValues extends FieldValues = FieldValues,
> {
  values: Values
  inputValues: InputValues
}

export interface FormFieldState<Type> {
  key: string
  value: Type
  setValue: (value: Type) => void
  issue: ValidateIssue | undefined
  isDirty: boolean
}

export interface FormInputFieldState<Type> extends FormFieldState<Type> {
  placeholder: Type | undefined
}

export type FormFields<
  Values extends FieldValues,
  PlaceholderValues extends FieldValues = FieldValues,
> = {
  [Key in keyof Values]: Key extends keyof PlaceholderValues
    ? FormInputFieldState<Values[Key]>
    : FormFieldState<Values[Key]>
}

export type FormPlaceholderValues<Values> = {
  [Key in keyof Values]: Values[Key] | undefined
}

type Validation = Pick<RegisterOptions, "min" | "max" | "validate"> & {
  warning?: (value: string) => string | undefined
}

export interface ValidateIssue {
  type: "error" | "warning"
  message: string
}

export interface VariantProps<Variant extends string> {
  variant: Variant
}

export const hookDefaults = <
  Values extends FormFieldValues,
  Override extends HookSignatureOverride = object,
>(
  callback: (
    defaults: Omit<
      HookSignatureReturn<FormFieldValues<Values["values"]>, Override>,
      "defaultValues"
    >,
  ) => HookSignature<Values, Override>,
) => {
  return callback({
    placeholderValues: () => ({}),
    useValidation: () => ({}),
  })
}

export const inferForm = <
  InValues extends FieldValues,
  OutValues extends FieldValues,
>(
  form: InValues,
): UseFormReturn<OutValues> => {
  return form as unknown as UseFormReturn<OutValues>
}

export const findMaxValidation = (
  validations: ValidationValueMessage<number | string>[],
) => {
  let maxValidation = validations[0]

  for (let i = 1; i < validations.length; i += 1) {
    const validation = validations[i]
    const validationAmount = new Amount(validation.value)

    if (validationAmount.isLessThan(maxValidation.value)) {
      maxValidation = validation
    }
  }

  return maxValidation
}

export const findMinValidation = (
  validations: ValidationValueMessage<number | string>[],
) => {
  let minValidation = validations[0]

  for (let i = 1; i < validations.length; i += 1) {
    const validation = validations[i]
    const validationAmount = new Amount(validation.value)

    if (
      validationAmount.isGreaterThan(minValidation.value) &&
      validationAmount.isGreaterThan(0)
    ) {
      minValidation = validation
    }
  }

  return minValidation
}

/**
 * Form register options validate
 *
 * Adds supports for the warning validation. It's also important to note that
 * the `min` and `max` are not included in the returned object as they will
 * override the `validate` function.
 */
export const validateIssue = <Values extends FieldValues = FieldValues>(
  validation: Validation,
  form: UseFormReturn<Values>,
  key: Path<Values>,
): RegisterOptions => {
  const { warning, validate } = validation

  if (!validate) {
    console.warn(`Use the validate function instead of min/max for ${key}`)
  }

  if (warning) {
    if (typeof validate === "function") {
      return {
        validate: (value, formValues) => {
          const result = validate(value, formValues)

          if (result !== undefined) {
            return result
          }

          const message = warning(value)

          if (message) {
            const issue: ValidateIssue = {
              type: "warning",
              message,
            }

            form.setError(key, issue)
          }
        },
      }
    } else {
      console.error("Unsupported condition reached")
    }
  }

  return {
    validate,
  }
}

export const extractValidationValue = (
  validation: ValidationRule<number | string> | undefined,
): number | string | undefined => {
  return typeof validation === "object" ? validation.value : validation
}

export const isPending = <Values extends FieldValues>(
  formState: FormState<Values>,
) => {
  return formState.isSubmitting
}

export const inferredValue = <Type>(
  value: Type,
  placeholder: Type | undefined,
): Type => {
  let inferredValue = value

  if (placeholder) {
    inferredValue ||= placeholder
  }

  return inferredValue
}

export const inferredInputValue = <Type>(
  input: FormInputFieldState<Type>,
): Type => {
  return inferredValue(input.value, input.placeholder)
}

export const extractErrorMessage = (error?: FieldErrors[0]) => {
  if (error && error.type !== "required") {
    return error.message
  }
}

export const defaultAssetPlaceholder = "0.00"
