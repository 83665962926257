import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const HistoryIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0469 21C17.0174 21 21.0469 16.9706 21.0469 12C21.0469 7.02944 17.0174 3 12.0469 3C7.07631 3 3.04688 7.02944 3.04688 12C3.04688 16.9706 7.07631 21 12.0469 21ZM12.5469 8.5C12.5469 7.94772 12.0992 7.5 11.5469 7.5C10.9946 7.5 10.5469 7.94772 10.5469 8.5V12.5858C10.5469 13.1162 10.7576 13.6249 11.1327 14L12.8398 15.7071C13.2303 16.0976 13.8635 16.0976 14.254 15.7071C14.6445 15.3166 14.6445 14.6834 14.254 14.2929L12.5469 12.5858V8.5Z"
      />
    </SvgIcon>
  )
}

export default HistoryIcon
