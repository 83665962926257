import Box, { type BoxProps } from "@mui/joy/Box"

import chainIconAlt from "@perps/network/image/chain-icon-alt.png"

export interface ChainLogoProps extends BoxProps<"img"> {
  icon: string
  display: string
  size?: number | string
}

const ChainLogo = (props: ChainLogoProps) => {
  const { size, icon, display, ...imgProps } = props

  return (
    <Box
      {...imgProps}
      component="img"
      sx={{
        height: size ?? 40,
        width: size ?? 40,
        objectFit: "cover",
        objectPosition: "0",
        backgroundColor: (theme) => theme.palette.background.level2,
        borderRadius: "100%",
        ...props.sx,
      }}
      src={icon ?? chainIconAlt}
      alt={props.alt ?? `${display} logo`}
    />
  )
}

export default ChainLogo
