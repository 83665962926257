import Box from "@mui/joy/Box"
import type { ReactNode } from "react"
import { useLocation } from "react-router-dom"

import { getLocalStorageItem } from "@common/storage/localStorage"

export interface BannerItem {
  /**
   * @example
   * "All paths": /^\//
   * "Specific page": /^\/((?!leaderboard).)*$/
   */
  path?: RegExp | string
  content: ReactNode
  id: string
}

export interface BannerLayoutProps {
  bannerItems: BannerItem[]
}

const BannerLayout = (props: BannerLayoutProps) => {
  const { bannerItems } = props
  const { pathname } = useLocation()

  const banner = bannerItems.find(
    (banner) =>
      (banner.path === undefined || pathname?.match(banner.path)) &&
      getLocalStorageItem(banner.id) !== true,
  )

  if (!banner) {
    return null
  }

  return (
    <>
      <Box>{banner.content}</Box>
    </>
  )
}

export default BannerLayout
