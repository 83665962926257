import PopperSheet, { type PopperSheetProps } from "@common/ui/PopperSheet"
import type { ContextStoreProp } from "@future/context/store"

import HeaderConnectedList, {
  type HeaderConnectedListProps,
} from "../HeaderConnectedList"

export type HeaderConnectedMenuProps = PopperSheetProps &
  ContextStoreProp<"standard"> &
  Pick<HeaderConnectedListProps, "appendList">

const HeaderConnectedMenu = (props: HeaderConnectedMenuProps) => {
  const { appendList, contextStore, ...joyProps } = props

  return (
    <PopperSheet {...joyProps}>
      <HeaderConnectedList
        onClose={props.onClose}
        appendList={appendList}
        contextStore={contextStore}
      />
    </PopperSheet>
  )
}

export default HeaderConnectedMenu
