import { isProduction } from "@common/env/constants"
import type { TargetId } from "@common/target/types"

import {
  type TargetCategory,
  type TargetInfo,
  targetInfos,
  type TargetSlug,
} from "./info"

type KnownDomain = (typeof knownDomains)[number]

const knownDomains = [
  "develop.staff.levana.exchange",
  "develop-mainnet.staff.levana.exchange",
  "develop-testnet.staff.levana.exchange",
  "staging.staff.levana.exchange",
  "staging-mainnet.staff.levana.exchange",
  "staging-testnet.staff.levana.exchange",
  "trade.levana.finance",
  "testnet-trade.levana.finance",
  "main.staff.levana.exchange",

  // Temporary domains for migration
  "trade-queue.levana.finance",
  "trade-legacy.levana.finance",
] as const

const currentDomain: KnownDomain = (() => {
  const windowDomain = window.location.host

  if (knownDomains.includes(windowDomain as KnownDomain)) {
    return windowDomain as KnownDomain
  } else {
    return isProduction
      ? "trade.levana.finance"
      : "develop.staff.levana.exchange"
  }
})()

const isTargetWhitelisted = (
  category: TargetCategory,
  targetWhitelistType: TargetWhitelistType,
): boolean => {
  switch (targetWhitelistType) {
    case "all":
      return true

    case "mainnet":
      return (() => {
        switch (category) {
          case "mainnet":
            return true
          case "beta":
          case "competition":
          case "local":
          case "other":
            return false
        }
      })()

    case "beta":
      return (() => {
        switch (category) {
          case "beta":
            return true
          case "competition":
          case "local":
          case "mainnet":
          case "other":
            return false
        }
      })()
  }
}

type TargetWhitelistType = "all" | "beta" | "mainnet"

const createDomainConfig = (
  targetWhitelistType: TargetWhitelistType,
  defaultTargetId: TargetId,
) => {
  let defaultTargetInfo: TargetInfo | undefined
  const idToTarget: Record<string, TargetInfo> = {}
  const slugToTarget: Record<string, TargetInfo> = {}

  for (const targetInfo of targetInfos) {
    if (isTargetWhitelisted(targetInfo.category, targetWhitelistType)) {
      idToTarget[targetInfo.id] = targetInfo
      slugToTarget[targetInfo.slug] = targetInfo

      if (targetInfo.id === defaultTargetId) {
        defaultTargetInfo = targetInfo
      }
    }
  }

  if (defaultTargetInfo === undefined) {
    throw new Error(
      `Internal config error, defaultTarget of ${defaultTargetId} incompatible with targetsShown of ${targetWhitelistType}`,
    )
  }

  return {
    defaultTargetInfo,
    availableTargets: Object.values(slugToTarget),
    getTargetBySlug: (slug: TargetSlug) => slugToTarget[slug],
    getTargetById: (id: TargetId) => idToTarget[id],
    usesGeoblocking: targetWhitelistType === "mainnet",
    mainnetIndexer: targetWhitelistType === "mainnet",
  }
}

export type DomainConfig = ReturnType<typeof createDomainConfig>

export const domainConfig = ((): DomainConfig => {
  switch (currentDomain) {
    case "develop.staff.levana.exchange":
    case "main.staff.levana.exchange":
    case "staging.staff.levana.exchange":
      return createDomainConfig("all", "osmodev")

    case "staging-testnet.staff.levana.exchange":
    case "testnet-trade.levana.finance":
    case "develop-testnet.staff.levana.exchange":
      return createDomainConfig("beta", "osmobeta")

    case "trade.levana.finance":
    case "trade-queue.levana.finance":
    case "trade-legacy.levana.finance":
    case "staging-mainnet.staff.levana.exchange":
    case "develop-mainnet.staff.levana.exchange":
      return createDomainConfig("mainnet", "osmomainnet1")
  }
})()

export const indexerUrl = domainConfig.mainnetIndexer
  ? "https://indexer-mainnet.levana.finance"
  : "https://indexer-testnet.levana.finance"

export const querierUrl = domainConfig.mainnetIndexer
  ? "https://querier-mainnet.levana.finance"
  : "https://querier-testnet.levana.finance"

export const govUrl = domainConfig.mainnetIndexer
  ? "https://gov.levana.finance"
  : "https://govtest.levana.finance"
