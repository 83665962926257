import { fromBech32 } from "@cosmjs/encoding";
export const isCosmosAddress = (address, prefix) => {
    try {
        const decoded = fromBech32(address);
        if (decoded.data.length === 20 || decoded.data.length === 32) {
            return prefix !== undefined ? decoded.prefix === prefix : true;
        }
        return false;
    }
    catch (_a) {
        return false;
    }
};
