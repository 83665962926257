import { WalletType as GrazWalletType } from "graz";
import { WalletType } from "../shared/wallet";
export const walletTypeToGraz = (walletType) => {
    switch (walletType) {
        case WalletType.compass:
            return GrazWalletType.COMPASS;
        case WalletType.cosmostation:
            return GrazWalletType.COSMOSTATION;
        case WalletType.cosmostationMobile:
            return GrazWalletType.WC_COSMOSTATION_MOBILE;
        case WalletType.keplr:
            return GrazWalletType.KEPLR;
        case WalletType.keplrMobile:
            return GrazWalletType.WC_KEPLR_MOBILE;
        case WalletType.leap:
            return GrazWalletType.LEAP;
        case WalletType.leapMobile:
            return GrazWalletType.WC_LEAP_MOBILE;
        case WalletType.metamask:
            return GrazWalletType.METAMASK_SNAP_LEAP;
        case WalletType.okx:
            return GrazWalletType.OKX;
        case WalletType.walletConnect:
            return GrazWalletType.WALLETCONNECT;
        case WalletType.viewing:
            return GrazWalletType.KEPLR;
        case WalletType.xdefi:
            return GrazWalletType.XDEFI;
    }
};
export const walletTypeFromGraz = (grazWalletType) => {
    switch (grazWalletType) {
        case GrazWalletType.CAPSULE:
            return WalletType.keplr;
        case GrazWalletType.COMPASS:
            return WalletType.compass;
        case GrazWalletType.COSMIFRAME:
            return WalletType.keplr;
        case GrazWalletType.COSMOSTATION:
            return WalletType.cosmostation;
        case GrazWalletType.INITIA:
            return WalletType.keplr;
        case GrazWalletType.KEPLR:
            return WalletType.keplr;
        case GrazWalletType.LEAP:
            return WalletType.leap;
        case GrazWalletType.METAMASK_SNAP_COSMOS:
            return WalletType.metamask;
        case GrazWalletType.METAMASK_SNAP_LEAP:
            return WalletType.metamask;
        case GrazWalletType.OKX:
            return WalletType.okx;
        case GrazWalletType.STATION:
            return WalletType.keplr;
        case GrazWalletType.VECTIS:
            return WalletType.keplr;
        case GrazWalletType.WALLETCONNECT:
            return WalletType.walletConnect;
        case GrazWalletType.WC_CLOT_MOBILE:
            return WalletType.walletConnect;
        case GrazWalletType.WC_COSMOSTATION_MOBILE:
            return WalletType.cosmostationMobile;
        case GrazWalletType.WC_KEPLR_MOBILE:
            return WalletType.keplrMobile;
        case GrazWalletType.WC_LEAP_MOBILE:
            return WalletType.leapMobile;
        case GrazWalletType.XDEFI:
            return WalletType.xdefi;
    }
};
