import { queryOptions } from "@tanstack/react-query"

import { MINUTE } from "@common/utils/date"
import { httpGet } from "@future/utils/http"
import type Config from "@public/config.json"

export type UpdateConfig = typeof Config

export const updateConfigQueryOptions = () => {
  return queryOptions({
    queryKey: ["updateConfig"],
    refetchInterval: MINUTE,
    queryFn: async (context) => {
      const response = await httpGet<UpdateConfig>("/config.json", {
        signal: context.signal,
      })
      return response.data
    },
  })
}
