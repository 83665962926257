import HCaptcha from "@hcaptcha/react-hcaptcha"
import { useTheme } from "@mui/joy/styles"

import type { FormInputFieldState } from "@future/libs/form/utils"

import { useController } from "./controller"

export interface FormCaptchaProps {
  state: FormInputFieldState<string>
}

const FormCaptcha = (props: FormCaptchaProps) => {
  const controller = useController(props)
  const theme = useTheme()

  return (
    <HCaptcha
      sitekey="9b92faf0-5545-4b03-9652-7424ef073459"
      theme={theme.palette.mode}
      onVerify={controller.captcha.onVerify}
      onExpire={controller.captcha.onExpire}
    />
  )
}

export default FormCaptcha
