import { create } from "zustand";
import { createJSONStorage, subscribeWithSelector, persist, } from "zustand/middleware";
import { serializeAccount, deserializeAccount, mutateAccounts, } from "../shared/account";
export const injectiveSessionDefaultValues = {
    accounts: null,
};
const sessionOptions = {
    name: "InjectiveSession",
    version: 1,
    partialize: (state) => {
        return {
            accounts: state.accounts
                ? mutateAccounts(state.accounts, serializeAccount)
                : null,
        };
    },
    merge: (persistedState, currentState) => {
        const state = persistedState;
        return Object.assign(Object.assign(Object.assign({}, currentState), state), { accounts: (state === null || state === void 0 ? void 0 : state.accounts)
                ? mutateAccounts(state.accounts, deserializeAccount)
                : null });
    },
    storage: createJSONStorage(() => sessionStorage),
};
export const useInjectiveSessionStore = create(subscribeWithSelector(persist(() => injectiveSessionDefaultValues, sessionOptions)));
export const useInjectiveStore = create(() => ({
    walletStrategy: undefined,
}));
