import Button from "@mui/joy/Button"
import Link from "@mui/joy/Link"
import List from "@mui/joy/List"
import ListItem from "@mui/joy/ListItem"
import ListItemContent from "@mui/joy/ListItemContent"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import Skeleton from "@mui/joy/Skeleton"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useStore } from "zustand"
import { useAccount } from "@levana/chain/account"
import NavigationModal, {
  NavigationModalDynamicItem,
} from "@levana/theme/NavigationModal"

import LvnIcon from "@common/icons/LvnIcon"
import type { ChainName } from "@common/target/types"
import { Percentage, Usd } from "@future/numerics"
import PriceChange from "@common/ui/PriceChange"
import { lvnPriceQueryOptions } from "@future/network/lvnPrice"
import { lvnCirculatingQueryOptions } from "@future/network/lvnCirculating"
import type { ContextStoreProp } from "@future/context/store"
import ConnectButton from "@future/connect/ConnectButton"
import { govUrl } from "@future/target/domain"
import { useWalletNativeCoinBalancesQuery } from "@future/network/walletNativeCoinBalances"
import { useGovGlobalInfo } from "@future/network/govGlobalInfo"
import { lvnAddress } from "@future/market/config/constants"

const GetLvnModal = (props: ContextStoreProp<"standard">) => {
  const { t } = useTranslation("perpsCommon")
  const { data: account } = useAccount()

  const chainName = useStore(
    props.contextStore,
    (state) => state.chain.config.chainName,
  )
  const url = getDexUrl(chainName)

  const lvnCirculatingResult = useQuery(lvnCirculatingQueryOptions())
  const lvnPriceResult = useQuery(lvnPriceQueryOptions())
  const walletNativeCoinBalances = useWalletNativeCoinBalancesQuery(props)
  const lvn = walletNativeCoinBalances.data?.get(lvnAddress)?.nominated
  const govGlobalInfo = useGovGlobalInfo(props)

  return (
    <NavigationModal rootId={GetLvnModal.name}>
      {(modal) => (
        <NavigationModalDynamicItem
          id={GetLvnModal.name}
          title={t("lvn.modal.title")}
          canClose
        >
          <Stack direction="column" sx={{ rowGap: 3 }}>
            <Stack direction="row" sx={{ alignSelf: "center", columnGap: 1 }}>
              <LvnIcon size="lg" />
              <Typography
                level="title-md"
                textColor="text.primary"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 0.75,
                }}
              >
                <Skeleton
                  loading={!lvnPriceResult.data}
                  variant="rectangular"
                  width={200}
                  height={25}
                >
                  {lvnPriceResult.data && (
                    <>
                      {`LVN ${lvnPriceResult.data.price.toSymbolAsset({
                        maxDecimalPlaces: 4,
                      })} `}
                      <PriceChange
                        percentage={lvnPriceResult.data.percent24hChange}
                        timePeriod="1d"
                      />
                    </>
                  )}
                </Skeleton>
              </Typography>
            </Stack>

            <List>
              <ListItem>
                <ListItemContent>
                  <Typography level="body-md" textColor="text.secondary">
                    {t("lvn.modal.totalLocked")}
                  </Typography>
                </ListItemContent>
                <Typography level="body-md" textColor="text.primary">
                  <Skeleton
                    variant="rectangular"
                    width={60}
                    height={20}
                    loading={!govGlobalInfo.data || !lvnCirculatingResult.data}
                  >
                    {govGlobalInfo.data &&
                      lvnCirculatingResult.data &&
                      new Percentage(
                        govGlobalInfo.data.lockedLvn
                          .divide(lvnCirculatingResult.data)
                          .times(100)
                          .min(100),
                      ).toAsset()}
                  </Skeleton>
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemContent>
                  <Typography level="body-md" textColor="text.secondary">
                    {t("lvn.modal.marketCap")}
                  </Typography>
                </ListItemContent>
                <Typography level="body-md" textColor="text.primary">
                  <Skeleton
                    variant="rectangular"
                    width={90}
                    height={20}
                    loading={!lvnPriceResult.data || !lvnCirculatingResult.data}
                  >
                    {lvnPriceResult.data &&
                      lvnCirculatingResult.data &&
                      new Usd(lvnCirculatingResult.data)
                        .times(lvnPriceResult.data.price)
                        .toAbbreviate()}
                  </Skeleton>
                </Typography>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  href={url}
                  target="_blank"
                  rel="noopener"
                  underline="none"
                  onClick={modal.close}
                  variant="solid"
                  fullWidth
                  aria-label="buy LVN"
                >
                  {t("lvn.modal.buyLvn")}
                </Button>
              </ListItem>
            </List>

            <List>
              <ListItem>
                <ListItemContent>
                  <Typography level="body-md" textColor="text.secondary">
                    {t("lvn.modal.availableLvn")}
                  </Typography>
                </ListItemContent>
                <Typography level="body-md" textColor="text.primary">
                  {lvn ? t("lvn.modal.lvn", { lvn: lvn.toString() }) : <>-</>}
                </Typography>
              </ListItem>
              <ListItem sx={{ flexDirection: "column", alignItems: "stretch" }}>
                {account ? (
                  <Button
                    component={Link}
                    href={`${govUrl}/stake`}
                    target="_blank"
                    underline="none"
                    onClick={modal.close}
                    variant="solid"
                    fullWidth
                    aria-label="stake LVN"
                  >
                    {t("lvn.modal.stakeLvn")}
                  </Button>
                ) : (
                  <ConnectButton />
                )}
              </ListItem>
            </List>

            <Link
              href="https://medium.com/@LevanaProtocol/lvn-tokenomics-the-essential-guide-c9fefd61b9e8"
              target="_blank"
              rel="noopener"
              underline="none"
              onClick={modal.close}
              level="body-sm"
              sx={{ alignSelf: "center" }}
              aria-label="tokenomics"
            >
              {t("lvn.modal.tokenomics")}
            </Link>
          </Stack>
        </NavigationModalDynamicItem>
      )}
    </NavigationModal>
  )
}

const getDexUrl = (chainName: ChainName): string => {
  switch (chainName) {
    case "injective":
      return "https://injective.astroport.fi/swap?from=inj&to=ibc/4971C5E4786D5995EC7EF894FCFA9CF2E127E95D5D53A982F6A062F3F410EDB8"
    case "osmosis":
      return "https://swapfast.app/lvn"
    case "sei":
      return "https://sei.astroport.fi/swap?to=ibc/4786BEBBFDD989C467C4552AD73065D8B2578230B8428B3B9275D540EB04C851&from=usei"
    case "neutron":
      // FIXME this URL converts from NTRN to ASTRO, since LVN isn't currently listed on Neutron's Astroport.
      return "https://neutron.astroport.fi/swap?from=untrn&to=ibc/5751B8BCDA688FD0A8EC0B292EEF1CDEAB4B766B63EC632778B196D317C40C3A"
    case "dymension":
      return "https://not-available"
  }
}

export default GetLvnModal
