import { useTranslation } from "react-i18next"
import { useStore } from "zustand"
import { useConnectedWallet } from "@levana/chain/wallet"
import { useDisconnect } from "@levana/chain/account"

import CopyIcon from "@common/icons/CopyIcon"
import DisconnectIcon from "@common/icons/DisconnectIcon"
import { notification } from "@future/notification"
import { track } from "@perps/analytics/track"
import { Event } from "@perps/analytics/events"

import type { HeaderConnectedListProps } from "."
import type { HeaderMenuListItem } from "../HeaderMenuList"

export const useController = (props: HeaderConnectedListProps) => {
  const { appendList } = props
  const { t } = useTranslation("common")

  const { data: connectedWallet } = useConnectedWallet()
  const { disconnect } = useDisconnect()

  const chainName = useStore(
    props.contextStore,
    (state) => state.chain.config.chainName,
  )
  const chainPrettyName = useStore(
    props.contextStore,
    (state) => state.chain.config.chainPrettyName,
  )

  const items: HeaderMenuListItem[] = [
    {
      title: t("wallet.menu.address.title", {
        chain: chainPrettyName,
      }),
      subtitle: connectedWallet?.truncateAddress(),
      Icon: CopyIcon,
      action: () => {
        if (connectedWallet) {
          track(Event.copyWalletAddress())
          navigator.clipboard.writeText(connectedWallet.account.bech32Address)
          notification.success(
            t("wallet.menu.address.notification", { chain: chainPrettyName }),
          )
        }
      },
    },
  ]

  if (chainName === "injective" && connectedWallet) {
    items.push({
      title: t("wallet.menu.address.title", { chain: "Ethereum" }),
      subtitle: connectedWallet.truncateEthereumAddress(),
      Icon: CopyIcon,
      action: () => {
        track(Event.copyWalletAddress())
        navigator.clipboard.writeText(
          connectedWallet.account.ethereumHexAddress,
        )
        notification.success(
          t("wallet.menu.address.notification", { chain: "Ethereum" }),
        )
      },
    })
  }

  if (appendList) {
    items.push(...appendList)
  }

  items.push({
    title: t("wallet.menu.disconnect"),
    Icon: DisconnectIcon,
    action: () => {
      if (connectedWallet) {
        track(Event.disconnectWallet())
        disconnect()
      }
    },
  })

  return {
    items,
  }
}
