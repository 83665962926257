import { getI18n } from "react-i18next"
import { useCallback, useEffect, useState } from "react"

import { nanoToMilli } from "@perps/sdk/utils"

/**
 * @deprecated use "@levana/utils/date"
 */
// All in milliseconds
export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR

export const OPEN_POSITIONS_INTERVAL = MINUTE

export const parseDate = (input: string): Date | undefined => {
  const date = new Date(input)
  return Number.isNaN(date.getTime()) ? undefined : date
}

export const formatSecondsToDHMS = (totalSeconds: number) => {
  let seconds = totalSeconds

  const days = Math.floor(seconds / 86400)

  if (days > 0) {
    seconds %= 86400
    return `${days.toString().padStart(2, "0")}:${formatSecondsToHMS(seconds)}`
  } else {
    return formatSecondsToHMS(totalSeconds)
  }
}

export function formatSecondsToHMS(totalSeconds: number) {
  let seconds = totalSeconds

  const hours = Math.floor(seconds / 3600)
  seconds %= 3600

  const minutes = Math.floor(seconds / 60)
  seconds = seconds % 60

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
}

export function formatSecondsToLargestTimeUnit(seconds: number) {
  const t = getI18n().t
  const milliseconds = seconds * 1000

  switch (true) {
    case milliseconds >= DAY:
      return t("time.day", { count: Math.ceil(milliseconds / DAY) })
    case milliseconds >= HOUR:
      return t("time.hour", { count: Math.ceil(milliseconds / HOUR) })
    case milliseconds >= MINUTE:
      return t("time.minute", { count: Math.ceil(milliseconds / MINUTE) })
    default:
      return t("time.second", { count: Math.ceil(milliseconds / SECOND) })
  }
}

export const useSecondsUntil = (nanoseconds: string | undefined) => {
  const calcSeconds = useCallback(() => {
    if (!nanoseconds) {
      return
    }
    const atMillis = nanoToMilli(nanoseconds)
    const nowMillis = Date.now()
    const res = Math.floor((atMillis - nowMillis) / 1000)
    return res <= 0 ? undefined : res
  }, [nanoseconds])

  const [seconds, setSeconds] = useState<number | undefined>(calcSeconds)

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(calcSeconds())
    }, 250)
    return () => {
      clearInterval(interval)
    }
  }, [calcSeconds])

  return seconds
}
