import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const ReferralIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M19.9 14.2992C19.9 13.8992 20 13.5992 20 13.1992C20 10.1992 18.4 7.69922 16 6.29922V6.19922C16 3.99922 14.2 2.19922 12 2.19922C9.8 2.19922 8 3.99922 8 6.19922V6.29922C5.6 7.69922 4 10.1992 4 13.1992C4 13.5992 4 13.8992 4.1 14.2992C2.9 14.9992 2 16.2992 2 17.7992C2 19.9992 3.8 21.7992 6 21.7992C7.1 21.7992 8.1 21.2992 8.9 20.5992C9.9 20.9992 10.9 21.1992 12 21.1992C13.1 21.1992 14.2 20.9992 15.1 20.5992C15.8 21.2992 16.8 21.7992 18 21.7992C20.2 21.7992 22 19.9992 22 17.7992C22 16.2992 21.2 14.9992 19.9 14.2992ZM12 19.6992C11.2 19.6992 10.4 19.4992 9.7 19.2992C9.9 18.7992 10 18.2992 10 17.7992C10 15.5992 8.2 13.7992 6 13.7992C5.8 13.7992 5.7 13.7992 5.5 13.7992C5.5 13.5992 5.5 13.3992 5.5 13.1992C5.5 10.9992 6.6 8.99922 8.4 7.79922C9 9.19922 10.4 10.1992 12 10.1992C13.6 10.1992 15 9.19922 15.6 7.79922C17.3 8.99922 18.5 10.8992 18.5 13.1992C18.5 13.3992 18.5 13.5992 18.5 13.7992C18.3 13.7992 18.2 13.7992 18 13.7992C15.8 13.7992 14 15.5992 14 17.7992C14 18.2992 14.1 18.7992 14.3 19.2992C13.6 19.4992 12.8 19.6992 12 19.6992Z" />
    </SvgIcon>
  )
}

export default ReferralIcon
