import { useCallback, useRef, useState } from "react"

import { Event } from "@perps/analytics/events"
import { track } from "@perps/analytics/track"

interface PresenterProps {
  analyticsKind?: "openWalletMenu" | "openNavMenu"
}
export const usePresenter = (props?: PresenterProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = useCallback(() => {
    if (props?.analyticsKind === "openWalletMenu") {
      track(Event.openWalletMenu())
    } else if (props?.analyticsKind === "openNavMenu") {
      track(Event.openOverflowMenu())
    }
    setIsOpen(true)
  }, [props?.analyticsKind])

  const close = useCallback(() => setIsOpen(false), [])

  const ref = useRef<HTMLButtonElement>(null)

  return {
    ref,
    isOpen,
    open,
    close,
  }
}
