import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const CopyIcon = (props: SvgIconProps) => {
  const Svg = () => (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5.7C3 4.20883 4.20883 3 5.7 3H12C13.4912 3 14.7 4.20883 14.7 5.7V7.5H12C9.51472 7.5 7.5 9.51472 7.5 12V14.7H5.7C4.20883 14.7 3 13.4912 3 12V5.7ZM12 9.3C10.5088 9.3 9.3 10.5088 9.3 12V18.3C9.3 19.7912 10.5088 21 12 21H18.3C19.7912 21 21 19.7912 21 18.3V12C21 10.5088 19.7912 9.3 18.3 9.3H12Z"
      />
    </g>
  )

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <Svg />
    </SvgIcon>
  )
}

export default CopyIcon
