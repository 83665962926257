import { useEffect, useRef, useState } from "react"

import { AppError } from "@future/libs/error/AppError"
import { Report } from "@future/libs/error/report"

export const useLazyLoad = <Value>(
  lazyImport: () => Promise<{ default: Value }>,
) => {
  const [lazyValue, setLazyValue] = useState<Value | null>(null)
  const lazyImportRef = useRef(lazyImport)

  useEffect(() => {
    const load = async () => {
      try {
        const module = await lazyImportRef.current()
        setLazyValue(() => module.default)
      } catch (error) {
        Report.error(
          AppError.fromError(error, {
            text: "Unable to lazy load",
            level: "fatal",
          }),
        )
      }
    }
    load()
  }, [])

  return lazyValue
}
