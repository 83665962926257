import type { ContextStoreProp } from "@future/context/store"

import { useController } from "./controller"
import HeaderMenuList, { type HeaderMenuListItem } from "../HeaderMenuList"

export interface HeaderConnectedListProps extends ContextStoreProp<"standard"> {
  onClose: () => void
  appendList?: HeaderMenuListItem[]
}

const HeaderConnectedList = (props: HeaderConnectedListProps) => {
  const controller = useController(props)

  return <HeaderMenuList items={controller.items} onClose={props.onClose} />
}

export default HeaderConnectedList
