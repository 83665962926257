import InvalidRootLayout, {
  type InvalidRootContent,
} from "@future/root/InvalidRootContent"
import type { ContextStoreProp } from "@future/context/store"

import LoadingContent from "./LoadingContent"

export interface LoadingPageProps extends ContextStoreProp<"initial"> {
  desc: string[]
  type: "full-screen" | "component"
}

const LoadingPage = (props: LoadingPageProps) => {
  switch (props.type) {
    case "full-screen": {
      const content: InvalidRootContent = { type: "loading", desc: props.desc }
      return (
        <InvalidRootLayout
          content={content}
          contextStore={props.contextStore}
        />
      )
    }
    case "component":
      return <LoadingContent messages={props.desc} />
  }
}

export default LoadingPage
