import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CssBaseline from "@mui/joy/CssBaseline";
import GlobalStyles from "@mui/joy/GlobalStyles";
import { CssVarsProvider } from "@mui/joy/styles";
import { useEffect, useState } from "react";
import { lazyLoad } from "@levana/utils/lazyLoad";
import BreakpointDetection from "../BreakpointDetection";
const ThemeProvider = (props) => {
    const { children } = props;
    return (_jsx(ThemeSelector, { variant: props.variant, children: (cssVarsData) => (_jsxs(CssVarsProvider, { theme: cssVarsData.theme, defaultMode: cssVarsData.defaultMode, children: [_jsx(CssBaseline, {}), _jsx(GlobalStyles, { styles: {
                        "#root": {
                            minHeight: "100vh",
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "nowrap",
                        },
                        ul: {
                            paddingInlineStart: "1.5rem",
                        },
                    } }), _jsx(BreakpointDetection, {}), children] })) }));
};
const ThemeSelector = (props) => {
    var _a;
    const [cssVarsData, setCssVarsData] = useState();
    useEffect(() => {
        switch (props.variant) {
            case "levana":
                lazyLoad(() => import("./levana")).then(setCssVarsData);
                break;
            case "rujira":
                lazyLoad(() => import("./rujira")).then(setCssVarsData);
                break;
        }
    }, [props.variant]);
    if (!cssVarsData) {
        return null;
    }
    return (_a = props.children) === null || _a === void 0 ? void 0 : _a.call(props, cssVarsData);
};
export default ThemeProvider;
