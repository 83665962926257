export const useTwitterShare = () => {
  const createUrl = (shareText: string, shareUrl?: string) => {
    const url = new URL("https://twitter.com/intent/tweet")
    url.searchParams.append("text", shareText)
    if (shareUrl) {
      url.searchParams.append("url", shareUrl)
    }
    return url
  }

  const openTwitter = (shareText: string, shareUrl?: string) => {
    // window.open does not work in mobile Safari with async funcs
    setTimeout(() => {
      window.open(createUrl(shareText, shareUrl), "_blank")
    })
  }

  return {
    openTwitter,
  }
}
