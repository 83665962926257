import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import common from "../../locales/en/common.json"
import error from "../../locales/en/error.json"
import faucet from "../../locales/en/faucet.json"
import perpsCommon from "../../locales/en/perpsCommon.json"
import perpsEarn from "../../locales/en/perpsEarn.json"
import perpsHistory from "../../locales/en/perpsHistory.json"
import perpsStats from "../../locales/en/perpsStats.json"
import perpsTrade from "../../locales/en/perpsTrade.json"
import perpsTradeMarket from "../../locales/en/perpsTradeMarket.json"
import priceFeed from "../../locales/en/priceFeed.json"

declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: typeof defaultNamespace
    resources: (typeof resources)[typeof fallbackLanguage]
  }
}

const fallbackLanguage = "en"
const languages = [fallbackLanguage]
const defaultNamespace = "common"

const resources = {
  [fallbackLanguage]: {
    common,
    error,
    faucet,
    perpsCommon,
    perpsEarn,
    perpsHistory,
    perpsTrade,
    perpsTradeMarket,
    perpsStats,
    priceFeed,
  },
} as const

export const initI18n = () =>
  i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      // debug: true,
      supportedLngs: languages,
      fallbackLng: fallbackLanguage,
      lng: undefined, // Detect the language
      fallbackNS: defaultNamespace,
      defaultNS: defaultNamespace,
      ns: defaultNamespace,
      resources,
      detection: {
        // https://github.com/i18next/i18next-browser-languageDetector
        // For now we only have a single locale, so it doesn't matter where we get the key from.
        order: ["htmlTag", "localStorage", "navigator"],
      },
    })
