import Snackbar from "@mui/joy/Snackbar"
import IconButton from "@mui/joy/IconButton"
import Typography from "@mui/joy/Typography"

import FaceUncertainIcon from "@common/icons/FaceUncertainIcon"
import ChevronRightRoundedIcon from "@common/icons/ChevronRightRoundedIcon"

import NotificationSymbol from "./NotificationSymbol"
import {
  type NotificationOptions,
  type NotificationProps,
  baseNotificationProps,
} from "./utils"

type WarningNotificationProps = NotificationProps & NotificationOptions

const WarningNotification = (props: WarningNotificationProps) => {
  return (
    <Snackbar
      {...baseNotificationProps(props.toast)}
      startDecorator={
        <NotificationSymbol color="warning">
          <FaceUncertainIcon htmlColor="white" fontSize="xs" />
        </NotificationSymbol>
      }
      endDecorator={
        props.href && (
          <IconButton
            variant="plain"
            color="neutral"
            size="md"
            component="a"
            href={props.href}
            target="_blank"
            rel="noopener"
            aria-label="notification more"
          >
            <ChevronRightRoundedIcon
              sx={({ palette }) => ({ color: palette.text.secondary })}
            />
          </IconButton>
        )
      }
    >
      <Typography level="title-md" textColor="text.primary">
        {props.message}
      </Typography>
    </Snackbar>
  )
}

export default WarningNotification
