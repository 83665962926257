import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const SettingsIcon = (props: SvgIconProps) => {
  const Svg = () => (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9997 2C14.552 2 14.9997 2.44772 14.9997 3V3.90739C14.9997 4.3148 15.2497 4.67624 15.613 4.86049C15.8755 4.99359 16.1297 5.14074 16.3745 5.30094C16.7157 5.5242 17.1541 5.56019 17.5072 5.35632L18.2943 4.90192C18.7725 4.62578 19.3841 4.78966 19.6603 5.26795L21.6603 8.73205C21.9364 9.21034 21.7725 9.82193 21.2943 10.0981L20.5065 10.5529C20.154 10.7564 19.9659 11.1531 19.988 11.5595C19.9959 11.7054 19.9999 11.8522 19.9999 12C19.9999 12.1478 19.9959 12.2947 19.988 12.4405C19.9659 12.8469 20.154 13.2436 20.5065 13.4472L21.2942 13.9019C21.7725 14.1781 21.9364 14.7896 21.6602 15.2679L19.6602 18.732C19.3841 19.2103 18.7725 19.3742 18.2942 19.0981L17.5072 18.6437C17.1541 18.4398 16.7157 18.4758 16.3745 18.6991C16.1297 18.8593 15.8755 19.0064 15.613 19.1395C15.2497 19.3238 14.9997 19.6852 14.9997 20.0926V21C14.9997 21.5523 14.552 22 13.9997 22H9.99968C9.4474 22 8.99968 21.5523 8.99968 21V20.0924C8.99968 19.685 8.74971 19.3236 8.38637 19.1393C8.12406 19.0063 7.87006 18.8592 7.62538 18.6991C7.28419 18.4759 6.84579 18.4399 6.49268 18.6438L5.70579 19.0981C5.2275 19.3742 4.61591 19.2103 4.33977 18.732L2.33977 15.2679C2.06363 14.7897 2.2275 14.1781 2.70579 13.9019L3.49323 13.4473C3.84574 13.2438 4.03387 12.8471 4.0118 12.4406C4.00389 12.2947 3.99988 12.1478 3.99988 12C3.99988 11.8522 4.00389 11.7053 4.0118 11.5594C4.03386 11.153 3.84574 10.7563 3.49323 10.5527L2.70573 10.0981C2.22744 9.82193 2.06357 9.21033 2.33971 8.73204L4.33971 5.26794C4.61585 4.78965 5.22744 4.62577 5.70573 4.90192L6.49266 5.35624C6.84577 5.56012 7.28417 5.52413 7.62536 5.30087C7.87005 5.14076 8.12405 4.9937 8.38637 4.86067C8.74971 4.6764 8.99968 4.31498 8.99968 3.90758V3C8.99968 2.44772 9.44739 2 9.99968 2H13.9997ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
    />
  )

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <Svg />
    </SvgIcon>
  )
}

export default SettingsIcon
