import type { MarketSlug } from "@future/market/config/constants"
import type { FullMarketInfo } from "@perps/sdk/client/CosmosClient"
import type { MarketId } from "@perps/sdk/types"

export interface MarketLookup {
  ids: MarketId[]
  addresses: string[]
  slugToId: (slug: MarketSlug) => MarketId | undefined
  addressToId: (address: string) => MarketId | undefined
}

export const createMarketLookup = (
  previousMarketLookup: MarketLookup | undefined,
  markets: Map<MarketId, FullMarketInfo>,
): MarketLookup => {
  const addresses = [...markets.values()].map(
    (market) => market.config.marketAddress,
  )

  if (previousMarketLookup) {
    if (previousMarketLookup.addresses.length === addresses.length) {
      const hasAllAddresses = previousMarketLookup.addresses.every((value) =>
        addresses.includes(value),
      )

      if (hasAllAddresses) {
        return previousMarketLookup
      }
    }
  }

  const ids: MarketId[] = []
  const slugToIdMap = new Map<MarketSlug, MarketId>()
  const addressToIdMap = new Map<string, MarketId>()

  for (const market of markets.values()) {
    ids.push(market.config.id)
    slugToIdMap.set(market.config.slug, market.config.id)
    addressToIdMap.set(market.config.marketAddress, market.config.id)
  }

  return {
    ids,
    addresses,
    slugToId: (slug: MarketSlug) => slugToIdMap.get(slug),
    addressToId: (address: string) => addressToIdMap.get(address),
  }
}
