import BigNumber from "bignumber.js"

export type RoundingMode = BigNumber.RoundingMode

export const round = {
  up: BigNumber.ROUND_UP,
  down: BigNumber.ROUND_DOWN,
  ceil: BigNumber.ROUND_CEIL,
  floor: BigNumber.ROUND_FLOOR,
  halfUp: BigNumber.ROUND_HALF_UP,
  halfDown: BigNumber.ROUND_HALF_DOWN,
  halfEven: BigNumber.ROUND_HALF_EVEN,
  halfCeil: BigNumber.ROUND_HALF_CEIL,
  halfFloor: BigNumber.ROUND_HALF_FLOOR,
}

export type ConstructableAmount<This = Amount> = This | number | string

export interface AmountFormatOptions {
  minDecimalPlaces?: number
  maxDecimalPlaces?: number
  zeroTrim?: boolean
  comma?: boolean
  prefix?: string
  suffix?: string
  roundingMode?: RoundingMode
}

export class Amount {
  private normalizedNumber: BigNumber
  readonly specialFormat:
    | { type: "empty" }
    | { type: "trailingDecimalPoint" }
    | { type: "trailingZero"; count: number }
    | undefined

  constructor(amount: ConstructableAmount<Amount>) {
    this.normalizedNumber = Amount.bigNumberFromAmount(amount)

    if (typeof amount === "string") {
      if (amount.length === 0) {
        this.specialFormat = {
          type: "empty",
        }
      } else if (amount[amount.length - 1] === ".") {
        this.specialFormat = {
          type: "trailingDecimalPoint",
        }
      } else if (amount[amount.length - 1] === "0" && amount.includes(".")) {
        this.specialFormat = {
          type: "trailingZero",
          count: amount.split(".")[1].length,
        }
      }
    }
  }

  /**
   * A comparable value resembling +Inf
   */
  static infiniteLike = new Amount(1e15)

  static nominatedToDenominated(
    amount: ConstructableAmount<Amount>,
    decimals: number,
  ) {
    return new Amount(
      Amount.bigNumberFromAmount(amount)
        .multipliedBy(new BigNumber(10).pow(decimals))
        .toFixed(0, round.down),
    )
  }

  static denominatedToNominated(
    amount: ConstructableAmount<Amount>,
    decimals: number,
  ) {
    return new Amount(
      Amount.bigNumberFromAmount(amount)
        .dividedBy(new BigNumber(10).pow(decimals))
        .toFixed(),
    )
  }

  new(amount: ConstructableAmount<Amount>): this {
    // biome-ignore lint:
    const constructor: any = this.constructor
    return new constructor(amount)
  }

  toAmount() {
    return new Amount(this)
  }

  plus(otherAmount: ConstructableAmount<this>) {
    const amount = this.normalizedNumber
      .plus(Amount.bigNumberFromAmount(otherAmount))
      .toString()

    return this.new(amount)
  }

  minus(otherAmount: ConstructableAmount<this>) {
    const amount = this.normalizedNumber
      .minus(Amount.bigNumberFromAmount(otherAmount))
      .toString()

    return this.new(amount)
  }

  times(otherAmount: ConstructableAmount<this>) {
    const amount = this.normalizedNumber
      .times(Amount.bigNumberFromAmount(otherAmount))
      .toString()

    return this.new(amount)
  }

  divide(otherAmount: ConstructableAmount<this>) {
    const amount = this.normalizedNumber
      .div(Amount.bigNumberFromAmount(otherAmount))
      .toString()

    return this.new(amount)
  }

  pow(otherAmount: ConstructableAmount<this>) {
    const amount = this.normalizedNumber
      .pow(Amount.bigNumberFromAmount(otherAmount))
      .toString()
    return this.new(amount)
  }

  negate() {
    const amount = this.normalizedNumber.negated().toString()

    return this.new(amount)
  }

  abs() {
    const amount = this.normalizedNumber.abs().toString()

    return this.new(amount)
  }

  ceil(roundingMode: RoundingMode = round.ceil) {
    const amount = this.normalizedNumber
      .decimalPlaces(0, roundingMode)
      .toString()

    return this.new(amount)
  }

  floor(roundingMode: RoundingMode = round.floor) {
    const amount = this.normalizedNumber
      .decimalPlaces(0, roundingMode)
      .toString()

    return this.new(amount)
  }

  round(roundingMode: RoundingMode = round.halfEven) {
    const amount = this.normalizedNumber
      .decimalPlaces(0, roundingMode)
      .toString()

    return this.new(amount)
  }

  maxDecimalPlaces(
    maxPlaces: number,
    roundingMode: RoundingMode = round.halfEven,
  ) {
    const amount = this.normalizedNumber
      .decimalPlaces(maxPlaces, roundingMode)
      .toString()

    return this.new(amount)
  }

  toString() {
    return this.toFormat({
      zeroTrim: true,
      comma: false,
    })
  }

  toNumber() {
    return this.normalizedNumber.toNumber()
  }

  /**
   * Convert this to a string to be sent in chain message
   *
   * @param _args accessible for subclass overriding
   */
  toChain(..._args: unknown[]) {
    return this.toFormat({
      zeroTrim: true,
      comma: false,
      maxDecimalPlaces: 18,
      roundingMode: round.down,
    })
  }

  /**
   * Convert this to a shortened string
   *
   * @param _args accessible for subclass overriding
   */
  toAbbreviate(..._args: unknown[]) {
    const maxTrailingPrecision = 2

    const totalPrecision = this.normalizedNumber.precision(true)
    const trailingPrecision = this.normalizedNumber.decimalPlaces() ?? 0
    const leadingPrecision = totalPrecision - trailingPrecision

    const suffix: string = (() => {
      switch (true) {
        case leadingPrecision <= 3:
          return ""
        case leadingPrecision <= 6:
          return "K"
        case leadingPrecision <= 9:
          return "M"
        case leadingPrecision <= 12:
          return "B"
        case leadingPrecision <= 15:
          return "T"
        default:
          return ""
      }
    })()

    const number = this.normalizedNumber
      .shiftedBy(-Math.floor((leadingPrecision - 1) / 3) * 3)
      .decimalPlaces(maxTrailingPrecision)

    const decimalPlaces = number.decimalPlaces() ?? 0

    return number.toFormat(decimalPlaces, round.halfEven, {
      decimalSeparator: ".",
      suffix,
    })
  }

  toFormat(options?: AmountFormatOptions) {
    const minPlaces = options?.minDecimalPlaces ?? 0
    const maxPlaces = options?.maxDecimalPlaces
    const zeroTrim = options?.zeroTrim ?? true
    const comma = options?.comma ?? false
    const roundingMode = options?.roundingMode ?? round.halfEven

    const format: BigNumber.Format = {
      groupSeparator: comma ? "," : undefined,
      groupSize: 3,
      decimalSeparator: ".",
      prefix: options?.prefix,
      suffix: options?.suffix,
    }

    const places = (() => {
      if (zeroTrim && maxPlaces !== undefined) {
        const maxTrimmedPlaces = this.normalizedNumber
          .decimalPlaces(maxPlaces, roundingMode)
          .decimalPlaces()

        return Math.min(maxPlaces, Math.max(minPlaces, maxTrimmedPlaces ?? 0))
      }

      return maxPlaces
    })()

    if (places !== undefined) {
      return this.normalizedNumber
        .decimalPlaces(places, roundingMode) // prevents negative zero
        .toFormat(places, roundingMode, format)
    } else {
      return this.normalizedNumber.toFormat(format)
    }
  }

  isEqualTo(otherAmount: ConstructableAmount<this>): boolean {
    return this.normalizedNumber.isEqualTo(
      Amount.bigNumberFromAmount(otherAmount),
    )
  }

  isGreaterThan(otherAmount: ConstructableAmount<this>): boolean {
    return this.normalizedNumber.isGreaterThan(
      Amount.bigNumberFromAmount(otherAmount),
    )
  }

  isGreaterThanOrEqualTo(otherAmount: ConstructableAmount<this>): boolean {
    return this.normalizedNumber.isGreaterThanOrEqualTo(
      Amount.bigNumberFromAmount(otherAmount),
    )
  }

  isLessThan(otherAmount: ConstructableAmount<this>): boolean {
    return this.normalizedNumber.isLessThan(
      Amount.bigNumberFromAmount(otherAmount),
    )
  }

  isLessThanOrEqualTo(otherAmount: ConstructableAmount<this>): boolean {
    return this.normalizedNumber.isLessThanOrEqualTo(
      Amount.bigNumberFromAmount(otherAmount),
    )
  }

  min(otherAmount: ConstructableAmount<this>) {
    if (this.isLessThan(otherAmount)) {
      return this.new(this)
    } else {
      return this.new(otherAmount)
    }
  }

  max(otherAmount: ConstructableAmount<this>) {
    if (this.isGreaterThan(otherAmount)) {
      return this.new(this)
    } else {
      return this.new(otherAmount)
    }
  }

  closer(a: ConstructableAmount<this>, b: ConstructableAmount<this>) {
    const aAmount = this.new(a)
    const bAmount = this.new(b)

    if (this.isInfinite()) {
      return aAmount.isGreaterThan(bAmount) ? aAmount : bAmount
    } else if (aAmount.isInfinite()) {
      return bAmount
    } else if (bAmount.isInfinite()) {
      return aAmount
    }

    if (this.minus(aAmount).abs().isLessThan(this.minus(bAmount).abs())) {
      return aAmount
    } else {
      return bAmount
    }
  }

  isInfinite(): boolean {
    return !this.normalizedNumber.isFinite()
  }

  private static bigNumberFromAmount(
    amount: ConstructableAmount<Amount>,
  ): BigNumber {
    if (amount instanceof Amount) {
      return amount.normalizedNumber
    } else if (new BigNumber(amount).isNaN()) {
      return new BigNumber(0)
    } else {
      return new BigNumber(amount)
    }
  }
}
