import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 2 10">
      <rect width="2" height="2" rx="1" />
      <rect y="4" width="2" height="6" rx="1" />
    </SvgIcon>
  )
}

export default InfoIcon
