import { queryOptions } from "@tanstack/react-query"

import type CosmosClient from "@perps/sdk/client/CosmosClient"
import type { TargetInfo } from "@future/target/info"
import { AppError } from "@future/libs/error/AppError"
import { SECOND } from "@common/utils/date"

export const marketsQueryOptions = (
  chainClient: CosmosClient,
  targetInfo: TargetInfo,
) => {
  return queryOptions({
    queryKey: ["markets", targetInfo],
    refetchInterval: SECOND * 3,
    refetchIntervalInBackground: true,
    queryFn: async (context) => {
      try {
        const markets = await chainClient.queryMarkets(
          targetInfo,
          context.signal,
          true,
        )

        return markets
      } catch (error) {
        throw AppError.fromText(
          `Could not load market configs for ${targetInfo.id}`,
          { cause: error },
        )
      }
    },
  })
}
