import { queryOptions } from "@tanstack/react-query"

import type { ChainNetworkId } from "@common/target/types"
import { AppError } from "@future/libs/error/AppError"
import {
  type TargetConfigCw20,
  mainnetTargetConfig,
  type MaintenanceMessage,
  type TargetConfig,
} from "@future/target/config"
import type { TargetInfo } from "@future/target/info"
import { httpGet } from "@future/utils/http"

interface TargetConfigResponse {
  factory: string
  faucet: string
  network: ChainNetworkId
  explorer: string
  cw20s: TargetConfigCw20[]
  faucet_gas_amount?: string
  faucet_collateral_amount: Record<string, string>
  maintenance?: string | null
}

export const targetConfigQueryOptions = (targetInfo: TargetInfo) => {
  return queryOptions({
    queryKey: ["targetConfig", targetInfo],
    retry: true,
    queryFn: async (context): Promise<TargetConfig> => {
      if (targetInfo.category === "mainnet") {
        // For better security, we always hard-code the target config for mainnet
        return mainnetTargetConfig(targetInfo.id)
      }

      const response = await httpGet<TargetConfigResponse>(
        `https://static.levana.finance/perps-frontend-config/${targetInfo.id}.json`,
        { signal: context.signal },
      )

      if (response.status !== 200) {
        throw AppError.fromResponse(response, {
          text: "Unable to fetch target config",
        })
      }

      const targetConfig = response.data

      return {
        factory: targetConfig.factory,
        faucet: targetConfig.faucet,
        explorer: targetConfig.explorer,
        cw20s: targetConfig.cw20s,
        faucetGasAmount: targetConfig.faucet_gas_amount,
        faucetCollateralAmount: targetConfig.faucet_collateral_amount ?? {},
        maintenance: parseMaintenanceMessage(targetConfig.maintenance),
      }
    },
  })
}

const parseMaintenanceMessage = (
  maintenance: string | undefined | null,
): MaintenanceMessage | undefined => {
  switch (maintenance) {
    case "general":
      return "general"
    case "sei-unstable":
      return "sei-unstable"
    default:
      return undefined
  }
}
