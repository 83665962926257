import IconButton from "@mui/joy/IconButton"
import Typography from "@mui/joy/Typography"
import { useTranslation } from "react-i18next"

import CopyIcon from "@common/icons/CopyIcon"

import { useCopyId } from "../useCopyId"

export interface CopyIdButtonProps {
  type: "position" | "order" | "action" | "referral"
  id: string
  showId?: boolean
}

const CopyIdButton = (props: CopyIdButtonProps) => {
  const copyId = useCopyId()
  const { t } = useTranslation("perpsCommon")
  const { id, type, showId = true } = props

  const handleClick: React.MouseEventHandler = (event) => {
    event.stopPropagation()

    const message = ((): string => {
      switch (type) {
        case "position":
          return t("copy.position.message")
        case "order":
          return t("copy.order.message")
        case "action":
          return t("copy.action.message")
        case "referral":
          return t("copy.referral.message")
      }
    })()

    copyId.handleCopy(id, message)
  }

  return (
    <IconButton
      variant="plain"
      color="neutral"
      size="sm"
      onClick={handleClick}
      sx={({ vars }) => ({ color: vars.palette.text.secondary })}
      aria-label={`copy ${type} id`}
    >
      {showId && <Typography sx={{ marginRight: 1 }}>{id}</Typography>}
      <CopyIcon fontSize="lg" />
    </IconButton>
  )
}

export default CopyIdButton
