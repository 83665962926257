import { WalletType, getWallet } from "graz";
import { leapWallet } from "../leap";
import { base64Image } from "./icon";
const walletInfo = {
    name: "Compass",
    icon: base64Image,
    install: {
        chrome: "https://chrome.google.com/webstore/detail/compass-wallet-for-sei/anokgmphncpekkhclmingpimjmcooifb",
        ios: "https://apps.apple.com/us/app/compass-wallet-for-sei/id6450257441",
        android: "https://play.google.com/store/apps/details?id=io.leapwallet.compass",
    },
    isChainSupported: (chainId) => {
        switch (chainId) {
            case "pacific-1":
            case "atlantic-2":
                return true;
            default:
                return false;
        }
    },
    // Compass is forked from Leap
    errors: leapWallet.info.errors,
};
export const compassWallet = {
    provider: () => {
        const installed = typeof window.compass !== "undefined";
        if (!installed) {
            return { installed };
        }
        const subscription = (reconnect) => {
            const listener = () => {
                reconnect();
            };
            window.addEventListener("leap_keystorechange", listener);
            return () => {
                window.removeEventListener("leap_keystorechange", listener);
            };
        };
        const provider = getWallet(WalletType.COMPASS);
        return {
            installed,
            getKey: provider.getKey,
            getOfflineSigner: provider.getOfflineSigner,
            subscription,
        };
    },
    info: walletInfo,
};
