import Button from "@mui/joy/Button"
import { useTranslation } from "react-i18next"
import NavigationModal, {
  NavigationModalDynamicItem,
} from "@levana/theme/NavigationModal"

import FaceUncertainIcon from "@common/icons/FaceUncertainIcon"
import ErrorContent from "@future/libs/error/ErrorContent"
import type { AppError } from "@future/libs/error/AppError"

import NotificationSymbol from "./NotificationSymbol"

interface NotificationErrorModalProps {
  message: string
  error: AppError
}

const NotificationErrorModal = (props: NotificationErrorModalProps) => {
  const { t } = useTranslation("common")

  return (
    <NavigationModal rootId={NotificationErrorModal.name}>
      {(modal) => (
        <NavigationModalDynamicItem
          id={NotificationErrorModal.name}
          title={t("notification.error.title")}
          subtitle={props.error.transactionId}
          icon={
            <NotificationSymbol color="danger">
              <FaceUncertainIcon htmlColor="white" fontSize="sm" />
            </NotificationSymbol>
          }
        >
          <ErrorContent error={props.error} />
          <Button
            fullWidth
            onClick={modal.close}
            sx={{ mt: 4 }}
            aria-label="error modal close"
          >
            {t("notification.error.close")}
          </Button>
        </NavigationModalDynamicItem>
      )}
    </NavigationModal>
  )
}

export default NotificationErrorModal
