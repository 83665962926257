import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const ExclamationMarkShortIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 4 16">
      <rect width="4" height="10" rx="2" />
      <rect y="12" width="4" height="4" rx="2" />
    </SvgIcon>
  )
}

export default ExclamationMarkShortIcon
