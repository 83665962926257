import { create } from "zustand";
import { subscribeWithSelector, persist, } from "zustand/middleware";
import { WalletType } from "./wallet";
export const useChainStore = create(() => ({
    chainId: "",
    chains: [],
    chainsConfig: {},
}));
export const accountStoreDefaultValues = {
    walletType: WalletType.keplr,
    status: "disconnected",
};
const accountStoreOptions = {
    name: "AccountStore",
    version: 1,
    partialize: (state) => {
        if ("walletAddress" in state) {
            return {
                walletType: state.walletType,
                walletAddress: state.walletAddress,
            };
        }
        return {
            walletType: state.walletType,
        };
    },
};
export const useAccountStore = create(subscribeWithSelector(persist(() => accountStoreDefaultValues, accountStoreOptions)));
