import { Icon } from "./icon";
const walletInfo = {
    name: "View Only",
    icon: Icon,
    install: {
        chrome: "",
    },
    isChainSupported: () => {
        return true;
    },
    errors: {
        generic: [],
    },
};
export const viewingWallet = {
    provider: () => {
        const installed = true;
        return {
            installed,
        };
    },
    info: walletInfo,
};
