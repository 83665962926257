var _a, _b, _c, _d, _e, _f;
import { ChainId as InjectiveChainId } from "@injectivelabs/ts-types";
import { WalletErrorType } from "../../shared/error";
import { base64Image } from "./icon";
import { okxWallet } from "../okx";
const injectiveErrors = [
    {
        message: "User rejected the request.",
        type: WalletErrorType.rejected,
    },
    {
        // XDEFI proxy will throw an empty message error when access rejecting
        message: "",
        type: WalletErrorType.rejected,
    },
];
const walletInfo = {
    name: "MetaMask",
    icon: base64Image,
    install: {
        chrome: "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
        firefox: "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask",
        edge: "https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm",
        ios: "https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202",
        android: "https://play.google.com/store/apps/details?id=io.metamask",
    },
    isChainSupported: (chainId) => {
        switch (chainId) {
            case InjectiveChainId.Mainnet:
            case InjectiveChainId.Testnet:
            case InjectiveChainId.Devnet:
                return true;
            default:
                return false;
        }
    },
    errors: {
        generic: [
            {
                message: "User rejected the request",
                type: WalletErrorType.rejected,
            },
            {
                message: "User denied transaction",
                type: WalletErrorType.rejected,
            },
            {
                message: "MetaMask Typed Message Signature: User denied message signature.",
                type: WalletErrorType.rejected,
            },
        ],
        chain: {
            // Include proxy wallet errors
            [InjectiveChainId.Mainnet]: [
                ...((_b = (_a = okxWallet.info.errors.chain) === null || _a === void 0 ? void 0 : _a[InjectiveChainId.Mainnet]) !== null && _b !== void 0 ? _b : []),
                ...injectiveErrors,
            ],
            [InjectiveChainId.Testnet]: [
                ...((_d = (_c = okxWallet.info.errors.chain) === null || _c === void 0 ? void 0 : _c[InjectiveChainId.Testnet]) !== null && _d !== void 0 ? _d : []),
                ...injectiveErrors,
            ],
            [InjectiveChainId.Devnet]: [
                ...((_f = (_e = okxWallet.info.errors.chain) === null || _e === void 0 ? void 0 : _e[InjectiveChainId.Devnet]) !== null && _f !== void 0 ? _f : []),
                ...injectiveErrors,
            ],
        },
    },
};
export const metaMaskWallet = {
    provider: () => {
        const installed = typeof window.ethereum !== "undefined";
        if (!installed) {
            return { installed };
        }
        return {
            installed,
        };
    },
    info: walletInfo,
};
