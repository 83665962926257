import { EventKey } from "@perps/analytics/constants"
import { AppError } from "@future/libs/error/AppError"

import type { AppErrorKeyOptions } from "./AppError"

export type CreateAppErrorKeyOptions = (
  message: string,
) => AppErrorKeyOptions | undefined

export const extractFailureReason = (error: unknown): string | undefined => {
  if (error instanceof AppError) {
    return error.analytics?.[EventKey.CONTRACT_ERROR_ID]
  }
}
