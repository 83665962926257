import Button, { type ButtonProps } from "@mui/joy/Button"
import { useTheme } from "@mui/joy/styles"
import useMediaQuery from "@mui/system/useMediaQuery"
import { forwardRef } from "react"
import { WalletType } from "@levana/chain/wallet"
import type { ConnectResult } from "@levana/chain/account"

import WalletAvatar from "@common/ui/WalletAvatar"
import ChevronDownRoundedIcon from "@common/icons/ChevronDownRoundedIcon"

export interface HeaderConnectedButtonProps
  extends Omit<ButtonProps, "children"> {
  isOpen: boolean
  connectedWallet: ConnectResult
}

const HeaderConnectedButton = forwardRef<
  HTMLButtonElement,
  HeaderConnectedButtonProps
>((props, ref) => {
  const { isOpen, connectedWallet, ...joyProps } = props

  const theme = useTheme()
  const smallQuery = theme.breakpoints.between("xs", "sm")
  const isSmallScreen = useMediaQuery(smallQuery)

  return (
    <Button
      {...joyProps}
      ref={ref}
      variant="outlined"
      color="neutral"
      startDecorator={
        <WalletAvatar
          address={connectedWallet.account.bech32Address}
          variant="outlined"
          viewing={connectedWallet.walletType === WalletType.viewing}
        />
      }
      sx={[
        {
          "--Avatar-size": { xs: "2.25rem", sm: "1.5rem" },
          "--Button-gap": { xs: 0, sm: "0.75rem" },
          "--variant-borderWidth": { xs: 0, sm: "2px" },
          paddingInline: { xs: 0, sm: "0.3rem 0.5rem" },

          [smallQuery]: {
            paddingBlock: 0,
          },
        },
        isOpen && {
          backgroundColor: "background.level2",
        },
      ]}
      aria-label="connected menu"
    >
      {!isSmallScreen && (
        <>
          {connectedWallet.truncateAddress()}
          <ChevronDownRoundedIcon
            sx={{ ml: 0.75, rotate: isOpen ? `z ${180}deg` : "none" }}
          />
        </>
      )}
    </Button>
  )
})

export default HeaderConnectedButton
