import type { SnackbarOrigin, SnackbarProps } from "@mui/joy/Snackbar"
import type { Toast, ToastPosition } from "react-hot-toast"

export interface NotificationProps {
  toast: Toast
  message: string
}

export interface NotificationOptions {
  href?: string
}

export const positionToOrigin = (position?: ToastPosition): SnackbarOrigin => {
  switch (position) {
    case "bottom-center":
      return { vertical: "bottom", horizontal: "center" }
    case "bottom-left":
    case undefined:
      return { vertical: "bottom", horizontal: "left" }
    case "bottom-right":
      return { vertical: "bottom", horizontal: "right" }
    case "top-center":
      return { vertical: "top", horizontal: "center" }
    case "top-left":
      return { vertical: "top", horizontal: "left" }
    case "top-right":
      return { vertical: "top", horizontal: "right" }
  }
}

export const baseNotificationProps = (toast: Toast): SnackbarProps => {
  return {
    variant: "soft",
    color: "neutral",
    open: toast.visible,
    anchorOrigin: positionToOrigin(toast.position),
    slotProps: {
      endDecorator: {
        sx: ({ vars }) => ({
          "::before": {
            content: "''",
            borderLeft: "1px solid",
            borderLeftColor: vars.palette.text.tertiary,
            pl: 1.5,
          },
        }),
      },
    },
  }
}
