import Box from "@mui/joy/Box"
import { useEffect } from "react"
import { toast as controller, resolveValue, useToaster } from "react-hot-toast"

import { positionToOrigin } from "./utils"

const maxToastsCount = 3

const NotificationLayout = () => {
  const { toasts, handlers } = useToaster()
  const { startPause, endPause, calculateOffset, updateHeight } = handlers

  useEffect(() => {
    if (maxToastsCount >= toasts.length) {
      return
    }

    for (let i = toasts.length; i >= 0; i -= 1) {
      const toast = toasts.at(i)

      if (toast && i + 1 > maxToastsCount) {
        controller.dismiss(toast.id)
      }
    }
  }, [toasts])

  return (
    <Box>
      {toasts.map((toast) => {
        const origin = positionToOrigin(toast.position)

        const offset = calculateOffset(toast, {
          reverseOrder: false,
          gutter: 8,
        })

        const ref = (element: HTMLDivElement | null) => {
          if (element && typeof toast.height !== "number") {
            const height =
              element.firstElementChild?.getBoundingClientRect().height

            if (height) {
              updateHeight(toast.id, height)
            }
          }
        }

        return (
          <Box
            key={toast.id}
            ref={ref}
            onMouseEnter={startPause}
            onMouseLeave={endPause}
            sx={({ vars }) => ({
              position: "fixed",
              width: { xs: "100%", sm: "unset" },
              [origin.vertical]: offset,
              zIndex: vars.zIndex.snackbar,
              "& > *": {
                position: "relative",
                width: {
                  xs: "calc(100% - (var(--Snackbar-inset) * 2))",
                  sm: "unset",
                },
              },
            })}
          >
            {resolveValue(toast.message, toast)}
          </Box>
        )
      })}
    </Box>
  )
}

export default NotificationLayout
