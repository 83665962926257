var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IconButton from "@mui/joy/IconButton";
import ModalClose from "@mui/joy/ModalClose";
import { styled, useThemeProps } from "@mui/joy/styles";
import useSlot from "@mui/joy/utils/useSlot";
import { forwardRef } from "react";
import ArrowBackIcon from "./icons/ArrowBackIcon";
const name = "LevanaNavigationModalHeader";
const NavigationModalHeaderRoot = styled("div", {
    name,
    slot: "root",
})({
    "--IconButton-size": "40px",
    "--IconButton-radius": "calc(var(--IconButton-size) / 2)",
    "--ModalClose-radius": "var(--IconButton-radius)",
    textAlign: "center",
    paddingTop: "var(--ModalContent-padding)",
    paddingInline: "var(--ModalContent-padding)",
});
const NavigationModalHeaderClose = styled(ModalClose, {
    name,
    slot: "close",
})({});
const NavigationModalHeader = forwardRef(function NavigationModalHeader(inProps, ref) {
    const props = useThemeProps({ props: inProps, name });
    const { onBack, canClose, topDecorator, children } = props, otherProps = __rest(props, ["onBack", "canClose", "topDecorator", "children"]);
    const ownerState = Object.assign(Object.assign({}, props), { canClose });
    const externalForwardedProps = Object.assign({}, otherProps);
    const [SlotRoot, rootProps] = useSlot("root", {
        ref,
        className: "root",
        elementType: NavigationModalHeaderRoot,
        ownerState,
        externalForwardedProps,
    });
    const [SlotClose, closeProps] = useSlot("close", {
        ref,
        className: "close",
        elementType: NavigationModalHeaderClose,
        ownerState,
        externalForwardedProps,
        internalForwardedProps: {
            color: "neutral",
            variant: "outlined",
            "aria-label": "close",
        },
    });
    return (_jsxs(SlotRoot, Object.assign({}, rootProps, { children: [topDecorator, onBack && (_jsx(IconButton, { onClick: onBack, color: "neutral", variant: "outlined", sx: {
                    position: "absolute",
                    top: "var(--ModalClose-inset)",
                    left: "var(--ModalClose-inset)",
                }, "aria-label": "back", children: _jsx(ArrowBackIcon, {}) })), children, canClose && _jsx(SlotClose, Object.assign({}, closeProps))] })));
});
export default NavigationModalHeader;
