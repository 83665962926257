import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const FaceUncertainIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 17">
      <g fill={props.htmlColor}>
        <rect y="0.572693" width="4" height="6" rx="2" />
        <rect x="12" y="0.572693" width="4" height="6" rx="2" />
        <path
          d="M2 15.0727V15.0727C5.44952 14.0776 9.02185 13.5727 12.612 13.5727L14 13.5727"
          stroke={props.htmlColor}
          strokeWidth="3"
          strokeLinecap="round"
        />
      </g>
    </SvgIcon>
  )
}

export default FaceUncertainIcon
