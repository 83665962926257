import { useTheme } from "@mui/joy/styles"
import useMediaQuery from "@mui/system/useMediaQuery"

import { usePresenter } from "@common/utils/usePresenter"

export const useController = () => {
  const theme = useTheme()
  const isXsScreen = useMediaQuery(theme.breakpoints.only("xs"))
  const isSmallerThanMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"))
  const isSmallerThanXlScreen = useMediaQuery(theme.breakpoints.down("xl"))
  const isSmallerThanLgScreen = useMediaQuery(theme.breakpoints.down("lg"))
  const isNavInMenu = useMediaQuery(theme.breakpoints.between("xs", "md"))
  const connectedMenuModal = usePresenter({ analyticsKind: "openWalletMenu" })
  const navMenuModal = usePresenter({ analyticsKind: "openNavMenu" })
  const chooseNetworkModal = usePresenter()

  return {
    isXsScreen,
    isSmallerThanMediumScreen,
    isMediumScreen,
    isSmallerThanXlScreen,
    isSmallerThanLgScreen,
    isNavInMenu,
    connectedMenuModal,
    navMenuModal,
    chooseNetworkModal,
  }
}
