import type { TargetId } from "@common/target/types"

export interface TargetConfig {
  factory: string
  faucet?: string
  explorer?: string
  cw20s: TargetConfigCw20[]
  faucetGasAmount?: string
  faucetCollateralAmount: Record<string, string>
  maintenance?: MaintenanceMessage
}

export interface TargetConfigCw20 {
  address: string
  denom: string
  decimals: number
}

export type MaintenanceMessage = "general" | "sei-unstable"

export const mainnetTargetConfig = async (
  targetId: TargetId,
): Promise<TargetConfig> => {
  switch (targetId) {
    case "osmomainnet1": {
      return {
        factory:
          "osmo1ssw6x553kzqher0earlkwlxasfm2stnl3ms3ma2zz4tnajxyyaaqlucd45",
        cw20s: [],
        faucetCollateralAmount: {},
        explorer: "https://www.mintscan.io/osmosis/txs/",
      }
    }
    case "seimainnet1": {
      return {
        factory:
          "sei18rdj3asllguwr6lnyu2sw8p8nut0shuj3sme27ndvvw4gakjnjqqper95h",
        cw20s: [],
        faucetCollateralAmount: {},
        explorer: "https://seiscan.app/pacific-1/txs/",
      }
    }
    case "injmainnet1": {
      return {
        factory: "inj1vdu3s39dl8t5l88tyqwuhzklsx9587adv8cnn9",
        cw20s: [],
        faucetCollateralAmount: {},
        explorer: "https://explorer.injective.network/transaction/",
      }
    }
    case "ntrnmainnet1": {
      return {
        factory:
          "neutron1an8ls6d57c4qcvjq0jmm27jtrpk65twewfjqzdn7annefv7gadqsjs7uc3",
        cw20s: [],
        faucetCollateralAmount: {},
        explorer: "https://www.mintscan.io/neutron/txs/",
      }
    }
    default:
      throw new Error(
        `No local target configs are available, tried to load: ${targetId}`,
      )
  }
}
