import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const WalletIcon = (props: SvgIconProps) => {
  const Svg = () => (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5C7.23858 5 5 7.23858 5 10V11H9C11.7614 11 14 13.2386 14 16C14 18.7614 11.7614 21 9 21H5V22C5 24.7614 7.23858 27 10 27H23C25.7614 27 28 24.7614 28 22V10C28 7.23858 25.7614 5 23 5H10ZM5 19H9C10.6569 19 12 17.6569 12 16C12 14.3431 10.6569 13 9 13H5V19Z"
    />
  )

  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <Svg />
    </SvgIcon>
  )
}

export default WalletIcon
