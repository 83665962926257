import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const ArrowDropDown = (props: SvgIconProps) => {
  const Svg = () => (
    <g>
      <path d="M 5 5 L 10 10 L 15 5 H 5 Z" />
    </g>
  )

  return (
    <SvgIcon {...props} viewBox="0 0 20 16">
      <Svg />
    </SvgIcon>
  )
}

export default ArrowDropDown
