import type { ParseKeys } from "i18next"

import type { LibrarySymbolInfo } from "@public/lib/charting_library"
import atomIcon from "@perps/market/image/atom-logo.svg"
import dAtomIcon from "@perps/market/image/datom-logo.svg"
import amatomIcon from "@perps/market/image/amatom-logo.svg"
import statomIcon from "@perps/market/image/statom-logo.svg"
import stosmoIcon from "@perps/market/image/stosmo-logo.svg"
import ryethIcon from "@perps/market/image/ryeth-logo.svg"
import btcIcon from "@perps/market/image/btc-logo.svg"
import allbtcIcon from "@perps/market/image/allbtc-logo.svg"
import wbtcIcon from "@perps/market/image/wbtc-logo.svg"
import dotIcon from "@perps/market/image/dot-logo.svg"
import ethIcon from "@perps/market/image/eth-logo.svg"
import seiIcon from "@perps/market/image/sei-logo.svg"
import solIcon from "@perps/market/image/sol-logo.svg"
import linkIcon from "@perps/market/image/link-logo.svg"
import evmosIcon from "@perps/market/image/evmos-logo.svg"
import akashIcon from "@perps/market/image/akt-logo.svg"
import secretIcon from "@perps/market/image/secret-logo.svg"
import osmoIcon from "@perps/market/image/osmo-logo.svg"
import injIcon from "@perps/market/image/inj-logo.svg"
import eurIcon from "@perps/market/image/eur-logo.svg"
import goldIcon from "@perps/market/image/gold-logo.svg"
import silverIcon from "@perps/market/image/silver-logo.svg"
import yuanIcon from "@perps/market/image/yuan-logo.svg"
import gbpIcon from "@perps/market/image/gbp-logo.svg"
import bnbIcon from "@perps/market/image/bnb-logo.svg"
import axlIcon from "@perps/market/image/axl-logo.svg"
import dogeIcon from "@perps/market/image/doge-logo.svg"
import lunaIcon from "@perps/market/image/luna-logo.svg"
import tiaIcon from "@perps/market/image/tia-logo.svg"
import sttiaIcon from "@perps/market/image/sttia-logo.svg"
import dymIcon from "@perps/market/image/dym-logo.svg"
import milkTiaIcon from "@perps/market/image/milktia-logo.svg"
import stkatomIcon from "@perps/market/image/stkatom-logo.svg"
import nbtcIcon from "@perps/market/image/nbtc-logo.svg"
import dydxIcon from "@perps/market/image/dydx-logo.svg"
import stdydxIcon from "@perps/market/image/stdydx-logo.svg"
import stdymIcon from "@perps/market/image/stdym-logo.svg"
import runeIcon from "@perps/market/image/rune-logo.svg"
import xrpIcon from "@perps/market/image/xrp-logo.svg"
import adaIcon from "@perps/market/image/ada-logo.svg"
import trxIcon from "@perps/market/image/trx-logo.svg"
import maticIcon from "@perps/market/image/matic-logo.svg"
import avaxIcon from "@perps/market/image/avax-logo.svg"
import pythIcon from "@perps/market/image/pyth-logo.svg"
import usdtIcon from "@perps/market/image/usdt-logo.svg"
import usdcIcon from "@perps/market/image/usdc-logo.svg"
import ntrnIcon from "@perps/market/image/ntrn-logo.svg"
import coinIcon from "@perps/market/image/coin-logo.svg"
import wifIcon from "@perps/market/image/wif-logo.svg"
import memeIcon from "@perps/market/image/meme-logo.svg"
import pepeIcon from "@perps/market/image/pepe-logo.svg"
import shibIcon from "@perps/market/image/shib-logo.svg"
import flokiIcon from "@perps/market/image/floki-logo.svg"
import bonkIcon from "@perps/market/image/bonk-logo.svg"
import magaIcon from "@perps/market/image/maga-logo.svg"
import wstEthIcon from "@perps/market/image/wsteth-logo.svg"
import type { MarketId, MarketType } from "@perps/sdk/types"

import { MarketDisplayConfigMap } from "./marketDisplayConfig"

export const lvnAddress =
  "factory/osmo1mlng7pz4pnyxtpq0akfwall37czyk9lukaucsrn30ameplhhshtqdvfm5c/ulvn"

/// How the market is displayed in URLs
export class MarketSlug {
  slug: string
  private constructor(slug: string) {
    this.slug = slug
  }

  static get(slug: string): MarketSlug {
    const ret = MARKET_SLUGS.get(slug)
    if (ret !== undefined) {
      return ret
    }
    const newSlug = new MarketSlug(slug)
    MARKET_SLUGS.set(slug, newSlug)
    return newSlug
  }

  toString(): string {
    return this.slug
  }

  toJSON(): string {
    return this.slug
  }
}

const MARKET_SLUGS: Map<string, MarketSlug> = new Map()

export interface MarketDisplayConfig {
  icon: string
  collateralIcon: string
  benchmarksSymbol:
    | string
    | {
        id: string
        inverted: boolean
      }[]
  pythHistoryFallback: PythHistoryFallback
  makeTradingViewSymbolInfo: () => LibrarySymbolInfo
  slug: MarketSlug
  id: MarketId
  base: string
  quote: string
  collateral: string
  notional: string
  type: MarketType
  /// Used for display within the UI
  title: string
  /// Title used on the earn page list specifically
  earnPageTitle: string
  /// Any warning to be displayed about the price feed
  priceFeedWarning?: ParseKeys<"priceFeed">
  /**
   * Override the number of quote decimal places
   *
   * Helpful for collateral is quote markets which have a small USD value
   */
  quoteDecimalPlaces?: number
}

export interface PythHistoryFallback {
  network: "edge" | "stable"
  ids: {
    /**
     * @link https://pyth.network/developers/price-feed-ids#cosmwasm-stable
     */
    id: string
    inverted: boolean
  }[]
}

export const marketDisplayConfigMap = new MarketDisplayConfigMap([
  {
    id: "ATOM_USD",
    title: "ATOM / USD",
    description: "Atom",
    slug: MarketSlug.get("ATOM_USD"),
    base: "ATOM",
    quote: "USD",
    type: "collateral_is_base",
    icon: atomIcon,
    collateralIcon: atomIcon,
    benchmarksSymbol: "Crypto.ATOM/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "b00b60f88b03a6a625a8d1c048c3f66653edf217439983d037e7222c4e612819",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "amATOM_USD",
    title: "amATOM / USD",
    description: "Amulet Atom",
    slug: MarketSlug.get("amATOM_USD"),
    base: "amATOM",
    quote: "USD",
    type: "collateral_is_base",
    icon: amatomIcon,
    collateralIcon: amatomIcon,
    benchmarksSymbol: "Crypto.ATOM/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "b00b60f88b03a6a625a8d1c048c3f66653edf217439983d037e7222c4e612819",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "dATOM_USD",
    title: "ATOM / USD",
    description: "Atom",
    slug: MarketSlug.get("dATOM_USD"),
    base: "dATOM",
    quote: "USD",
    type: "collateral_is_base",
    icon: atomIcon,
    collateralIcon: dAtomIcon,
    benchmarksSymbol: "Crypto.ATOM/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "b00b60f88b03a6a625a8d1c048c3f66653edf217439983d037e7222c4e612819",
          inverted: false,
        },
      ],
    },
    // FIXME do we want a price feed warning about the differences between the two markets?
    // I know it hasn't be specified by product, but I think it's worth raising.
    // priceFeedWarning: "centralized",
  },
  {
    id: "stATOM_USD",
    title: "stATOM / USD",
    description: "stAtom",
    slug: MarketSlug.get("stATOM_USD"),
    base: "stATOM",
    quote: "USD",
    type: "collateral_is_base",
    icon: statomIcon,
    collateralIcon: statomIcon,
    // FIXME - this is a hack to get the chart to work
    // it's going to be off by at least the redemption rate
    // however that is accounted for in hardcoded workarounds for now
    benchmarksSymbol: "Crypto.ATOM/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "b00b60f88b03a6a625a8d1c048c3f66653edf217439983d037e7222c4e612819",
          inverted: false,
        },
      ],
    },
    priceFeedWarning: "centralized",
  },
  {
    id: "stOSMO_USD",
    title: "stOSMO / USD",
    description: "stOsmo",
    slug: MarketSlug.get("stOSMO_USD"),
    base: "stOSMO",
    quote: "USD",
    type: "collateral_is_base",
    icon: stosmoIcon,
    collateralIcon: stosmoIcon,
    // FIXME - this is a hack to get the chart to work
    // it's going to be off by at least the redemption rate
    // however that is accounted for in hardcoded workarounds for now
    benchmarksSymbol: "Crypto.OSMO/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "5867f5683c757393a0670ef0f701490950fe93fdb006d181c8265a831ac0c5c6",
          inverted: false,
        },
      ],
    },
    priceFeedWarning: "centralized",
  },
  {
    id: "stDYDX_USD",
    title: "stDYDX / USD",
    description: "stDYDX",
    slug: MarketSlug.get("stDYDX_USD"),
    base: "stDYDX",
    quote: "USD",
    type: "collateral_is_base",
    icon: stdydxIcon,
    collateralIcon: stdydxIcon,
    // FIXME - this is a hack to get the chart to work
    // it's going to be off by at least the redemption rate
    // however that is accounted for in hardcoded workarounds for now
    benchmarksSymbol: "Crypto.DYDX/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "6489800bb8974169adfe35937bf6736507097d13c190d760c557108c7e93a81b",
          inverted: false,
        },
      ],
    },
    priceFeedWarning: "centralized",
  },
  {
    id: "stTIA_USD",
    title: "stTIA / USD",
    description: "stTIA",
    slug: MarketSlug.get("stTIA_USD"),
    base: "stTIA",
    quote: "USD",
    type: "collateral_is_base",
    icon: sttiaIcon,
    collateralIcon: sttiaIcon,
    // FIXME - this is a hack to get the chart to work
    // it's going to be off by at least the redemption rate
    // however that is accounted for in hardcoded workarounds for now
    benchmarksSymbol: "Crypto.TIA/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "09f7c1d7dfbb7df2b8fe3d3d87ee94a2259d212da4f30c1f0540d066dfa44723",
          inverted: false,
        },
      ],
    },
    priceFeedWarning: "centralized",
  },
  {
    id: "stDYM_USD",
    title: "stDYM / USD",
    description: "stDYM",
    slug: MarketSlug.get("stDYM_USD"),
    base: "stDYM",
    quote: "USD",
    type: "collateral_is_base",
    icon: stdymIcon,
    collateralIcon: stdymIcon,
    // FIXME - this is a hack to get the chart to work
    // it's going to be off by at least the redemption rate
    // however that is accounted for in hardcoded workarounds for now
    benchmarksSymbol: "Crypto.DYM/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "a9f3b2a89c6f85a6c20a9518abde39b944e839ca49a0c92307c65974d3f14a57",
          inverted: false,
        },
      ],
    },
    priceFeedWarning: "centralized",
  },
  {
    id: "ryETH_USD",
    title: "YieldETH / USD",
    description: "Yield Ethereum",
    slug: MarketSlug.get("YieldETH_USD"),
    base: "YieldETH",
    quote: "USD",
    type: "collateral_is_base",
    icon: ryethIcon,
    collateralIcon: ryethIcon,
    // FIXME - this is a hack to get the chart to work
    // it's going to be off by at least the redemption rate
    // however that is accounted for in hardcoded workarounds for now
    benchmarksSymbol: "Crypto.ETH/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
          inverted: false,
        },
      ],
    },
    priceFeedWarning: "centralized",
  },
  {
    id: "wstETH_USD",
    title: "ETH / USD",
    description: "Ethereum",
    slug: MarketSlug.get("ETH_USD"),
    base: "wstETH",
    quote: "USD",
    type: "collateral_is_base",
    icon: ethIcon,
    collateralIcon: wstEthIcon,
    benchmarksSymbol: "Crypto.ETH/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
          inverted: false,
        },
      ],
    },
    // FIXME do we want a price feed warning about the differences between the two markets?
    // I know it hasn't be specified by product, but I think it's worth raising.
    // priceFeedWarning: "centralized",
  },
  {
    id: "axlETH_USD",
    title: "ETH / USD",
    description: "Ethereum",
    slug: MarketSlug.get("ETH_USD"),
    base: "ETH",
    quote: "USD",
    type: "collateral_is_base",
    icon: ethIcon,
    collateralIcon: ethIcon,
    benchmarksSymbol: "Crypto.ETH/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "BTC_USD",
    title: "BTC / USD",
    description: "Bitcoin",
    slug: MarketSlug.get("BTC_USD"),
    base: "BTC",
    quote: "USD",
    type: "collateral_is_base",
    icon: btcIcon,
    collateralIcon: btcIcon,
    benchmarksSymbol: "Crypto.BTC/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "allBTC_USD",
    title: "allBTC / USD",
    description: "Alloyed Bitcoin",
    slug: MarketSlug.get("allBTC_USD"),
    base: "allBTC",
    quote: "USD",
    type: "collateral_is_base",
    icon: allbtcIcon,
    collateralIcon: allbtcIcon,
    benchmarksSymbol: "Crypto.BTC/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "BTC_USDT",
    title: "BTC / USDT",
    description: "Bitcoin",
    slug: MarketSlug.get("BTC_USDT"),
    base: "BTC",
    quote: "USDT",
    type: "collateral_is_quote",
    icon: btcIcon,
    collateralIcon: usdtIcon,
    benchmarksSymbol: "Crypto.BTC/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "wBTC_USD",
    title: "wBTC / USD",
    description: "Wrapped Bitcoin",
    slug: MarketSlug.get("wBTC_USD"),
    base: "wBTC",
    quote: "USD",
    type: "collateral_is_base",
    icon: wbtcIcon,
    collateralIcon: wbtcIcon,
    benchmarksSymbol: "Crypto.wBTC/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "nBTC_USD",
    title: "nBTC / USD",
    description: "Nomic Bitcoin",
    slug: MarketSlug.get("nBTC_USD"),
    base: "nBTC",
    quote: "USD",
    type: "collateral_is_base",
    icon: nbtcIcon,
    collateralIcon: nbtcIcon,
    benchmarksSymbol: "Crypto.BTC/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "DOT_USD",
    title: "DOT / USD",
    description: "Polkadot",
    slug: MarketSlug.get("DOT_USD"),
    base: "DOT",
    quote: "USD",
    type: "collateral_is_base",
    icon: dotIcon,
    collateralIcon: dotIcon,
    benchmarksSymbol: "Crypto.DOT/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ca3eed9b267293f6595901c734c7525ce8ef49adafe8284606ceb307afa2ca5b",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "DOT_USDC",
    title: "DOT / USDC",
    description: "Polkadot",
    slug: MarketSlug.get("DOT_USDC"),
    base: "DOT",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: dotIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.DOT/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ca3eed9b267293f6595901c734c7525ce8ef49adafe8284606ceb307afa2ca5b",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "DYDX_USDC",
    title: "DYDX / USDC",
    description: "dYdX",
    slug: MarketSlug.get("DYDX_USDC"),
    base: "DYDX",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: dydxIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.DYDX/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "6489800bb8974169adfe35937bf6736507097d13c190d760c557108c7e93a81b",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "AXL_USD",
    title: "AXL / USD",
    description: "Axelar",
    slug: MarketSlug.get("AXL_USD"),
    base: "AXL",
    quote: "USD",
    type: "collateral_is_base",
    icon: axlIcon,
    collateralIcon: axlIcon,
    benchmarksSymbol: "Crypto.AXL/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "60144b1d5c9e9851732ad1d9760e3485ef80be39b984f6bf60f82b28a2b7f126",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "ETH_BTC",
    title: "ETH / BTC",
    description: "Ethereum",
    slug: MarketSlug.get("ETH_BTC"),
    base: "ETH",
    quote: "BTC",
    type: "collateral_is_quote",
    icon: ethIcon,
    collateralIcon: btcIcon,
    benchmarksSymbol: "Crypto.ETH/BTC",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
          inverted: false,
        },
        {
          id: "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
          inverted: true,
        },
      ],
    },
  },
  {
    id: "ETH_USD",
    title: "ETH / USD",
    description: "Ethereum",
    slug: MarketSlug.get("ETH_USD"),
    base: "ETH",
    quote: "USD",
    type: "collateral_is_base",
    icon: ethIcon,
    collateralIcon: ethIcon,
    benchmarksSymbol: "Crypto.ETH/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "NTRN_USDC",
    title: "NTRN / USDC",
    description: "Neutron",
    slug: MarketSlug.get("NTRN_USDC"),
    base: "NTRN",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: ntrnIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.NTRN/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "a8e6517966a52cb1df864b2764f3629fde3f21d2b640b5c572fcd654cbccd65e",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "NTRN_USD",
    title: "NTRN / USD",
    description: "Neutron",
    slug: MarketSlug.get("NTRN_USD"),
    base: "NTRN",
    quote: "USD",
    type: "collateral_is_base",
    icon: ntrnIcon,
    collateralIcon: ntrnIcon,
    benchmarksSymbol: "Crypto.NTRN/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "a8e6517966a52cb1df864b2764f3629fde3f21d2b640b5c572fcd654cbccd65e",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "OSMO_USD",
    title: "OSMO / USD",
    description: "Osmosis",
    slug: MarketSlug.get("OSMO_USD"),
    base: "OSMO",
    quote: "USD",
    type: "collateral_is_base",
    icon: osmoIcon,
    collateralIcon: osmoIcon,
    benchmarksSymbol: "Crypto.OSMO/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "5867f5683c757393a0670ef0f701490950fe93fdb006d181c8265a831ac0c5c6",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "OSMO_USDC",
    title: "OSMO / USDC",
    description: "Osmosis",
    slug: MarketSlug.get("OSMO_USDC"),
    base: "OSMO",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: osmoIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.OSMO/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "5867f5683c757393a0670ef0f701490950fe93fdb006d181c8265a831ac0c5c6",
          inverted: false,
        },
        {
          id: "eaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a",
          inverted: true,
        },
      ],
    },
  },
  {
    id: "SEI_USD",
    title: "SEI / USD",
    description: "Sei",
    slug: MarketSlug.get("SEI_USD"),
    base: "SEI",
    quote: "USD",
    type: "collateral_is_base",
    icon: seiIcon,
    collateralIcon: seiIcon,
    benchmarksSymbol: "Crypto.SEI/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "SOL_USD",
    title: "SOL / USD",
    description: "Solana",
    slug: MarketSlug.get("SOL_USD"),
    base: "SOL",
    quote: "USD",
    type: "collateral_is_base",
    icon: solIcon,
    collateralIcon: solIcon,
    benchmarksSymbol: "Crypto.SOL/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "SOL_USDC",
    title: "SOL / USDC",
    description: "Solana",
    slug: MarketSlug.get("SOL_USDC"),
    base: "SOL",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: solIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.SOL/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "SOL_USDT",
    title: "SOL / USDT",
    description: "Solana",
    slug: MarketSlug.get("SOL_USDT"),
    base: "SOL",
    quote: "USDT",
    type: "collateral_is_quote",
    icon: solIcon,
    collateralIcon: usdtIcon,
    benchmarksSymbol: "Crypto.SOL/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "LINK_USDC",
    title: "LINK / USDC",
    description: "LINK",
    slug: MarketSlug.get("LINK_USDC"),
    base: "LINK",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: linkIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.LINK/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "LINK_USDT",
    title: "LINK / USDT",
    description: "LINK",
    slug: MarketSlug.get("LINK_USDT"),
    base: "LINK",
    quote: "USDT",
    type: "collateral_is_quote",
    icon: linkIcon,
    collateralIcon: usdtIcon,
    benchmarksSymbol: "Crypto.LINK/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "DOGE_USDC",
    title: "DOGE / USDC",
    description: "DOGE",
    slug: MarketSlug.get("DOGE_USDC"),
    base: "DOGE",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: dogeIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.DOGE/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "dcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "LUNA_USDC",
    title: "LUNA / USDC",
    description: "LUNA",
    slug: MarketSlug.get("LUNA_USDC"),
    base: "LUNA",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: lunaIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.LUNA/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "e6ccd3f878cf338e6732bf59f60943e8ca2c28402fc4d9c258503b2edbe74a31",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "EVMOS_USD",
    title: "EVMOS / USD",
    description: "Evmos",
    slug: MarketSlug.get("EVMOS_USD"),
    base: "EVMOS",
    quote: "USD",
    type: "collateral_is_base",
    icon: evmosIcon,
    collateralIcon: evmosIcon,
    benchmarksSymbol: "Crypto.EVMOS/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "c19405e4c8bdcbf2a66c37ae05a27d385c8309e9d648ed20dc6ee717e7d30e17",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "AKT_USD",
    title: "AKT / USD",
    description: "Akash",
    slug: MarketSlug.get("AKT_USD"),
    base: "AKT",
    quote: "USD",
    type: "collateral_is_base",
    icon: akashIcon,
    collateralIcon: akashIcon,
    benchmarksSymbol: "Crypto.AKT/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "4ea5bb4d2f5900cc2e97ba534240950740b4d3b89fe712a94a7304fd2fd92702",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "SCRT_USD",
    title: "SCRT / USD",
    description: "Secret",
    slug: MarketSlug.get("SCRT_USD"),
    base: "SCRT",
    quote: "USD",
    type: "collateral_is_base",
    icon: secretIcon,
    collateralIcon: secretIcon,
    benchmarksSymbol: "Crypto.SCRT/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ac5a498aa407c3642257dc8bd8b92efda656e708b22be9b96febcb77878d6bfa",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "INJ_USD",
    title: "INJ / USD",
    description: "Injective",
    slug: MarketSlug.get("INJ_USD"),
    base: "INJ",
    quote: "USD",
    type: "collateral_is_base",
    icon: injIcon,
    collateralIcon: injIcon,
    benchmarksSymbol: "Crypto.INJ/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "INJ_USDC",
    title: "INJ / USDC",
    description: "Injective",
    slug: MarketSlug.get("INJ_USDC"),
    base: "INJ",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: injIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.INJ/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "TIA_USD",
    title: "TIA / USD",
    description: "Celestia",
    slug: MarketSlug.get("TIA_USD"),
    base: "TIA",
    quote: "USD",
    type: "collateral_is_base",
    icon: tiaIcon,
    collateralIcon: tiaIcon,
    benchmarksSymbol: "Crypto.TIA/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "09f7c1d7dfbb7df2b8fe3d3d87ee94a2259d212da4f30c1f0540d066dfa44723",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "milkTIA_USD",
    title: "milkTIA / USD",
    description: "Milky Way Staked Celestia",
    slug: MarketSlug.get("milkTIA_USD"),
    base: "milkTIA",
    quote: "USD",
    type: "collateral_is_base",
    icon: milkTiaIcon,
    collateralIcon: milkTiaIcon,
    // FIXME - this is a hack to get the chart to work
    // it's going to be off by at least the redemption rate
    // however that is accounted for in hardcoded workarounds for now
    benchmarksSymbol: "Crypto.TIA/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "09f7c1d7dfbb7df2b8fe3d3d87ee94a2259d212da4f30c1f0540d066dfa44723",
          inverted: false,
        },
      ],
    },
    priceFeedWarning: "centralized",
  },
  {
    id: "stkATOM_USD",
    title: "stkATOM / USD",
    description: "stkAtom",
    slug: MarketSlug.get("stkATOM_USD"),
    base: "stkATOM",
    quote: "USD",
    type: "collateral_is_base",
    icon: stkatomIcon,
    collateralIcon: stkatomIcon,
    // FIXME - this is a hack to get the chart to work
    // it's going to be off by at least the redemption rate
    // however that is accounted for in hardcoded workarounds for now
    benchmarksSymbol: "Crypto.ATOM/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "b00b60f88b03a6a625a8d1c048c3f66653edf217439983d037e7222c4e612819",
          inverted: false,
        },
      ],
    },
    priceFeedWarning: "centralized",
  },
  {
    id: "BTC_INJ",
    title: "BTC / INJ",
    description: "Bitcoin / Injective",
    slug: MarketSlug.get("BTC_INJ"),
    base: "BTC",
    quote: "INJ",
    type: "collateral_is_quote",
    icon: btcIcon,
    collateralIcon: injIcon,
    benchmarksSymbol: [
      {
        id: "Crypto.BTC/USD",
        inverted: false,
      },
      {
        id: "Crypto.INJ/USD",
        inverted: true,
      },
    ],
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
          inverted: false,
        },
        {
          id: "7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592",
          inverted: true,
        },
      ],
    },
  },
  {
    id: "BTC_SEI",
    title: "BTC / SEI",
    description: "Bitcoin / Sei",
    slug: MarketSlug.get("BTC_SEI"),
    base: "BTC",
    quote: "SEI",
    type: "collateral_is_quote",
    icon: btcIcon,
    collateralIcon: seiIcon,
    benchmarksSymbol: [
      {
        id: "Crypto.BTC/USD",
        inverted: false,
      },
      {
        id: "Crypto.SEI/USD",
        inverted: true,
      },
    ],
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "e62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
          inverted: false,
        },
        {
          id: "53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb",
          inverted: true,
        },
      ],
    },
  },
  {
    id: "ETH_SEI",
    title: "ETH / SEI",
    description: "Ethereum / Sei",
    slug: MarketSlug.get("ETH_SEI"),
    base: "ETH",
    quote: "SEI",
    type: "collateral_is_quote",
    icon: ethIcon,
    collateralIcon: seiIcon,
    benchmarksSymbol: [
      {
        id: "Crypto.ETH/USD",
        inverted: false,
      },
      {
        id: "Crypto.SEI/USD",
        inverted: true,
      },
    ],
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
          inverted: false,
        },
        {
          id: "53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb",
          inverted: true,
        },
      ],
    },
  },
  {
    id: "SOL_SEI",
    title: "SOL / SEI",
    description: "Solana / Sei",
    slug: MarketSlug.get("SOL_SEI"),
    base: "SOL",
    quote: "SEI",
    type: "collateral_is_quote",
    icon: solIcon,
    collateralIcon: seiIcon,
    benchmarksSymbol: [
      {
        id: "Crypto.SOL/USD",
        inverted: false,
      },
      {
        id: "Crypto.SEI/USD",
        inverted: true,
      },
    ],
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
          inverted: false,
        },
        {
          id: "53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb",
          inverted: true,
        },
      ],
    },
  },
  {
    id: "BNB_USD",
    title: "BNB / USD",
    description: "BNB",
    slug: MarketSlug.get("BNB_USD"),
    base: "BNB",
    quote: "USD",
    type: "collateral_is_base",
    icon: bnbIcon,
    collateralIcon: bnbIcon,
    benchmarksSymbol: "Crypto.BNB/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "BNB_USDC",
    title: "BNB / USDC",
    description: "BNB",
    slug: MarketSlug.get("BNB_USDC"),
    base: "BNB",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: bnbIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.BNB/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "BNB_USDT",
    title: "BNB / USDT",
    description: "BNB",
    slug: MarketSlug.get("BNB_USDT"),
    base: "BNB",
    quote: "USDT",
    type: "collateral_is_quote",
    icon: bnbIcon,
    collateralIcon: usdtIcon,
    benchmarksSymbol: "Crypto.BNB/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "EUR_USDC",
    title: "EUR / USDC",
    description: "Euro",
    slug: MarketSlug.get("EUR_USDC"),
    base: "EUR",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: eurIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "FX.EUR/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "a995d00bb36a63cef7fd2c287dc105fc8f3d93779f062f09551b0af3e81ec30b",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "GBP_USDC",
    title: "GBP / USDC",
    description: "British Pound",
    slug: MarketSlug.get("GBP_USDC"),
    base: "GBP",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: gbpIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "FX.GBP/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "84c2dde9633d93d1bcad84e7dc41c9d56578b7ec52fabedc1f335d673df0a7c1",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "CNH_USDC",
    title: "CNH / USDC",
    description: "Yuan",
    slug: MarketSlug.get("CNH_USDC"),
    base: "CNH",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: yuanIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: [
      {
        id: "FX.USD/CNH",
        inverted: true,
      },
    ],
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "eef52e09c878ad41f6a81803e3640fe04dceea727de894edd4ea117e2e332e66",
          inverted: true,
        },
      ],
    },
  },
  {
    id: "GOLD_USDC",
    title: "GOLD / USDC",
    description: "Gold",
    slug: MarketSlug.get("GOLD_USDC"),
    base: "GOLD",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: goldIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Metal.XAU/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "765d2ba906dbc32ca17cc11f5310a89e9ee1f6420508c63861f2f8ba4ee34bb2",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "SILVER_USDC",
    title: "SILVER / USDC",
    description: "Silver",
    slug: MarketSlug.get("SILVER_USDC"),
    base: "SILVER",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: silverIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Metal.XAG/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "f2fb02c32b055c805e7238d628e5e9dadef274376114eb1f012337cabe93871e",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "RUNE_USDC",
    title: "RUNE / USDC",
    description: "THORChain",
    slug: MarketSlug.get("RUNE_USDC"),
    base: "RUNE",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: runeIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.RUNE/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "5fcf71143bb70d41af4fa9aa1287e2efd3c5911cee59f909f915c9f61baacb1e",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "XRP_USDC",
    title: "XRP / USDC",
    description: "Ripple",
    slug: MarketSlug.get("XRP_USDC"),
    base: "XRP",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: xrpIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.XRP/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "ec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "ADA_USDC",
    title: "ADA / USDC",
    description: "Cardano",
    slug: MarketSlug.get("ADA_USDC"),
    base: "ADA",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: adaIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.ADA/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "2a01deaec9e51a579277b34b122399984d0bbf57e2458a7e42fecd2829867a0d",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "TRX_USDC",
    title: "TRX / USDC",
    description: "Tron",
    slug: MarketSlug.get("TRX_USDC"),
    base: "TRX",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: trxIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.TRX/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "67aed5a24fdad045475e7195c98a98aea119c763f272d4523f5bac93a4f33c2b",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "MATIC_USDC",
    title: "MATIC / USDC",
    description: "Polygon",
    slug: MarketSlug.get("MATIC_USDC"),
    base: "MATIC",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: maticIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.MATIC/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "5de33a9112c2b700b8d30b8a3402c103578ccfa2765696471cc672bd5cf6ac52",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "AVAX_USDC",
    title: "AVAX / USDC",
    description: "Avalanche",
    slug: MarketSlug.get("AVAX_USDC"),
    base: "AVAX",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: avaxIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.AVAX/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "93da3352f9f1d105fdfe4971cfa80e9dd777bfc5d0f683ebb6e1294b92137bb7",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "PYTH_USDC",
    title: "PYTH / USDC",
    description: "Pyth",
    slug: MarketSlug.get("PYTH_USDC"),
    base: "PYTH",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: pythIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.PYTH/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "0bbf28e9a841a1cc788f6a361b17ca072d0ea3098a1e5df1c3922d06719579ff",
          inverted: false,
        },
      ],
    },
  },
  // This market should never goto production! It provides frequent off hours
  // which is helpful for debugging.
  {
    id: "COIN_USDC",
    title: "COIN / USDC",
    description: "Coin",
    slug: MarketSlug.get("COIN_USDC"),
    base: "COIN",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: coinIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Equity.US.COIN/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "fee33f2a978bf32dd6b662b65ba8083c6773b494f8401194ec1870c640860245",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "DYM_USD",
    title: "DYM / USD",
    description: "DYM",
    slug: MarketSlug.get("DYM_USD"),
    base: "DYM",
    quote: "USD",
    type: "collateral_is_base",
    icon: dymIcon,
    collateralIcon: dymIcon,
    benchmarksSymbol: "Crypto.DYM/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "a9f3b2a89c6f85a6c20a9518abde39b944e839ca49a0c92307c65974d3f14a57",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "WIF_USDC",
    title: "WIF / USDC",
    description: "Dogwifhat",
    slug: MarketSlug.get("WIF_USDC"),
    base: "WIF",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: wifIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.WIF/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "4ca4beeca86f0d164160323817a4e42b10010a724c2217c6ee41b54cd4cc61fc",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "MEME_USDC",
    title: "MEME / USDC",
    description: "MEME",
    slug: MarketSlug.get("MEME_USDC"),
    base: "MEME",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: memeIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.MEME/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "cd2cee36951a571e035db0dfad138e6ecdb06b517cc3373cd7db5d3609b7927c",
          inverted: false,
        },
      ],
    },
  },
  {
    id: "PEPE_USDC",
    title: "PEPE / USDC",
    description: "PEPE",
    slug: MarketSlug.get("PEPE_USDC"),
    base: "PEPE",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: pepeIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.PEPE/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "d69731a2e74ac1ce884fc3890f7ee324b6deb66147055249568869ed700882e4",
          inverted: false,
        },
      ],
    },
    quoteDecimalPlaces: 10,
  },
  {
    id: "SHIB_USDC",
    title: "SHIB / USDC",
    description: "SHIB",
    slug: MarketSlug.get("SHIB_USDC"),
    base: "SHIB",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: shibIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.SHIB/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "f0d57deca57b3da2fe63a493f4c25925fdfd8edf834b20f93e1f84dbd1504d4a",
          inverted: false,
        },
      ],
    },
    quoteDecimalPlaces: 9,
  },
  {
    id: "FLOKI_USDC",
    title: "FLOKI / USDC",
    description: "FLOKI",
    slug: MarketSlug.get("FLOKI_USDC"),
    base: "FLOKI",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: flokiIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.FLOKI/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "6b1381ce7e874dc5410b197ac8348162c0dd6c0d4c9cd6322672d6c2b1d58293",
          inverted: false,
        },
      ],
    },
    quoteDecimalPlaces: 8,
  },
  {
    id: "BONK_USDC",
    title: "BONK / USDC",
    description: "BONK",
    slug: MarketSlug.get("BONK_USDC"),
    base: "BONK",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: bonkIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.BONK/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "72b021217ca3fe68922a19aaf990109cb9d84e9ad004b4d2025ad6f529314419",
          inverted: false,
        },
      ],
    },
    quoteDecimalPlaces: 9,
  },
  {
    id: "MAGA_USDC",
    title: "MAGA / USDC",
    description: "MAGA",
    slug: MarketSlug.get("MAGA_USDC"),
    base: "MAGA",
    quote: "USDC",
    type: "collateral_is_quote",
    icon: magaIcon,
    collateralIcon: usdcIcon,
    benchmarksSymbol: "Crypto.MAGA/USD",
    pythHistoryFallback: {
      network: "stable",
      ids: [
        {
          id: "a80e97f70f6a4a8a0273822fb86d51b2bdb9a16ce0edb7ea8c8b84cbaecb5ce5",
          inverted: false,
        },
      ],
    },
  },
])

export const requireMarketDisplayConfig = ({
  marketId,
}: {
  marketId: MarketId
}): MarketDisplayConfig => {
  const config = marketDisplayConfigMap.get(marketId)
  if (config === undefined) {
    throw new Error(`Market display config not found for market ${marketId}`)
  }
  return config
}
