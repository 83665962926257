/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * Introduce a bunch of type-safe wrappers to distinguish different kinds of
 * assets at compile time.
 *
 * Each class must implement a branding technique to prevent interchangeable
 * classes through TypeScripts duck typing. The pattern used for these classes
 * is a unique hidden property, which is the classes name.
 */

import {
  Amount,
  type AmountFormatOptions,
  round,
  type ConstructableAmount,
} from "@future/numerics/amount"
import type { ChainConfig } from "@future/libs/chain/chain"
import type { Token } from "@perps/sdk/types"
import { getTokenDecimals } from "@perps/sdk/token"

import type { MarketConfig } from "../market/config/types"

abstract class AssetAmount {
  abstract toAsset(...args: unknown[]): string
}

abstract class PriceAmount {
  abstract toPrice(...args: unknown[]): string
}

export abstract class InputAmount {
  abstract maxInputDecimalPlaces(...args: unknown[]): number
  abstract toInput(...args: unknown[]): string
  abstract suffix(...args: unknown[]): string
}

abstract class ChainAmount {
  abstract toChain(...args: unknown[]): string
}

abstract class ToPercentage<ToClass extends Percentage> {
  abstract toPercentage(): ToClass
}

abstract class FromPercentage<FromClass extends Amount> {
  abstract fromPercentage(): FromClass
}

interface AssetFormatOptions extends AmountFormatOptions {
  /**
   * Add space between the amount and the ID
   */
  idSpacing?: boolean
  /**
   * Represented as an hourly amount
   */
  hourly?: boolean
}

type AssetFormatOverrideOptions = Pick<
  AssetFormatOptions,
  | "comma"
  | "hourly"
  | "maxDecimalPlaces"
  | "minDecimalPlaces"
  | "roundingMode"
  | "zeroTrim"
>

/**
 * The base asset of the market.
 *
 * In a market ID pair, this is always the first asset
 */
export class Base
  extends Amount
  implements AssetAmount, InputAmount, PriceAmount
{
  // @ts-ignore
  #base = true

  toAsset(
    marketConfig: MarketConfig,
    options?: Pick<AssetFormatOverrideOptions, "zeroTrim" | "roundingMode">,
  ): string {
    return toAssetFormat(this, {
      suffix: this.suffix(marketConfig),
      maxDecimalPlaces: maxAssetDecimalPlaces(
        this,
        this.maxInputDecimalPlaces(),
      ),
      zeroTrim: true,
      idSpacing: true,
      roundingMode: round.down,
      ...options,
    })
  }

  toPrice(marketConfig: MarketConfig): string {
    return toAssetFormat(this, {
      suffix: this.suffix(marketConfig),
      maxDecimalPlaces: maxPriceDecimalPlaces(this),
      zeroTrim: false,
      idSpacing: true,
    })
  }

  maxInputDecimalPlaces(): number {
    return 6
  }

  toInput(): string {
    return toInputFormat(this, this.maxInputDecimalPlaces(), {
      roundingMode: round.down,
    })
  }

  suffix(marketConfig: MarketConfig): string {
    return marketConfig.base
  }

  toAbbreviate(marketConfig: MarketConfig): string {
    return `${super.toAbbreviate()} ${this.suffix(marketConfig)}`
  }
}

/**
 * The quote asset of the market.
 *
 * In a market ID pair, this is always the second asset
 */
export class Quote
  extends Amount
  implements AssetAmount, InputAmount, PriceAmount
{
  // @ts-ignore
  #quote = true

  toAsset(
    marketConfig: MarketConfig,
    options?: Pick<AssetFormatOverrideOptions, "zeroTrim" | "roundingMode">,
  ): string {
    return toAssetFormat(this, {
      suffix: this.suffix(marketConfig),
      maxDecimalPlaces: maxAssetDecimalPlaces(
        this,
        this.maxInputDecimalPlaces(marketConfig),
      ),
      zeroTrim: true,
      idSpacing: true,
      roundingMode: round.down,
      ...options,
    })
  }

  toPrice(marketConfig: MarketConfig): string {
    return toAssetFormat(this, {
      suffix: this.suffix(marketConfig),
      maxDecimalPlaces: maxPriceDecimalPlaces(this),
      zeroTrim: false,
      idSpacing: true,
    })
  }

  maxInputDecimalPlaces(marketConfig: MarketConfig): number {
    return marketConfig?.quoteDecimalPlaces ?? 6
  }

  toInput(marketConfig: MarketConfig): string {
    return toInputFormat(this, this.maxInputDecimalPlaces(marketConfig), {
      roundingMode: round.down,
    })
  }

  suffix(marketConfig: MarketConfig): string {
    return marketConfig.quote
  }

  toAbbreviate(marketConfig: MarketConfig): string {
    return `${super.toAbbreviate()} ${this.suffix(marketConfig)}`
  }
}

/**
 * The collateral asset of the market.
 *
 * This depends on whether the market is collateral-is-base or collateral-is-quote.
 *
 * @example
 * ATOM_USD: collateral-is-base, the collateral is ATOM
 * OSMO_USDC: collateral-is-quote, the collateral is USDC
 */
export class Collateral
  extends Amount
  implements AssetAmount, InputAmount, PriceAmount, ChainAmount
{
  // @ts-ignore
  #collateral = true

  toAsset(
    marketConfig: MarketConfig,
    options?: Pick<AssetFormatOverrideOptions, "zeroTrim" | "roundingMode">,
  ): string {
    return toAssetFormat(this, {
      suffix: this.suffix(marketConfig),
      maxDecimalPlaces: maxAssetDecimalPlaces(
        this,
        this.maxInputDecimalPlaces(marketConfig.collateralToken),
      ),
      zeroTrim: true,
      idSpacing: true,
      roundingMode: round.down,
      ...options,
    })
  }

  toPrice(marketConfig: MarketConfig): string {
    return toAssetFormat(this, {
      suffix: this.suffix(marketConfig),
      maxDecimalPlaces: maxPriceDecimalPlaces(this),
      zeroTrim: false,
      idSpacing: true,
    })
  }

  maxInputDecimalPlaces(token: Token): number {
    return getTokenDecimals(token)
  }

  toInput(token: Token): string {
    return toInputFormat(this, this.maxInputDecimalPlaces(token), {
      roundingMode: round.down,
    })
  }

  suffix(marketConfig: MarketConfig): string {
    return marketConfig.collateral
  }

  toChain(token: Token): string {
    return this.toFormat({
      maxDecimalPlaces: this.maxInputDecimalPlaces(token),
      zeroTrim: true,
      comma: false,
      roundingMode: round.down,
    })
  }

  toAbbreviate(marketConfig: MarketConfig): string {
    return `${super.toAbbreviate()} ${this.suffix(marketConfig)}`
  }
}

/**
 * The notional asset of the market.
 *
 * This will be the non-collateral asset in the market ID pair.
 */
export class Notional
  extends Amount
  implements AssetAmount, InputAmount, PriceAmount
{
  // @ts-ignore
  #notional = true

  toAsset(
    marketConfig: MarketConfig,
    options?: Pick<AssetFormatOverrideOptions, "zeroTrim" | "roundingMode">,
  ): string {
    return toAssetFormat(this, {
      suffix: this.suffix(marketConfig),
      maxDecimalPlaces: maxAssetDecimalPlaces(
        this,
        this.maxInputDecimalPlaces(),
      ),
      zeroTrim: true,
      idSpacing: true,
      roundingMode: round.down,
      ...options,
    })
  }

  toPrice(marketConfig: MarketConfig): string {
    return toAssetFormat(this, {
      suffix: this.suffix(marketConfig),
      maxDecimalPlaces: maxPriceDecimalPlaces(this),
      zeroTrim: false,
      idSpacing: true,
    })
  }

  maxInputDecimalPlaces(): number {
    return 6
  }

  toInput(): string {
    return toInputFormat(this, this.maxInputDecimalPlaces(), {
      roundingMode: round.down,
    })
  }

  suffix(marketConfig: MarketConfig): string {
    return marketConfig.notional
  }

  toAbbreviate(marketConfig: MarketConfig): string {
    return `${super.toAbbreviate()} ${this.suffix(marketConfig)}`
  }
}

/**
 * The USD asset, regardless of what type of market we're dealing with.
 *
 * USD is handled specially by the protocol and is used for things like PnL
 * calculation in many places, regardless of the actual assets.
 */
export class Usd extends Amount implements AssetAmount, InputAmount {
  // @ts-ignore
  #usd = true

  toAsset(
    options?: Pick<
      AssetFormatOverrideOptions,
      "zeroTrim" | "hourly" | "roundingMode"
    >,
  ): string {
    return toAssetFormat(this, {
      suffix: this.suffix(),
      maxDecimalPlaces: maxAssetDecimalPlaces(
        this,
        this.maxInputDecimalPlaces(),
      ),
      zeroTrim: true,
      idSpacing: true,
      roundingMode: round.down,
      ...options,
    })
  }

  toGlobalPrice(): string {
    return toAssetFormat(this, {
      suffix: this.suffix(),
      maxDecimalPlaces: 0,
      idSpacing: true,
    })
  }

  toPersonalPrice(): string {
    return toAssetFormat(this, {
      suffix: this.suffix(),
      maxDecimalPlaces: 2,
      idSpacing: true,
      zeroTrim: false,
    })
  }

  maxInputDecimalPlaces(): number {
    return 6
  }

  toInput(): string {
    return toInputFormat(this, this.maxInputDecimalPlaces())
  }

  suffix(): string {
    return "USD"
  }

  toSymbolAsset(
    options?: Pick<AssetFormatOverrideOptions, "maxDecimalPlaces">,
  ): string {
    return toAssetFormat(this, {
      prefix: "$",
      maxDecimalPlaces: options?.maxDecimalPlaces ?? 2,
      idSpacing: false,
    })
  }

  toAbbreviate(): string {
    return `${super.toAbbreviate()} ${this.suffix()}`
  }
}

export type LpKind = "lp" | "xlp"

/**
 * A number of LP or xLP tokens
 */
export class LpToken
  extends Amount
  implements AssetAmount, InputAmount, ChainAmount
{
  // @ts-ignore
  #lpToken = true

  toAsset(
    lpKind: LpKind,
    options?: Pick<AssetFormatOverrideOptions, "zeroTrim" | "roundingMode">,
  ): string {
    return toAssetFormat(this, {
      suffix: this.suffix(lpKind),
      maxDecimalPlaces: maxAssetDecimalPlaces(
        this,
        this.maxInputDecimalPlaces(),
      ),
      zeroTrim: true,
      idSpacing: true,
      roundingMode: round.down,
      ...options,
    })
  }

  maxInputDecimalPlaces(): number {
    return 12
  }

  toInput(): string {
    return toInputFormat(this, this.maxInputDecimalPlaces(), {
      roundingMode: round.down,
    })
  }

  suffix(lpKind: LpKind): string {
    switch (lpKind) {
      case "lp":
        return "LP"
      case "xlp":
        return "xLP"
    }
  }

  toChain(): string {
    return this.toFormat({
      maxDecimalPlaces: this.maxInputDecimalPlaces(),
      zeroTrim: true,
      comma: false,
      roundingMode: round.down,
    })
  }
}

/**
 * A native asset of the chain.
 */
export class NativeToken extends Amount {
  // @ts-ignore
  #nativeToken = true

  toAsset(
    config: Pick<ChainConfig, "asset">,
    options?: Pick<AssetFormatOverrideOptions, "zeroTrim">,
  ): string {
    return toAssetFormat(this, {
      suffix: config.asset.symbol,
      maxDecimalPlaces: 6,
      idSpacing: true,
      ...options,
    })
  }
}

/**
 * A percentage
 */
export class Percentage extends Amount implements AssetAmount, InputAmount {
  // @ts-ignore
  #percentage = true

  toAsset(
    options?: Pick<AssetFormatOverrideOptions, "zeroTrim" | "roundingMode">,
  ): string {
    return toAssetFormat(this, {
      suffix: this.suffix(),
      maxDecimalPlaces: 2,
      zeroTrim: false,
      idSpacing: false,
      ...options,
    })
  }

  maxInputDecimalPlaces(): number {
    return 6
  }

  toInput(): string {
    return toInputFormat(this, this.maxInputDecimalPlaces())
  }

  suffix(): string {
    return "%"
  }
}

/**
 * A leverage
 */
export class Leverage extends Amount implements AssetAmount, InputAmount {
  // @ts-ignore
  #leverage = true

  toAsset(
    options?: Pick<AssetFormatOverrideOptions, "zeroTrim" | "roundingMode">,
  ): string {
    return toAssetFormat(this, {
      suffix: this.suffix(),
      maxDecimalPlaces: 2,
      idSpacing: false,
      zeroTrim: false,
      ...options,
    })
  }

  maxInputDecimalPlaces(): number {
    return 2
  }

  toInput(): string {
    return toInputFormat(this, this.maxInputDecimalPlaces())
  }

  suffix(): string {
    return "x"
  }

  toChain(): string {
    return this.toFormat({
      zeroTrim: true,
      comma: false,
      roundingMode: round.down,
    })
  }
}

/**
 * A max gains type which supports +Inf
 */
export class MaxGains
  extends Amount
  implements ChainAmount, ToPercentage<MaxGainsPercentage>
{
  // @ts-ignore
  #maxGains = true

  constructor(amount: ConstructableAmount) {
    if (amount === "+Inf") {
      super(Number.POSITIVE_INFINITY)
    } else {
      super(amount)
    }
  }

  toChain(): string {
    if (this.isInfinite()) {
      return "+Inf"
    }

    return super.toChain()
  }

  toPercentage(): MaxGainsPercentage {
    return new MaxGainsPercentage(this.times(100))
  }
}

export class MaxGainsPercentage
  extends Percentage
  implements FromPercentage<MaxGains>
{
  // @ts-ignore
  #maxGainsPercentage = true

  fromPercentage(): MaxGains {
    return new MaxGains(this.divide(100))
  }
}

/**
 * A take profit type which supports +Inf
 */
export class TakeProfit
  extends Quote
  implements ChainAmount, ToPercentage<TakeProfitPercentage>
{
  // @ts-ignore
  #takeProfit = true

  constructor(amount: ConstructableAmount) {
    if (amount === "+Inf") {
      super(Number.POSITIVE_INFINITY)
    } else {
      super(amount)
    }
  }

  toAsset(
    marketConfig: MarketConfig,
    options?:
      | Pick<AssetFormatOverrideOptions, "roundingMode" | "zeroTrim">
      | undefined,
  ): string {
    if (this.isInfinite()) {
      return `∞ ${this.suffix(marketConfig)}`
    }

    return super.toAsset(marketConfig, options)
  }

  toPrice(marketConfig: MarketConfig): string {
    if (this.isInfinite()) {
      return `∞ ${this.suffix(marketConfig)}`
    }

    return super.toPrice(marketConfig)
  }

  toChain(): string {
    if (this.isInfinite()) {
      return "+Inf"
    }

    return super.toChain()
  }

  toPercentage(): TakeProfitPercentage {
    return new TakeProfitPercentage(this.times(100))
  }
}

export class TakeProfitPercentage
  extends Percentage
  implements FromPercentage<TakeProfit>
{
  // @ts-ignore
  #takeProfitPercentage = true

  toAsset(
    options?:
      | Pick<AssetFormatOverrideOptions, "roundingMode" | "zeroTrim">
      | undefined,
  ): string {
    if (this.isInfinite()) {
      return `∞${this.suffix()}`
    }

    return super.toAsset(options)
  }

  fromPercentage(): TakeProfit {
    return new TakeProfit(this.divide(100))
  }
}

const toAssetFormat = (amount: Amount, options: AssetFormatOptions) => {
  const value = amount.toFormat({
    ...options,
    ...(options.prefix && {
      prefix: options.idSpacing ? `${options.prefix} ` : options.prefix,
    }),
    ...(options.suffix && {
      suffix: options.idSpacing ? ` ${options.suffix}` : options.suffix,
    }),
    comma: options?.comma ?? true,
  })

  if (options?.hourly) {
    return `~${value} / h`
  }

  return value
}

const toInputFormat = (
  amount: Amount,
  maxDecimalPlaces: number,
  options?: AmountFormatOptions,
) => {
  if (amount.specialFormat?.type === "empty") {
    return ""
  }

  return amount.toFormat({
    minDecimalPlaces:
      amount.specialFormat?.type === "trailingZero"
        ? amount.specialFormat.count
        : undefined,
    maxDecimalPlaces,
    zeroTrim: true,
    comma: false,
    suffix:
      amount.specialFormat?.type === "trailingDecimalPoint" ? "." : undefined,
    ...options,
  })
}

const leadingDecimalZeroes = (amount: Amount) => {
  if (amount.isEqualTo(0)) {
    return 0
  }
  return -Math.floor(Math.log10(amount.abs().toNumber())) - 1
}

const maxAssetDecimalPlaces = (amount: Amount, maxDecimalPlaces: number) => {
  return Math.max(
    0,
    Math.min(maxDecimalPlaces, leadingDecimalZeroes(amount) + 5),
  )
}

const maxPriceDecimalPlaces = (amount: Amount) => {
  return Math.max(2, leadingDecimalZeroes(amount) + 5)
}
