import { queryOptions } from "@tanstack/react-query"

import { MINUTE } from "@common/utils/date"
import { Percentage, Usd } from "@future/numerics"
import { queryQuerier } from "@perps/sdk/client/query"

interface LvnPriceResponse {
  price: string
  percent_change_24h: string
}

export const lvnPriceQueryOptions = () => {
  return queryOptions({
    queryKey: ["lvnPrice"],
    refetchInterval: MINUTE,
    queryFn: async (context) => {
      const response = await queryQuerier<LvnPriceResponse>(
        "/v1/levana/token-price",
        "Failed to fetch LVN token price",
        context.signal,
      )

      return {
        price: new Usd(response.price),
        percent24hChange: new Percentage(response.percent_change_24h),
      }
    },
  })
}
