import Container from "@mui/joy/Container"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"

import LevanaIcon from "@common/icons/LevanaIcon"
import LevanaName from "@common/icons/LevanaName"

export interface LoadingContentProps {
  messages?: string[]
}

const LoadingContent = ({ messages = ["Loading..."] }: LoadingContentProps) => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        pb: 15,
      }}
    >
      <Stack sx={{ alignItems: "center", rowGap: 2 }}>
        <Stack
          sx={{
            width: "70px",
            height: "70px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LevanaIcon
            sx={{
              animation: "grow-shrink 1s ease-in infinite alternate",
              "@keyframes grow-shrink": {
                "0%": {
                  width: "50px",
                },
                "40%": {
                  width: "50px",
                },
                "100%": {
                  width: "43px",
                },
              },
            }}
          />
        </Stack>

        <LevanaName
          width={120}
          sx={{
            animation: "fade-light 1s ease-in infinite alternate",
            "@keyframes fade-light": {
              "0%": {
                opacity: "1",
              },
              "40%": {
                opacity: "1",
              },
              "100%": {
                opacity: "0.5",
              },
            },
          }}
        />
        {messages.map((message, idx) => (
          <Typography
            level="body-sm"
            key={idx}
            sx={{
              fontWeight: 500,
              animation: "fade-dark 1s ease-in infinite alternate",
              "@keyframes fade-dark": {
                "0%": {
                  opacity: "1",
                },
                "40%": {
                  opacity: "1",
                },
                "100%": {
                  opacity: "0.1",
                },
              },
            }}
          >
            {message}
          </Typography>
        ))}
      </Stack>
    </Container>
  )
}

export default LoadingContent
