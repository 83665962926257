import { queryOptions } from "@tanstack/react-query"

import { Report } from "@future/libs/error/report"
import { httpGet } from "@future/utils/http"
import { domainConfig } from "@future/target/domain"

interface GeoblockedResponse {
  allowed: boolean
  countryCode?: string
}

export const geoblockedQueryOptions = () => {
  return queryOptions({
    queryKey: ["geoblocked"],
    retry: true,
    queryFn: async (context) => {
      if (!domainConfig.usesGeoblocking) {
        return { allowed: true }
      }

      const response = await httpGet<GeoblockedResponse>(
        "https://geoblocked.levana.finance",
        { signal: context.signal },
      )

      if ("allowed" in response.data) {
        const { allowed, countryCode } = response.data

        if (!countryCode) {
          Report.message(
            `Country code (${countryCode}) not found for geoblocking`,
          )
        }

        return { allowed, countryCode }
      }

      // This should never happen, means our geoblocking API is down or out of
      // sync with frontend so report it and assume they are geoblocked :/
      Report.message("Geoblocking service is down", "fatal")

      return { allowed: false }
    },
  })
}
