import { create } from "zustand"

import { isDevelopment } from "@common/env/constants"

import type { ConsoleLog } from "./console"

export const browserLogLevels = [
  // Show all logs
  "debug",
  // Show only warnings and errors
  "warning",
  // Show only errors
  "error",
] as const
export type BrowserLogLevel = (typeof browserLogLevels)[number]

export interface UseDebugStoreProps {
  // Console Drawer
  consoleVisible: boolean
  openConsole: () => void
  closeConsole: () => void
  consoleLogs: ConsoleLog[]
  addConsoleLog: (log: ConsoleLog) => void

  // Browser Console
  browserLogLevel: BrowserLogLevel
  setBrowserLogLevel: (level: BrowserLogLevel) => void
}

export const useDebugStore = create<UseDebugStoreProps>((set) => ({
  consoleVisible: false,
  openConsole: () => set({ consoleVisible: true }),
  closeConsole: () => set({ consoleVisible: false }),
  consoleLogs: [],
  addConsoleLog: (log: ConsoleLog) => {
    set((state) => ({ consoleLogs: [...state.consoleLogs, log] }))
  },

  browserLogLevel: isDevelopment ? "debug" : "error",
  setBrowserLogLevel: (level) => set({ browserLogLevel: level }),
}))
