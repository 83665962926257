import { useNavigate as useRouterNavigate } from "react-router-dom"
import { useCallback } from "react"

import { type PerpsRoute, renderRoute } from "./Router"

export const useNavigate = () => {
  const navigate = useRouterNavigate()

  return useCallback(
    (route: PerpsRoute) => {
      navigate(renderRoute(route))
    },
    [navigate],
  )
}
