import Divider from "@mui/joy/Divider"
import Stack from "@mui/joy/Stack"
import { useTheme } from "@mui/joy/styles"
import useMediaQuery from "@mui/system/useMediaQuery"

import type { RouteItem } from "@common/routes/types"
import PopperSheet, { type PopperSheetProps } from "@common/ui/PopperSheet"
import type { ContextStoreProp } from "@future/context/store"

import HeaderMenuList, { type HeaderMenuListItem } from "../HeaderMenuList"
import HeaderNavList from "../HeaderNavList"

export interface HeaderGeneralMenuProps
  extends ContextStoreProp<"initial">,
    PopperSheetProps {
  navigationList?: RouteItem[]
  generalList?: HeaderMenuListItem[]
}

const HeaderGeneralMenu = (props: HeaderGeneralMenuProps) => {
  const { navigationList, generalList, contextStore, ...joyProps } = props

  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("xs", "md"))

  return (
    <PopperSheet {...joyProps}>
      <Stack divider={<Divider />}>
        {isMediumScreen && navigationList && navigationList.length > 0 && (
          <HeaderNavList
            contextStore={contextStore}
            items={navigationList}
            onClose={props.onClose}
          />
        )}

        {generalList && (
          <HeaderMenuList items={generalList} onClose={props.onClose} />
        )}
      </Stack>
    </PopperSheet>
  )
}

export default HeaderGeneralMenu
