import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const TradeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <rect x="4.54688" y="7.5" width="6" height="10.5" rx="2" />
      <rect x="12.7969" y="5.25" width="6" height="13.5" rx="2" />
      <path d="M6.04688 6C6.04688 5.17157 6.71845 4.5 7.54687 4.5V4.5C8.3753 4.5 9.04687 5.17157 9.04687 6V6.75H6.04688V6Z" />
      <path d="M14.2969 3.75C14.2969 2.92157 14.9684 2.25 15.7969 2.25V2.25C16.6253 2.25 17.2969 2.92157 17.2969 3.75V4.5H14.2969V3.75Z" />
      <path d="M6.04688 19.5C6.04688 20.3284 6.71845 21 7.54687 21V21C8.3753 21 9.04687 20.3284 9.04687 19.5V18.75H6.04688V19.5Z" />
      <path d="M14.2969 20.25C14.2969 21.0784 14.9684 21.75 15.7969 21.75V21.75C16.6253 21.75 17.2969 21.0784 17.2969 20.25V19.5H14.2969V20.25Z" />
    </SvgIcon>
  )
}

export default TradeIcon
