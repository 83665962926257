import { queryOptions } from "@tanstack/react-query"

import { SECOND } from "@common/utils/date"
import { queryQuerier } from "@perps/sdk/client/query"

interface EpochResponse {
  next_epoch_starts?: string
}

export const nextEpocQueryOptions = (chainId: string) => {
  return queryOptions({
    queryKey: ["nextEpoc", chainId],
    refetchInterval: SECOND * 30,
    enabled: chainId === "osmosis-1",
    queryFn: async (context) => {
      const resp = await queryQuerier<EpochResponse>(
        "/v1/chain/osmosis-mainnet-epoch",
        "Unable to fetch Osmosis epoch information",
        context.signal,
      )
      if (
        resp.next_epoch_starts !== undefined &&
        typeof resp.next_epoch_starts === "string"
      ) {
        return new Date(resp.next_epoch_starts)
      }
    },
  })
}
