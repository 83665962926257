import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const NotificationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 23V21H10V14C10 12.6167 10.4167 11.3875 11.25 10.3125C12.0833 9.2375 13.1667 8.53333 14.5 8.2V7.5C14.5 7.08333 14.6458 6.72917 14.9375 6.4375C15.2292 6.14583 15.5833 6 16 6C16.4167 6 16.7708 6.14583 17.0625 6.4375C17.3542 6.72917 17.5 7.08333 17.5 7.5V8.2C18.8333 8.53333 19.9167 9.2375 20.75 10.3125C21.5833 11.3875 22 12.6167 22 14V21H24V23H8ZM16 26C15.45 26 14.9792 25.8042 14.5875 25.4125C14.1958 25.0208 14 24.55 14 24H18C18 24.55 17.8042 25.0208 17.4125 25.4125C17.0208 25.8042 16.55 26 16 26Z"
      />
    </SvgIcon>
  )
}

export default NotificationIcon
