var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { GasPrice } from "@cosmjs/stargate";
import { getChainInfo as getGrazChainInfo, getWallet as getGrazWallet, } from "graz";
import { walletTypeToGraz } from "./wallet";
import { getWallet } from "../wallet";
import { queryClient } from "../shared/queryClient";
import { useChainStore, useAccountStore } from "../shared/store";
import { SigningClientType } from "../shared/signing";
export const cosmWasmSigning = (chainId) => __awaiter(void 0, void 0, void 0, function* () {
    return ({
        type: SigningClientType.cosmWasm,
        client: yield cosmWasmSigningClient(chainId),
    });
});
const cosmWasmSigningClient = (chainId) => {
    const chainInfo = getGrazChainInfo({ chainId });
    const { walletType } = useAccountStore.getState();
    const queryKey = [
        "cosmWasmSigningClient",
        walletType,
        chainId,
        chainInfo === null || chainInfo === void 0 ? void 0 : chainInfo.rpc,
    ];
    const cachedData = queryClient.getQueryData(queryKey);
    if (cachedData) {
        return cachedData;
    }
    return queryClient.fetchQuery({
        queryKey,
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (!chainInfo) {
                throw new Error(`Unavailable chain: ${chainId}`);
            }
            if (!getWallet(walletType).provider().installed) {
                throw new Error(`Unavailable wallet: ${walletType}`);
            }
            const chainConfig = (_a = useChainStore.getState().chainsConfig) === null || _a === void 0 ? void 0 : _a[chainInfo.chainId];
            const endpoint = {
                url: chainInfo.rpc,
                headers: Object.assign({}, ((chainConfig === null || chainConfig === void 0 ? void 0 : chainConfig.rpcHeaders) || {})),
            };
            const gasPrice = (chainConfig === null || chainConfig === void 0 ? void 0 : chainConfig.gas)
                ? GasPrice.fromString(`${chainConfig.gas.price}${chainConfig.gas.denom}`)
                : undefined;
            const wallet = getGrazWallet(walletTypeToGraz(walletType));
            /**
             * Offline Signer Mode
             *
             * Some wallets have issues using the direct signer (`SIGN_MODE_DIRECT`)
             * such as Cosmostation and Ledger Nano through Keplr. Use the amino
             * signer, since all current wallets support it.
             */
            const offlineSigner = wallet.getOfflineSignerOnlyAmino(chainInfo.chainId);
            const signingClient = yield SigningCosmWasmClient.connectWithSigner(endpoint, offlineSigner, { gasPrice });
            return signingClient;
        }),
    });
};
