import type { TargetId } from "@common/target/types"
import DymensionIcon from "@common/target/icons/Dymension.svg"
import InjectiveIcon from "@common/target/icons/Injective.svg"
import NeutronIcon from "@common/target/icons/Neutron.svg"
import OsmosisIcon from "@common/target/icons/Osmosis.svg"
import SeiIcon from "@common/target/icons/Sei.svg"

export type TargetSlug = string

export type TargetCategory =
  | "mainnet"
  | "beta"
  | "competition"
  | "local"
  | "other"

export interface TargetInfo {
  /**
   * A unique string representing a specific target
   */
  id: TargetId
  /**
   * A user friendly name
   */
  name: string
  /**
   * A string used for URL's
   */
  slug: TargetSlug
  /**
   * A category representing how this target is used
   */
  category: TargetCategory
  /**
   * If `true`, the contract uses direct price updates instead of the Pyth oracle
   */
  directPriceUpdates: boolean
  /**
   * Should we hide this network from the network dropdown list?
   *
   * When enabled, the network will still appear on the stats page,
   * and will also support direct URLs.
   *
   */
  hideInNetworkList: boolean
  icon: string
}

interface MakeTargetInfoProps {
  id: TargetId
  slug: TargetSlug
  icon: string
  category?: TargetCategory
  name?: string
  directPriceUpdates?: boolean
  hideInNetworkList?: boolean
}

const makeTargetInfo = (props: MakeTargetInfoProps): TargetInfo => {
  const {
    id,
    slug,
    icon,
    category,
    name,
    directPriceUpdates,
    hideInNetworkList,
  } = props

  return {
    id,
    slug,
    icon,
    category: category ?? "other",
    name: name ?? id,
    directPriceUpdates: directPriceUpdates ?? false,
    hideInNetworkList: hideInNetworkList ?? false,
  }
}

/**
 * Organized for convenient display
 */
export const targetInfos: TargetInfo[] = [
  // Mainnet
  makeTargetInfo({
    id: "osmomainnet1",
    slug: "osmosis",
    icon: OsmosisIcon,
    category: "mainnet",
    name: "Osmosis",
  }),
  makeTargetInfo({
    id: "seimainnet1",
    slug: "sei",
    icon: SeiIcon,
    category: "mainnet",
    name: "Sei",
    hideInNetworkList: true,
  }),
  makeTargetInfo({
    id: "injmainnet1",
    slug: "injective",
    icon: InjectiveIcon,
    category: "mainnet",
    name: "Injective",
  }),
  makeTargetInfo({
    id: "ntrnmainnet1",
    slug: "neutron",
    icon: NeutronIcon,
    category: "mainnet",
    name: "Neutron",
  }),

  // Betas
  makeTargetInfo({
    id: "injbeta",
    slug: "injective-testnet",
    icon: InjectiveIcon,
    category: "beta",
    name: "Injective Testnet",
  }),
  makeTargetInfo({
    id: "seibeta",
    slug: "sei-testnet",
    icon: SeiIcon,
    category: "beta",
    name: "Sei Testnet",
  }),
  makeTargetInfo({
    id: "osmobeta",
    slug: "osmosis-testnet",
    icon: OsmosisIcon,
    category: "beta",
    name: "Osmosis Testnet",
  }),
  makeTargetInfo({
    id: "ntrnbeta",
    slug: "neutron-testnet",
    icon: NeutronIcon,
    category: "beta",
    name: "Neutron Testnet",
  }),

  // Internal development
  makeTargetInfo({
    id: "osmodev",
    slug: "osmodev",
    icon: OsmosisIcon,
  }),
  makeTargetInfo({
    id: "seidev",
    slug: "seidev",
    icon: SeiIcon,
  }),
  makeTargetInfo({
    id: "seidebug",
    slug: "seidebug",
    icon: SeiIcon,
  }),
  makeTargetInfo({
    id: "seici",
    slug: "seici",
    icon: SeiIcon,
  }),
  makeTargetInfo({
    id: "osmodebug",
    slug: "osmodebug",
    icon: OsmosisIcon,
  }),
  makeTargetInfo({
    id: "osmoqa",
    slug: "osmoqa",
    icon: OsmosisIcon,
    directPriceUpdates: true,
  }),
  makeTargetInfo({
    id: "osmoci",
    slug: "osmoci",
    icon: OsmosisIcon,
  }),
  makeTargetInfo({
    id: "injdebug",
    slug: "injdebug",
    icon: InjectiveIcon,
  }),
  makeTargetInfo({
    id: "ntrndev",
    slug: "ntrndev",
    icon: NeutronIcon,
  }),
  makeTargetInfo({
    id: "dymbeta",
    slug: "dymbeta",
    icon: DymensionIcon,
  }),
]
