import { useTranslation } from "react-i18next"
import NavigationModal, {
  NavigationModalDynamicItem,
} from "@levana/theme/NavigationModal"

import type { ContextStoreProp } from "@future/context/store"

import ConnectInputContent from "./ConnectInputContent"
import ConnectContent from "./ConnectContent"

const ConnectModal = (props: ContextStoreProp<"standard">) => {
  const { t } = useTranslation("common")

  return (
    <NavigationModal rootId={ConnectContent.name}>
      {(modal) => (
        <>
          <NavigationModalDynamicItem
            id={ConnectContent.name}
            title={t("wallet.dialog.title")}
            canClose
          >
            <ConnectContent modal={modal} />
          </NavigationModalDynamicItem>
          <NavigationModalDynamicItem
            id={ConnectInputContent.name}
            title={t("wallet.connect.input.title")}
            canClose
            canPop
          >
            <ConnectInputContent
              modal={modal}
              contextStore={props.contextStore}
            />
          </NavigationModalDynamicItem>
        </>
      )}
    </NavigationModal>
  )
}

export default ConnectModal
