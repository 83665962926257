import { differenceInSeconds } from "date-fns"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { useStore } from "zustand"

import { useBannerContext } from "@perps/banner/BannerContext"
import { Report } from "@future/libs/error/report"
import { SECOND } from "@common/utils/date"
import { nodeStatusQueryOptions } from "@future/network/nodeStatus"
import type { ContextStoreProp } from "@future/context/store"
import { nextEpocQueryOptions } from "@future/network/nextEpoc"

import { systemHealthFromResp } from "./types"

const ALLOWED_BLOCK_AGE = SECOND * 45

/**
 * How many seconds before the epoch we begin the countdown
 */
const START_EPOCH_COUNTDOWN_SECONDS = 300

/**
 * The status detected from the chain
 */
type NodeBlockStatus =
  | { status: "upToDate"; nextEpoch: Date | undefined }
  | { status: "lagging"; lastSeenBlock: Date }

/**
 * Combination of the NodeBlockStatus and the current timestamp.
 */
type NodeBlockStatusDisplayed =
  | { status: "noBanner" }
  | { status: "lagging"; seconds: number }
  | { status: "inEpoch"; seconds: number }
  | { status: "epochStartingSoon"; seconds: number }

const SystemHealth = (props: ContextStoreProp<"standard">) => {
  const bannerContext = useBannerContext()
  const { show, hide } = bannerContext.system
  const { t } = useTranslation("error")

  const nodeBlockStatus = useNodeBlockStatus(props)

  const getNodeBlockStatusDisplayed =
    useCallback((): NodeBlockStatusDisplayed => {
      switch (nodeBlockStatus.status) {
        case "lagging":
          return {
            status: "lagging",
            seconds: differenceInSeconds(
              new Date(),
              nodeBlockStatus.lastSeenBlock,
            ),
          }
        case "upToDate": {
          if (nodeBlockStatus.nextEpoch !== undefined) {
            const now = new Date()
            if (now >= nodeBlockStatus.nextEpoch) {
              return {
                status: "inEpoch",
                seconds: differenceInSeconds(now, nodeBlockStatus.nextEpoch),
              }
            }

            const untilEpoch = differenceInSeconds(
              nodeBlockStatus.nextEpoch,
              now,
            )
            if (untilEpoch < START_EPOCH_COUNTDOWN_SECONDS) {
              return { status: "epochStartingSoon", seconds: untilEpoch }
            }
          }
          return { status: "noBanner" }
        }
      }
    }, [nodeBlockStatus])

  const [nodeBlockStatusDisplayed, setNodeBlockStatusDisplayed] =
    useState<NodeBlockStatusDisplayed>({ status: "noBanner" })

  useEffect(() => {
    const interval = setInterval(() => {
      setNodeBlockStatusDisplayed((previousStatus) => {
        const newStatus = getNodeBlockStatusDisplayed()
        const isNew = !nodeBlockStatusDisplayedEqual(previousStatus, newStatus)
        return isNew ? newStatus : previousStatus
      })
    }, 250)
    return () => {
      clearInterval(interval)
    }
  }, [getNodeBlockStatusDisplayed])

  useEffect(() => {
    const key = "system.blockTime"
    switch (nodeBlockStatusDisplayed.status) {
      case "noBanner":
        hide(key)
        break
      case "lagging":
        show(
          key,
          t("system.blockTime", {
            age: secondsToDelayString(nodeBlockStatusDisplayed.seconds),
          }),
        )
        Report.message(
          `Block time outdated by ${nodeBlockStatusDisplayed} sec`,
          "info",
        )
        break
      case "epochStartingSoon":
        show(
          key,
          t("system.epochStartingSoon", {
            countdown: secondsToDelayString(nodeBlockStatusDisplayed.seconds),
          }),
        )
        break
      case "inEpoch":
        show(
          key,
          t("system.withinEpoch", {
            age: secondsToDelayString(nodeBlockStatusDisplayed.seconds),
          }),
        )
        break
    }
  }, [nodeBlockStatusDisplayed, show, hide, t])

  return null
}

const useNodeBlockStatus = (props: ContextStoreProp<"standard">) => {
  const rpcUrl = useStore(
    props.contextStore,
    (state) => state.chain.config.rpcUrl,
  )
  const nodeStatusResult = useQuery(nodeStatusQueryOptions(rpcUrl))

  const chainId = useStore(
    props.contextStore,
    (state) => state.chain.config.chainId,
  )
  const nextEpocResult = useQuery(nextEpocQueryOptions(chainId))

  const nodeBlockStatus = useMemo<NodeBlockStatus>(() => {
    const nextEpoch = nextEpocResult.data

    if (nodeStatusResult.data && nextEpoch) {
      const systemHealth = systemHealthFromResp({
        fullNodeStatusResp: nodeStatusResult.data,
      })
      const lastSeenBlock = new Date(systemHealth.syncInfo.latestBlockTime)
      const now = new Date()

      // First check if we're in the middle of an epoch. If so, we can ignore
      // block age.
      if (nextEpoch !== undefined && now >= nextEpoch) {
        return { status: "upToDate", nextEpoch }
      }

      // Now check if we're lagging too many blocks and, if so, warn about it.
      const age = differenceInSeconds(new Date(), lastSeenBlock)
      if (age > ALLOWED_BLOCK_AGE) {
        return { status: "lagging", lastSeenBlock }
      }

      // Not lagging, not in an epoch, return that we're up to date but include
      // the next epoch if available.
      return { status: "upToDate", nextEpoch }
    }

    return { status: "upToDate", nextEpoch: undefined }
  }, [nodeStatusResult.data, nextEpocResult.data])

  return nodeBlockStatus
}

const nodeBlockStatusDisplayedEqual = (
  x: NodeBlockStatusDisplayed,
  y: NodeBlockStatusDisplayed,
): boolean => {
  if (x.status !== y.status) {
    return false
  }

  const xSeconds = x.status === "noBanner" ? undefined : x.seconds
  const ySeconds = y.status === "noBanner" ? undefined : y.seconds

  return xSeconds === ySeconds
}

const secondsToDelayString = (secondsOrig: number): string => {
  let seconds = secondsOrig
  if (seconds < 60) {
    return `${seconds}s`
  }

  let minutes = Math.floor(seconds / 60)
  seconds = seconds % 60
  if (minutes < 60) {
    return `${minutes}m${seconds}s`
  }

  let hours = Math.floor(minutes / 60)
  minutes = minutes % 60
  if (hours < 24) {
    return `${hours}h${minutes}m${seconds}s`
  }

  const days = Math.floor(hours / 24)
  hours = hours % 24
  return `${days}d${hours}h${minutes}m${seconds}s`
}

export default SystemHealth
