export const hexToUint8Array = (hex) => {
    if (hex.length % 2 !== 0) {
        throw new Error("Invalid hex string");
    }
    const byteArray = new Uint8Array(hex.length / 2);
    for (let i = 0; i < hex.length; i += 2) {
        byteArray[i / 2] = Number.parseInt(hex.slice(i, i + 2), 16);
    }
    return byteArray;
};
