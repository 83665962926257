import { Network } from "@injectivelabs/networks";
import { ChainId } from "@injectivelabs/ts-types";
export const isInjectiveChainId = (chainId) => {
    switch (chainId) {
        case ChainId.Mainnet:
        case ChainId.Testnet:
        case ChainId.Devnet:
            return true;
        default:
            return false;
    }
};
export const isInjectiveMainnet = (network) => {
    return network === Network.MainnetSentry;
};
export const chainIdToInjectiveChainId = (chainId) => {
    switch (chainId) {
        case ChainId.Mainnet:
            return ChainId.Mainnet;
        case ChainId.Testnet:
            return ChainId.Testnet;
        case ChainId.Devnet:
            return ChainId.Devnet;
        default:
            return null;
    }
};
export const chainIdToInjectiveNetwork = (chainId) => {
    switch (chainId) {
        case ChainId.Mainnet:
            return Network.MainnetSentry;
        case ChainId.Testnet:
            return Network.Testnet;
        case ChainId.Devnet:
            return Network.Devnet;
        default:
            return null;
    }
};
export const chainIdToInjectiveNetworkWithDefault = (chainId) => {
    var _a;
    return (_a = chainIdToInjectiveNetwork(chainId)) !== null && _a !== void 0 ? _a : Network.Testnet;
};
