import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useOnline } from "@future/utils/useOnline"
import { useBannerContext } from "@perps/banner/BannerContext"

const OnlineWatcher = () => {
  const isOnline = useOnline()
  const bannerContext = useBannerContext()
  const { show, hide } = bannerContext.system
  const { t } = useTranslation("error")

  useEffect(() => {
    const key = "system.offline"

    if (isOnline) {
      hide(key)
    } else {
      show(key, t(key))
    }
  }, [isOnline, show, hide, t])

  return null
}

export default OnlineWatcher
