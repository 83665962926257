var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { subscribeAccounts as grazSubscribeAccounts } from "graz";
import { grazAccount, grazConnect, grazDisconnect } from "../graz/account";
import { WalletType } from "../shared/wallet";
import { useChainStore, useAccountStore, accountStoreDefaultValues, } from "../shared/store";
import { injectiveAccount, injectiveConnect, injectiveDisconnect, } from "../injective/account";
import { useInjectiveSessionStore } from "../injective/store";
import { getChain } from "../shared/chain";
import { createViewingAccount, getConnectResult, } from "../shared/account";
import { decideChainAction, useDecideChainAction } from "./chain";
import { SigningClientType } from "./signing";
export const getAccount = (chainId = useChainStore.getState().chainId) => {
    var _a;
    if (chainId.length === 0) {
        return;
    }
    const accountStore = useAccountStore.getState();
    if (accountStore.walletType === WalletType.viewing) {
        const chainInfo = getChain(chainId);
        const prefix = (_a = chainInfo.bech32Config) === null || _a === void 0 ? void 0 : _a.bech32PrefixAccAddr;
        if (prefix &&
            prefix.length > 0 &&
            accountStore.walletAddress.startsWith(prefix)) {
            return createViewingAccount(accountStore.walletAddress);
        }
        else {
            return;
        }
    }
    return decideChainAction({
        chainId,
        cosmos: grazAccount,
        injective: injectiveAccount,
    })();
};
export const useAccount = () => {
    const chainId = useChainStore((state) => state.chainId);
    const [data, setData] = useState(() => {
        const account = getAccount(chainId);
        if (account) {
            return { account, chainId };
        }
    });
    useEffect(function updateDataFromChainIdChanges() {
        if (chainId.length === 0) {
            return;
        }
        setData((previousData) => {
            const account = getAccount(chainId);
            if (account) {
                if (account.bech32Address !== (previousData === null || previousData === void 0 ? void 0 : previousData.account.bech32Address)) {
                    return { account, chainId };
                }
                return previousData;
            }
        });
    }, [chainId]);
    useEffect(function updateDataFromAccountStoreChanges() {
        if (chainId.length === 0) {
            return;
        }
        return useAccountStore.subscribe((state) => {
            if (state.walletType === WalletType.viewing) {
                return {
                    walletType: state.walletType,
                    walletAddress: state.walletAddress,
                };
            }
            else {
                return { walletType: state.walletType };
            }
        }, (state) => {
            const account = getAccount(chainId);
            if (state.walletType === WalletType.viewing) {
                if (account) {
                    setData({ account, chainId });
                }
            }
            else {
                if (!account) {
                    setData(undefined);
                }
            }
        }, {
            equalityFn: (a, b) => {
                return (a.walletType === b.walletType &&
                    a.walletAddress === b.walletAddress);
            },
        });
    }, [chainId]);
    useEffect(function updateDataFromChainStoreChanges() {
        if (chainId.length === 0) {
            return;
        }
        const handleAccounts = (accounts) => {
            if (accounts && chainId in accounts) {
                setData({ account: accounts[chainId], chainId });
            }
            else {
                setData(undefined);
            }
        };
        return decideChainAction({
            chainId,
            cosmos: () => {
                return grazSubscribeAccounts(handleAccounts);
            },
            injective: () => {
                return useInjectiveSessionStore.subscribe((state) => state.accounts, handleAccounts);
            },
        })();
    }, [chainId]);
    return useMemo(() => {
        if ((data === null || data === void 0 ? void 0 : data.chainId) !== chainId) {
            return {
                data: undefined,
            };
        }
        return {
            data: data === null || data === void 0 ? void 0 : data.account,
        };
    }, [data, chainId]);
};
export const useConnect = (args = {}) => {
    const { onError, onLoading, onSuccess } = args;
    const connect = useDecideChainAction({
        cosmos: grazConnect,
        injective: injectiveConnect,
    });
    const mutation = useMutation({
        mutationKey: ["useConnect", onError, onLoading, onSuccess],
        mutationFn: (account) => __awaiter(void 0, void 0, void 0, function* () {
            useAccountStore.setState(Object.assign(Object.assign({}, account), { status: "connecting" }));
            if (account.walletType === WalletType.viewing) {
                const { chainId } = useChainStore.getState();
                const signing = () => __awaiter(void 0, void 0, void 0, function* () {
                    return ({
                        type: SigningClientType.viewing,
                    });
                });
                return getConnectResult(account, chainId, signing);
            }
            return connect(account);
        }),
        onMutate: onLoading,
        onError: (error, account) => {
            onError === null || onError === void 0 ? void 0 : onError(error, account);
            useAccountStore.setState({ error, status: "disconnected" });
        },
        onSuccess: (connectResult) => {
            useAccountStore.setState({ status: "connected" });
            return onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(connectResult);
        },
    });
    return useMemo(() => ({
        connect: (account) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                return yield mutation.mutateAsync(account);
            }
            catch (_a) {
                return;
            }
        }),
        error: mutation.error,
        isLoading: mutation.isPending,
        isSuccess: mutation.isSuccess,
        status: mutation.status,
    }), [
        mutation.mutateAsync,
        mutation.error,
        mutation.isPending,
        mutation.isSuccess,
        mutation.status,
    ]);
};
export const useDisconnect = (args = {}) => {
    const { onError, onLoading, onSuccess } = args;
    const mutation = useMutation({
        mutationKey: ["useDisconnect", onError, onLoading, onSuccess],
        mutationFn: () => __awaiter(void 0, void 0, void 0, function* () {
            useAccountStore.setState(accountStoreDefaultValues, true);
            yield Promise.all([injectiveDisconnect(), grazDisconnect()]);
            return;
        }),
        onMutate: onLoading,
        onError: (error) => onError === null || onError === void 0 ? void 0 : onError(error, undefined),
        onSuccess: () => onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(undefined),
    });
    return useMemo(() => ({
        disconnect: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                return yield mutation.mutateAsync(undefined);
            }
            catch (_a) {
                return;
            }
        }),
        error: mutation.error,
        isLoading: mutation.isPending,
        isSuccess: mutation.isSuccess,
        status: mutation.status,
    }), [
        mutation.mutateAsync,
        mutation.error,
        mutation.isPending,
        mutation.isSuccess,
        mutation.status,
    ]);
};
