import { Report } from "@future/libs/error/report"

export const initMonkeyPatch = () => {
  const initialFetch = window.fetch.bind(window)
  window.fetch = async (...args) => {
    const response = await (async (): Promise<Response> => {
      try {
        return await initialFetch(...args)
      } catch (error) {
        Report.addBreadcrumb({
          level: "debug",
          type: "http",
          category: "fetch",
          message: "Monkey patch fetch error: no response",
          data: {
            error: error instanceof Error ? error.toString() : error,
            params: args,
          },
        })
        throw error
      }
    })()

    const hasStatusError = response.status < 200 || response.status >= 300

    const bodyError = await (async () => {
      try {
        const responseClone = response.clone()
        await responseClone.blob()
        return undefined
      } catch (error) {
        return error
      }
    })()

    if (hasStatusError || bodyError !== undefined) {
      const headers: Record<string, string> = {}
      for (const [value, key] of response.headers) {
        headers[key] = value
      }
      Report.addBreadcrumb({
        level: "debug",
        type: "http",
        category: "fetch",
        message: `Monkey patch fetch error. Status code: ${response.status}.`,
        data: {
          error: bodyError instanceof Error ? bodyError.toString() : bodyError,
          params: args,
          headers,
        },
      })
    }

    return response
  }
}
