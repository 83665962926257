import type { ContextStoreProp } from "@future/context/store"
import InvalidRootLayout, {
  type InvalidRootContent,
} from "@future/root/InvalidRootContent"

const ErrorPage = (props: ContextStoreProp<"initial">) => {
  const content: InvalidRootContent = { type: "not-found" }

  return (
    <InvalidRootLayout content={content} contextStore={props.contextStore} />
  )
}

export default ErrorPage
