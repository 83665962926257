import { WalletType, getWallet } from "graz";
import { WalletErrorType } from "../../shared/error";
import { base64Image } from "./icon";
const walletInfo = {
    name: "XDEFI",
    icon: base64Image,
    install: {
        chrome: "https://chrome.google.com/webstore/detail/xdefi-wallet/hmeobnfnfcmdkdcmlblgagmfpfboieaf",
    },
    isChainSupported: (chainId) => {
        switch (chainId) {
            case "osmosis-1":
                return true;
            default:
                return false;
        }
    },
    errors: {
        generic: [
            {
                message: "XDEFI: user rejected the message signing",
                type: WalletErrorType.rejected,
            },
            {
                // This is the message XDEFI currently returns
                message: "[object Object]",
                type: WalletErrorType.rejected,
            },
        ],
    },
};
export const xdefiWallet = {
    provider: () => {
        var _a;
        const installed = typeof ((_a = window.xfi) === null || _a === void 0 ? void 0 : _a.keplr) !== "undefined";
        if (!installed) {
            return { installed };
        }
        const subscription = (reconnect) => {
            const listener = () => {
                reconnect();
            };
            window.addEventListener("keplr_keystorechange", listener);
            return () => {
                window.removeEventListener("keplr_keystorechange", listener);
            };
        };
        const provider = getWallet(WalletType.XDEFI);
        return {
            installed,
            getKey: provider.getKey,
            getOfflineSigner: provider.getOfflineSigner,
            subscription,
        };
    },
    info: walletInfo,
};
