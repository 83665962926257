import type { JSONValue } from "@common/json/types"
import { AppError } from "@future/libs/error/AppError"
import { Report } from "@future/libs/error/report"

export const getLocalStorageItem = <Type extends JSONValue>(
  key: string,
): Type | null => {
  if (typeof window === "undefined") {
    return null
  }

  const item = window.localStorage.getItem(key)

  if (!item) {
    return null
  }

  try {
    return JSON.parse(item)
  } catch (error) {
    if (
      typeof item === "string" ||
      typeof item === "number" ||
      typeof item === "boolean"
    ) {
      return item as Type
    }

    Report.error(
      AppError.fromError(error, {
        text: `Failed to parse local storage item with key '${key}'`,
      }),
    )
    return null
  }
}

export const setLocalStorageItem = <Type extends JSONValue>(
  key: string,
  value: Type | null,
) => {
  if (typeof window === "undefined") {
    return
  }

  try {
    const item = JSON.stringify(value)
    window.localStorage.setItem(key, item)
  } catch (error) {
    Report.error(
      AppError.fromError(error, {
        text: `Failed to stringify local storage item with key '${key}'`,
      }),
    )
    return
  }
}

export const removeLocalStorageItem = (key: string) => {
  if (typeof window === "undefined") {
    return
  }

  try {
    window.localStorage.removeItem(key)
  } catch {
    return
  }
}

export const localStorageStateBuilder = <Type extends JSONValue>(
  key: string,
  initial: Type,
) => {
  return {
    key,
    get(): Type {
      return getLocalStorageItem(key) ?? initial
    },
    set(value: Type) {
      setLocalStorageItem(key, value)
    },
  }
}
