var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Modal from "@mui/joy/Modal";
import useSlot from "@mui/joy/utils/useSlot";
import { styled, useThemeProps } from "@mui/joy/styles";
import { useState, useRef, useEffect, createContext, useContext, forwardRef, } from "react";
import { useModal, useModalTopKey } from "@levana/utils/modal";
import { muiMergeSx } from "./utils/mui";
import NavigationModalDialog from "./NavigationModalDialog";
import PreventUnmounting from "./PreventUnmounting";
const name = "LevanaNavigationModal";
const NavigationModalRoot = styled(Modal, {
    name,
    slot: "root",
})({});
const _NavigationModalDialog = styled(NavigationModalDialog, {
    name,
    slot: "dialog",
})({});
/**
 * Modal with a navigation stack
 *
 * There are two types of navigation items which can be pushed onto the stack.
 *
 * 1. A dynamic item is able to update its properties, such as `canClose`, while
 * the view rerenders. This item must exist upfront in the modals children.
 * Pushing these items can be done using their `id`.
 *
 * 2. A static item is unable to update its properties while the view rerenders
 * since it's pushed on the stack with no reference to the original view.
 * Pushing these items must contain all of the static item properties.
 *
 * @example
 * // Dynamic Item
 * <NavigationModal rootId={Settings.name}>
 *   {(modal) => (
 *     <NavigationModalDynamicItem id={Settings.name} title="Settings">
 *       <Settings modal={modal} />
 *     </NavigationModalDynamicItem>
 *   )}
 * </NavigationModal>
 *
 * // Static Item
 * modal.push({
 *   id: SettingsUser.name,
 *   canPop: true,
 *   view: (modal) => (
 *     <SettingsUser modal={modal} />
 *   ),
 * })
 */
const NavigationModal = forwardRef(function NavigationModal(inProps, ref) {
    var _a;
    const props = useThemeProps({ props: inProps, name });
    const { rootId, onDismiss, children } = props, otherProps = __rest(props, ["rootId", "onDismiss", "children"]);
    const ownerState = Object.assign({}, props);
    const externalForwardedProps = Object.assign({}, otherProps);
    const [SlotRoot, rootProps] = useSlot("root", {
        ref,
        className: "root",
        elementType: NavigationModalRoot,
        ownerState,
        externalForwardedProps,
    });
    const [SlotDialog, dialogProps] = useSlot("dialog", {
        ref,
        className: "dialog",
        elementType: _NavigationModalDialog,
        ownerState,
        externalForwardedProps,
    });
    const { dismiss } = useModal();
    const topModalKey = useModalTopKey();
    const initialTopModalKey = useRef(topModalKey);
    const [dynamicItemMap, setDynamicItemMap] = useState({});
    const [stack, setStack] = useState([
        {
            type: "dynamic",
            id: rootId,
        },
    ]);
    const topItem = (() => {
        const topStackItem = stack.at(stack.length - 1);
        if (!topStackItem) {
            return undefined;
        }
        switch (topStackItem.type) {
            case "dynamic":
                return dynamicItemMap[topStackItem.id];
            case "static":
                return topStackItem;
        }
    })();
    const handleDismiss = () => {
        var _a;
        dismiss();
        (_a = props.onDismiss) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const handleDismissRef = useRef(handleDismiss);
    useEffect(() => {
        handleDismissRef.current = handleDismiss;
    }, [handleDismiss]);
    useEffect(() => {
        if (topItem === null || topItem === void 0 ? void 0 : topItem.canClose) {
            const handleKeyDown = (event) => {
                if (event.key === "Escape") {
                    handleDismissRef.current();
                }
            };
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [topItem === null || topItem === void 0 ? void 0 : topItem.canClose]);
    return (_jsx(NavigationModalContext.Provider, { value: {
            id: (_a = topItem === null || topItem === void 0 ? void 0 : topItem.id) !== null && _a !== void 0 ? _a : rootId,
            setDynamicItemMap,
        }, children: _jsx(SlotRoot, Object.assign({}, rootProps, { open: true, onClose: (topItem === null || topItem === void 0 ? void 0 : topItem.canClose) ? () => handleDismiss() : undefined, sx: [
                (theme) => muiMergeSx(theme, props.sx),
                topModalKey !== initialTopModalKey.current && {
                    display: "none",
                },
            ], children: _jsx(SlotDialog, Object.assign({}, dialogProps, { rootId: rootId, stack: stack, setStack: setStack, topItem: topItem, onDismiss: onDismiss, children: children })) })) }));
});
export const NavigationModalDynamicItem = (props) => {
    const navigationModalContext = useContext(NavigationModalContext);
    const { canClose, canPop, subtitle, title } = props;
    const propsRef = useRef(props);
    useEffect(() => {
        propsRef.current = props;
    }, [props]);
    useEffect(() => {
        navigationModalContext === null || navigationModalContext === void 0 ? void 0 : navigationModalContext.setDynamicItemMap((map) => (Object.assign(Object.assign({}, map), { [propsRef.current.id]: {
                id: propsRef.current.id,
                canClose,
                canPop,
                icon: propsRef.current.icon,
                subtitle,
                title,
                helper: propsRef.current.helper,
            } })));
    }, [
        navigationModalContext === null || navigationModalContext === void 0 ? void 0 : navigationModalContext.setDynamicItemMap,
        canClose,
        canPop,
        subtitle,
        title,
    ]);
    return (_jsx(PreventUnmounting, { show: (navigationModalContext === null || navigationModalContext === void 0 ? void 0 : navigationModalContext.id) === props.id, children: props.children }));
};
const NavigationModalContext = createContext(undefined);
export const useNavigationModalTopId = () => {
    var _a, _b;
    return (_b = (_a = useContext(NavigationModalContext)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : "";
};
export default NavigationModal;
