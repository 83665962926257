import { ClickAwayListener } from "@mui/base/ClickAwayListener"
import { Popper, type PopperProps } from "@mui/base/Popper"
import Sheet, { type SheetProps } from "@mui/joy/Sheet"
import { useTheme } from "@mui/joy/styles"
import type { PropsWithChildren } from "react"

// import BreakpointPopperModal from "@common/ui/BreakpointPopperModal"

export interface PopperSheetProps extends PropsWithChildren<PopperProps> {
  onClose: () => void
  minWidth?: string
  zIndex?: number
  sheetProps?: SheetProps
}

const PopperSheet = (props: PopperSheetProps) => {
  const { onClose, minWidth, zIndex, children, sheetProps, ...joyProps } = props
  const theme = useTheme()

  if (!props.open) {
    return null
  }

  return (
    // Ignore touch events, in the case of tapping directly on the menu open
    // button, the touch event first triggers the clickaway and then a mouse
    // event triggers the menu to reopen. Instead, by ignoring touch events
    // here, the mouse event is used to trigger the clickaway and is then
    // prevented from bubbling up.
    <ClickAwayListener onClickAway={onClose} touchEvent={false}>
      <Popper
        {...joyProps}
        // slots={{ root: BreakpointPopperModal }}
        slotProps={{
          root: {
            style: {
              zIndex: zIndex ?? theme.vars.zIndex.popup,
            },
          },
        }}
      >
        <Sheet
          variant="outlined"
          {...sheetProps}
          sx={{
            "--paddingX": "1rem",
            minWidth: minWidth ?? "240px",
            mt: 1.5,
            backgroundColor: "var(--palette-background-level2)",
            ...sheetProps?.sx,
          }}
        >
          {children}
        </Sheet>
      </Popper>
    </ClickAwayListener>
  )
}

export default PopperSheet
