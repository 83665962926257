import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const ChevronDownRoundedIcon = (props: SvgIconProps) => {
  const Svg = () => (
    <path d="M17.5795 8.99929C17.1895 8.60929 16.5595 8.60929 16.1695 8.99929L12.2895 12.8793L8.40953 8.99929C8.01953 8.60929 7.38953 8.60929 6.99953 8.99929C6.60953 9.38929 6.60953 10.0193 6.99953 10.4093L11.5895 14.9993C11.9795 15.3893 12.6095 15.3893 12.9995 14.9993L17.5895 10.4093C17.9695 10.0293 17.9695 9.38929 17.5795 8.99929Z" />
  )

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <Svg />
    </SvgIcon>
  )
}

export default ChevronDownRoundedIcon
