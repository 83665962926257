import Box from "@mui/joy/Box"
import Divider from "@mui/joy/Divider"
import type { ReactNode } from "react"

import ExclamationMarkShortIcon from "@common/icons/ExclamationMarkShortIcon"

import BannerContent from "../BannerContent"

export interface SystemBannerLayoutProps {
  message: ReactNode
  endDecorator?: JSX.Element
}

const SystemBannerLayout = (props: SystemBannerLayoutProps) => {
  return (
    <>
      <Box>
        <BannerContent
          title={props.message}
          startDecorator={
            <Box
              sx={(theme) => ({
                "--size": theme.spacing(3),
                "--Icon-color": theme.palette.background.body,
                width: "var(--size)",
                minWidth: "var(--size)",
                minHeight: "var(--size)",
                height: "var(--size)",
                bgcolor: theme.palette.warning.plainColor,
                lineHeight: 0,
                borderRadius: theme.radius.md,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <ExclamationMarkShortIcon fontSize="sm" />
            </Box>
          }
          endDecorator={props.endDecorator}
        />
      </Box>
      <Divider />
    </>
  )
}

export default SystemBannerLayout
