import { ChainId as InjectiveChainId } from "@injectivelabs/ts-types";
import { WalletType, getWallet } from "graz";
import { WalletErrorType } from "../../shared/error";
import { base64Image } from "./icon";
const injectiveErrors = [
    {
        message: /The .* is not supported on Leap./,
        type: WalletErrorType.rejected,
    },
];
const walletInfo = {
    name: "Leap",
    icon: base64Image,
    install: {
        chrome: "https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg",
        ios: "https://apps.apple.com/us/app/leap-cosmos/id1642465549",
        android: "https://play.google.com/store/apps/details?id=io.leapwallet.cosmos",
    },
    isChainSupported: () => {
        return true;
    },
    errors: {
        generic: [
            // Extension
            {
                message: "No wallet exists",
                type: WalletErrorType.noAccount,
            },
            {
                message: "Invalid fee currencies",
                type: WalletErrorType.invalidCurrencies,
            },
            {
                message: "Failed to retrieve account from signer",
                type: WalletErrorType.outOfSyncAccount,
            },
            {
                message: "Transaction declined",
                type: WalletErrorType.rejected,
            },
            {
                message: "Invalid chain id",
                type: WalletErrorType.rejected,
            },
            {
                message: "Request rejected",
                type: WalletErrorType.rejected,
            },
            // Mobile
            {
                message: "Request rejected by user",
                type: WalletErrorType.rejected,
            },
            {
                message: "Request has been rejected",
                type: WalletErrorType.rejected,
            },
        ],
        chain: {
            [InjectiveChainId.Mainnet]: injectiveErrors,
            [InjectiveChainId.Testnet]: injectiveErrors,
            [InjectiveChainId.Devnet]: injectiveErrors,
        },
    },
};
export const leapWallet = {
    provider: () => {
        const installed = typeof window.leap !== "undefined";
        if (!installed) {
            return { installed };
        }
        const subscription = (reconnect) => {
            const listener = () => {
                reconnect();
            };
            window.addEventListener("leap_keystorechange", listener);
            return () => {
                window.removeEventListener("leap_keystorechange", listener);
            };
        };
        const provider = getWallet(WalletType.LEAP);
        return {
            installed,
            getKey: provider.getKey,
            getOfflineSigner: provider.getOfflineSigner,
            subscription,
        };
    },
    info: walletInfo,
};
