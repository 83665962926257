import { ChainId as InjectiveChainId } from "@injectivelabs/ts-types";
import { WalletType, getWallet } from "graz";
import { WalletErrorType } from "../../shared/error";
import { base64Image } from "./icon";
const injectiveErrors = [
    {
        message: "Message Signature: User denied message signature.",
        type: WalletErrorType.rejected,
    },
    {
        message: "User rejected the request.",
        type: WalletErrorType.rejected,
    },
];
const walletInfo = {
    name: "OKX",
    icon: base64Image,
    install: {
        chrome: "https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge",
        ios: "https://apps.apple.com/us/app/okx-buy-bitcoin-btc-crypto/id1327268470",
        android: "https://play.google.com/store/apps/details?id=com.okinc.okex.gp",
    },
    isChainSupported: (chainId) => {
        switch (chainId) {
            case "osmo-test-5":
            case "atlantic-2":
                return false;
            default:
                return true;
        }
    },
    errors: {
        generic: [
            {
                message: "Request Signature: User denied request signature.",
                type: WalletErrorType.rejected,
            },
            {
                message: "The user rejected the request",
                type: WalletErrorType.rejected,
            },
            {
                message: "User rejected the request.",
                type: WalletErrorType.rejected,
            },
            // {
            //   ???: what does this mean
            //   message: "Already processing wallet_requestIdentities. Please wait."
            // }
        ],
        chain: {
            [InjectiveChainId.Mainnet]: injectiveErrors,
            [InjectiveChainId.Testnet]: injectiveErrors,
            [InjectiveChainId.Devnet]: injectiveErrors,
        },
    },
};
export const okxWallet = {
    provider: () => {
        var _a;
        const installed = typeof ((_a = window.okxwallet) === null || _a === void 0 ? void 0 : _a.keplr) !== "undefined";
        if (!installed) {
            return { installed };
        }
        const subscription = (reconnect) => {
            const listener = () => {
                reconnect();
            };
            window.addEventListener("accountsChanged", listener);
            return () => {
                window.removeEventListener("accountsChanged", listener);
            };
        };
        const provider = getWallet(WalletType.OKX);
        return {
            installed,
            getKey: provider.getKey,
            getOfflineSigner: provider.getOfflineSigner,
            subscription,
        };
    },
    info: walletInfo,
};
