import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const MarketsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00346 10.3253L7.15363 14.8178C7.01477 15.155 6.68612 15.3751 6.32142 15.3751H2.29688C1.88266 15.3751 1.54688 15.0393 1.54688 14.6251C1.54688 14.2109 1.88266 13.8751 2.29688 13.8751H5.91961L8.20598 8.32247C8.51893 7.56244 9.60311 7.58512 9.88401 8.35758L12.1768 14.6627L14.2604 10.7559C14.5896 10.1387 15.4664 10.1165 15.8263 10.7164L17.7215 13.8751H21.0469C21.4611 13.8751 21.7969 14.2109 21.7969 14.6251C21.7969 15.0393 21.4611 15.3751 21.0469 15.3751H17.3818C17.0657 15.3751 16.7727 15.2092 16.6101 14.9381L15.0853 12.3968L12.867 16.5562C12.5004 17.2434 11.4932 17.1722 11.227 16.4402L9.00346 10.3253Z"
      />
    </SvgIcon>
  )
}

export default MarketsIcon
