var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { suggestChainAndConnect, disconnect, getAccounts } from "graz";
import { walletTypeToGraz, walletTypeFromGraz } from "./wallet";
import { useChainStore } from "../shared/store";
import { getConnectResult } from "../shared/account";
import { getChain } from "../shared/chain";
import { cosmWasmSigning } from "./signing";
export const grazAccount = () => {
    var _a;
    return (_a = getAccounts()) === null || _a === void 0 ? void 0 : _a[useChainStore.getState().chainId];
};
export const grazConnect = (account) => __awaiter(void 0, void 0, void 0, function* () {
    const { chainId } = useChainStore.getState();
    const result = yield suggestChainAndConnect({
        chainInfo: getChain(chainId),
        walletType: walletTypeToGraz(account.walletType),
    });
    const signing = () => __awaiter(void 0, void 0, void 0, function* () { return cosmWasmSigning(chainId); });
    return getConnectResult({
        account: result.accounts[chainId],
        walletType: walletTypeFromGraz(result.walletType),
    }, chainId, signing);
});
export const grazDisconnect = () => {
    return disconnect();
};
