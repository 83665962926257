import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, createElement } from "react";
import { create } from "zustand";
const useQueueStore = create((set, get) => {
    const priorityQueue = (priority) => {
        switch (priority) {
            case "important":
                return get().importantQueue;
            case "standard":
                return get().standardQueue;
            case "onboard":
                return get().onboardQueue;
        }
    };
    const setQueue = (newQueue, priority) => {
        switch (priority) {
            case "important":
                set({ importantQueue: newQueue });
                return null;
            case "standard":
                set({ standardQueue: newQueue });
                return null;
            case "onboard":
                set({ onboardQueue: newQueue });
                return null;
        }
    };
    return {
        importantQueue: new Map(),
        standardQueue: new Map(),
        onboardQueue: new Map(),
        combinedQueue: () => [
            ...get().importantQueue.entries(),
            ...get().standardQueue.entries(),
            ...get().onboardQueue.entries(),
        ],
        isModalVisible: () => get().combinedQueue().length > 0,
        add: (presenting, options) => {
            var _a;
            const [queueKey, modal] = (() => {
                var _a;
                if (typeof presenting === "string") {
                    return [presenting, null];
                }
                else {
                    const queueKey = (_a = options === null || options === void 0 ? void 0 : options.key) !== null && _a !== void 0 ? _a : Math.floor((1 + Math.random()) * 0x10000)
                        .toString(16)
                        .substring(1);
                    return [queueKey, presenting];
                }
            })();
            const priority = (_a = options === null || options === void 0 ? void 0 : options.priority) !== null && _a !== void 0 ? _a : "standard";
            const queue = priorityQueue(priority);
            if (options === null || options === void 0 ? void 0 : options.key) {
                for (const key of queue.keys()) {
                    if (key === queueKey) {
                        return;
                    }
                }
            }
            setQueue(new Map([[queueKey, modal], ...queue]), priority);
        },
        remove: (key) => {
            const removeFromQueue = (priority) => {
                const queue = priorityQueue(priority);
                let queueKey = typeof key === "string" ? key : undefined;
                if (!queueKey && queue.size > 0) {
                    queueKey = queue.keys().next().value;
                }
                if (queueKey && queue.has(queueKey)) {
                    queue.delete(queueKey);
                    setQueue(new Map(queue), priority);
                    return true;
                }
                else {
                    return false;
                }
            };
            const priorities = ["important", "standard", "onboard"];
            for (const priority of priorities) {
                if (removeFromQueue(priority)) {
                    break;
                }
            }
        },
    };
});
export const useModal = () => {
    return {
        present: useQueueStore((state) => state.add),
        dismiss: useQueueStore((state) => state.remove),
    };
};
export const useModalTopKey = () => {
    const entry = useQueueStore((state) => state.combinedQueue()).at(0);
    if (entry) {
        const [key] = entry;
        return key;
    }
};
export const useModalVisible = () => {
    return useQueueStore((state) => state.isModalVisible());
};
export const ModalProvider = () => {
    const entries = useQueueStore((state) => state.combinedQueue());
    const reverseEntries = [...entries].reverse();
    return (_jsx(_Fragment, { children: reverseEntries.map(([key, modal]) => (_jsx(Fragment, { children: typeof modal === "function" ? createElement(modal) : modal }, key))) }));
};
export const ModalPresenter = (props) => {
    const topModalKey = useModalTopKey();
    if (topModalKey === props.queueKey) {
        return props.children;
    }
};
