import {
  NotifiContext,
  type OsmosisParams,
  type InjectiveParams,
} from "@notifi-network/notifi-react-card"
import type { OfflineAminoSigner } from "@cosmjs/amino"
import { useState, useEffect } from "react"
import type { ConnectResult } from "@levana/chain/account"
import { SigningClientType } from "@levana/chain/signing"
import { WalletType } from "@levana/chain/wallet"

import { Report } from "@future/libs/error/report"
import { AppError } from "@future/libs/error/AppError"

import NotifiButton from "./NotifiButton"

export interface NotifiContextWrapperProps {
  connectedWallet: ConnectResult
}

const NotifiContextWrapper = (props: NotifiContextWrapperProps) => {
  const { connectedWallet } = props
  const [walletPublicKey, setWalletPublicKey] = useState("")

  const walletBlockchain = (():
    | (OsmosisParams | InjectiveParams)["walletBlockchain"]
    | null => {
    if (connectedWallet.walletType === WalletType.metamask) {
      return null
    }

    switch (connectedWallet.signing.type) {
      case SigningClientType.cosmWasm:
        return "OSMOSIS"
      case SigningClientType.injective:
        return "INJECTIVE"
      case SigningClientType.viewing:
        return null
    }
  })()

  useEffect(() => {
    if (walletBlockchain) {
      try {
        setWalletPublicKey(
          Buffer.from(connectedWallet.account.pubKey).toString("base64"),
        )
      } catch (error) {
        Report.error(
          AppError.fromError(error, {
            text: "Unable to decode pubkey",
            extra: {
              walletBlockchain,
            },
          }),
        )
      }
    }
  }, [connectedWallet.account.pubKey, walletBlockchain])

  if (!walletBlockchain) {
    // account and signer is required
    return null
  }

  const signMessage = async (message: Uint8Array): Promise<Uint8Array> => {
    switch (connectedWallet.signing.type) {
      case SigningClientType.cosmWasm: {
        const signingClient = connectedWallet.signing.client

        if ("signer" in signingClient) {
          const hackSigner = signingClient[`${"signer"}`] as OfflineAminoSigner
          const signer = connectedWallet.account.bech32Address

          if ("leap" in hackSigner) {
            const leap = hackSigner[`${"leap"}`] as {
              signArbitrary: (
                chainId: string,
                signer: string,
                data: unknown,
              ) => Promise<{
                signature: string
              }>
            }

            if ("signArbitrary" in leap) {
              const chainId = await signingClient.getChainId()
              const result = await leap.signArbitrary(chainId, signer, message)
              return Buffer.from(result.signature, "base64")
            } else {
              Report.message("Unable to connect Leap to notifi", "error")
            }
          } else if ("signAmino" in hackSigner) {
            const signdoc = {
              chain_id: "",
              account_number: "0",
              sequence: "0",
              fee: {
                gas: "0",
                amount: [],
              },
              msgs: [
                {
                  type: "sign/MsgSignData",
                  value: {
                    signer,
                    data: Buffer.from(message).toString("base64"),
                  },
                },
              ],
              memo: "",
            }
            const result = await hackSigner.signAmino(signer, signdoc)
            return Buffer.from(result.signature.signature, "base64")
          }
        }

        throw new Error("Failed to sign")
      }
      case SigningClientType.injective: {
        const signingClient = connectedWallet.signing.client
        const result = await signingClient.options.walletStrategy.signArbitrary(
          connectedWallet.account.bech32Address,
          new TextDecoder().decode(message),
        )

        if (typeof result === "string") {
          return Buffer.from(result, "base64")
        }

        throw new Error("Failed to sign")
      }
      case SigningClientType.viewing:
        throw new Error("Cannot sign with this wallet")
    }
  }

  return (
    <NotifiContext
      dappAddress="levanafinance"
      env={"Production"}
      signMessage={signMessage}
      walletPublicKey={walletPublicKey}
      walletBlockchain={walletBlockchain}
      accountAddress={connectedWallet.account.bech32Address}
    >
      <NotifiButton connectedWallet={connectedWallet} />
    </NotifiContext>
  )
}

export default NotifiContextWrapper
