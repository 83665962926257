import { AppError } from "@future/libs/error/AppError"

import type { Token } from "./types"

export interface TokenNative {
  native: {
    decimal_places: number
    denom: string
    [k: string]: unknown
  }
}

export const getTokenDecimals = (token: Token): number => {
  return "native" in token
    ? token.native.decimal_places
    : token.cw20.decimal_places
}

export const tokenId = (token: Token): string => {
  if ("native" in token) {
    return token.native.denom
  } else if ("cw20" in token) {
    return token.cw20.addr
  } else {
    throw AppError.fromText("Token id could not be determined", {
      level: "fatal",
      cause: { token },
    })
  }
}
