import { fromBech32 } from "@cosmjs/encoding";
export var WalletType;
(function (WalletType) {
    WalletType["compass"] = "compass";
    WalletType["cosmostation"] = "cosmostation";
    WalletType["cosmostationMobile"] = "cosmostationMobile";
    WalletType["keplr"] = "keplr";
    WalletType["keplrMobile"] = "keplrMobile";
    WalletType["leap"] = "leap";
    WalletType["leapMobile"] = "leapMobile";
    WalletType["metamask"] = "metamask";
    WalletType["okx"] = "okx";
    WalletType["walletConnect"] = "walletConnect";
    WalletType["viewing"] = "viewing";
    WalletType["xdefi"] = "xdefi";
})(WalletType || (WalletType = {}));
export const getAddressPrefix = (address) => {
    try {
        const decoded = fromBech32(address);
        return { type: "cosmos", prefix: decoded.prefix };
    }
    catch (_a) {
        if (address.startsWith("0x")) {
            return { type: "ethereum", prefix: "0x" };
        }
    }
};
export const truncateAddress = (address, startIndex, endIndex) => {
    var _a, _b;
    const addressPrefix = (_b = (_a = getAddressPrefix(address)) === null || _a === void 0 ? void 0 : _a.prefix) !== null && _b !== void 0 ? _b : "";
    const defaultIndexes = (() => {
        var _a;
        switch ((_a = getAddressPrefix(address)) === null || _a === void 0 ? void 0 : _a.type) {
            case "cosmos":
                return { start: 2, end: 4 };
            case "ethereum":
                return { start: 4, end: 5 };
            case undefined:
                return { start: 4, end: 4 };
        }
    })();
    const indexes = {
        start: startIndex !== null && startIndex !== void 0 ? startIndex : defaultIndexes.start,
        end: endIndex !== null && endIndex !== void 0 ? endIndex : defaultIndexes.end,
    };
    if (addressPrefix.length + indexes.start + indexes.end >= address.length) {
        return address;
    }
    const start = address.slice(0, addressPrefix.length + indexes.start);
    const end = address.slice(-indexes.end, address.length);
    return `${start}...${end}`;
};
