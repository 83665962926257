import Stack from "@mui/joy/Stack"

import LevanaIcon from "@common/icons/LevanaIcon"
import LevanaName from "@common/icons/LevanaName"

const LevanaLogo = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <LevanaIcon />
      <LevanaName sx={{ color: "text.primary" }} />
    </Stack>
  )
}

export default LevanaLogo
