import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const CheckIcon = (props: SvgIconProps) => {
  const Svg = () => (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7831 1.36241C15.5243 2.05828 15.5611 3.22329 14.8652 3.96455L8.58985 10.6492C6.89318 12.4565 4.02312 12.4565 2.32645 10.6492L1.13548 9.38055C0.439606 8.6393 0.476396 7.47428 1.21765 6.77841C1.9589 6.08254 3.12392 6.11933 3.81979 6.86059L5.01077 8.12923C5.25315 8.38742 5.66316 8.38742 5.90554 8.12923L12.1809 1.44458C12.8768 0.703325 14.0418 0.666535 14.7831 1.36241Z"
    />
  )

  return (
    <SvgIcon {...props} viewBox="0 0 16 13">
      <Svg />
    </SvgIcon>
  )
}

export default CheckIcon
