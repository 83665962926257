import Button, { type ButtonProps } from "@mui/joy/Button"

const SpotlightDialogButton = (props: ButtonProps) => {
  const { sx, ...joyProps } = props

  return (
    <Button
      variant="plain"
      color="success"
      size="sm"
      {...joyProps}
      sx={{ ml: -1, mb: -1, ...sx }}
    />
  )
}

export default SpotlightDialogButton
