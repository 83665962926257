import { notification } from "@future/notification"

export const useCopyId = () => {
  const handleCopy = (id: string, message: string) => {
    navigator.clipboard.writeText(id)
    notification.success(message)
  }

  return {
    handleCopy,
  }
}
