import { ChainId as InjectiveChainId } from "@injectivelabs/ts-types";
import { WalletType, getWallet } from "graz";
import { WalletErrorType } from "../../shared/error";
import { base64Image } from "./icon";
const injectiveErrors = [
    {
        message: "The user rejected the request",
        type: WalletErrorType.rejected,
    },
];
const walletInfo = {
    name: "Cosmostation",
    icon: base64Image,
    install: {
        chrome: "https://chrome.google.com/webstore/detail/cosmostation/fpkhgmpbidmiogeglndfbkegfdlnajnf",
        ios: "https://apps.apple.com/us/app/cosmostation/id1459830339",
        android: "https://play.google.com/store/apps/details?id=wannabit.io.cosmostaion",
    },
    isChainSupported: () => {
        return true;
    },
    errors: {
        generic: [
            {
                message: "The JSON sent is not a valid Request object.",
                type: WalletErrorType.noAccount,
            },
            {
                message: "User rejected the request.",
                type: WalletErrorType.rejected,
            },
        ],
        chain: {
            [InjectiveChainId.Mainnet]: injectiveErrors,
            [InjectiveChainId.Testnet]: injectiveErrors,
            [InjectiveChainId.Devnet]: injectiveErrors,
        },
    },
};
export const cosmostationWallet = {
    provider: () => {
        var _a;
        const installed = typeof ((_a = window.cosmostation) === null || _a === void 0 ? void 0 : _a.providers.keplr) !== "undefined";
        if (!installed) {
            return { installed };
        }
        const subscription = (reconnect) => {
            const listener = () => {
                reconnect();
            };
            window.addEventListener("cosmostation_keystorechange", listener);
            return () => {
                window.removeEventListener("cosmostation_keystorechange", listener);
            };
        };
        const provider = getWallet(WalletType.COSMOSTATION);
        return {
            installed,
            getKey: provider.getKey,
            getOfflineSigner: provider.getOfflineSigner,
            subscription,
        };
    },
    info: walletInfo,
};
