import { create } from "zustand"
import type { PositionId } from "@perps/sdk/types/.generated/market_response_to_status"

import type { MarketId } from "@perps/sdk/types"
import type { OpenPosition } from "@future/network/marketsOpenPositions/types"

interface UseClosedPositionsStoreProps {
  data: Record<MarketId, Record<PositionId, OpenPosition>>
  add: (marketId: MarketId, position: OpenPosition) => void
  remove: (marketId: MarketId, positionId: PositionId) => void
  has: (marketId: MarketId, positionId: PositionId) => boolean
  clear: () => void
}

export const useClosedPositionsStore = create<UseClosedPositionsStoreProps>(
  (set, get) => {
    const getPositions = (marketId: MarketId) => {
      const { data } = get()
      return marketId in data ? data[marketId] : {}
    }

    return {
      data: {},
      add: (marketId, position) => {
        const positions = getPositions(marketId)

        if (!positions[position.id]) {
          set({
            data: {
              ...get().data,
              [marketId]: { ...positions, [position.id]: position },
            },
          })
        }
      },
      remove: (marketId, positionId) => {
        const positions = getPositions(marketId)

        if (positions[positionId]) {
          const { [positionId]: _, ...remainingPositions } = positions

          set({
            data: {
              ...get().data,
              [marketId]: remainingPositions,
            },
          })
        }
      },
      has: (marketId, positionId) => {
        return positionId in getPositions(marketId)
      },
      clear: () => {
        set({ data: {} })
      },
    }
  },
)
