import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const CrankIcon = (props: SvgIconProps) => {
  const Svg = () => (
    <g>
      <path
        d="M19.1,3c-0.5,0-0.9,0.2-1.3,0.5l-8.3,8c-0.8,0.8-0.8,2.1-0.1,2.9c0.8,0.8,2.1,0.8,2.9,0.1c0,0,0,0,0.1-0.1
		l8-8.3c0.7-0.7,0.7-1.9-0.1-2.6C20.1,3.2,19.6,3,19.1,3z M19.1,3.6c0.7,0,1.2,0.6,1.2,1.2s-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2
		S18.5,3.6,19.1,3.6z"
      />
      <path
        d="M14.1,6.6c-0.1-0.3,0-0.6,0.1-0.8c-0.4-0.2-0.8-0.3-1.2-0.4c-0.1,0.6-0.6,1-1.2,0.9c-0.6,0-1.1-0.6-1.1-1.2
		c-0.4,0-0.9,0.1-1.3,0.1c0.1,0.6-0.1,1.1-0.7,1.4C8.2,6.8,7.6,6.5,7.3,6C6.9,6.2,6.6,6.4,6.2,6.6c0.4,0.4,0.4,1.1,0,1.5
		C5.8,8.6,5.1,8.7,4.6,8.2C4.3,8.6,4.1,8.9,3.9,9.3c0.5,0.2,0.8,0.8,0.6,1.4c-0.2,0.6-0.8,1-1.4,0.8C3.1,11.9,3,12.3,3,12.8
		c0.6,0,1.1,0.4,1.2,0.9c0.1,0.6-0.3,1.2-0.9,1.3c0.1,0.4,0.2,0.8,0.4,1.2c0.5-0.3,1.1-0.1,1.5,0.3c0.4,0.5,0.3,1.2-0.3,1.6
		c0.3,0.3,0.6,0.6,0.9,0.9c0.3-0.5,1-0.6,1.5-0.3c0.6,0.3,0.8,1,0.5,1.6c0.4,0.2,0.8,0.3,1.2,0.4c0.1-0.6,0.6-1,1.2-0.9
		c0.6,0,1.1,0.6,1.1,1.2c0.4,0,0.9-0.1,1.3-0.1c-0.1-0.6,0.1-1.1,0.7-1.4c0.6-0.3,1.3,0,1.5,0.6c0.4-0.2,0.7-0.4,1.1-0.7
		c-0.4-0.4-0.4-1.1,0-1.5c0.4-0.5,1.1-0.5,1.6-0.1c0.3-0.3,0.5-0.7,0.7-1.1c-0.5-0.2-0.8-0.8-0.6-1.4c0.2-0.6,0.8-1,1.4-0.8
		c0.1-0.4,0.1-0.8,0.1-1.3c-0.6,0-1.1-0.4-1.2-0.9c-0.1-0.6,0.3-1.2,0.9-1.3c-0.1-0.4-0.2-0.8-0.4-1.2C18,10,17.7,10,17.4,9.9
		l-4.7,4.8c0,0,0,0-0.1,0.1c-1,0.9-2.5,0.9-3.5-0.1c-0.9-1-0.9-2.5,0.1-3.5L14.1,6.6L14.1,6.6z"
      />
    </g>
  )

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <Svg />
    </SvgIcon>
  )
}

export default CrankIcon
