import { type Context, useContext } from "react"

// This value can be used for the default value of context when it
// should crash if the hook is being used outside of the provider.
// biome-ignore lint/style/noNonNullAssertion:
export const intentionallyEmptyContextProps = null!

type MaybeContext<T> = Context<T | undefined>

export const useContextOrFail = <T>(context: MaybeContext<T>) => {
  const maybeContext = useContext(context as MaybeContext<T>)

  if (!maybeContext) {
    throw new Error(`use${context.displayName} called outside of its provider`)
  }

  return maybeContext
}
