import { useEffect } from "react"

import { type BrowserLogLevel, browserLogLevels, useDebugStore } from "./store"

export const BrowserConsole = () => {
  const setLogLevel = useBrowserLogLevel()

  useEffect(() => {
    Object.assign(globalThis, {
      levana: {
        setLogLevel,
      },
    })
  }, [setLogLevel])

  return null
}

const useBrowserLogLevel = () => {
  const setBrowserLogLevel = useDebugStore((state) => state.setBrowserLogLevel)

  return (level: BrowserLogLevel) => {
    // The browser console can pass any string, so validate
    if (browserLogLevels.includes(level)) {
      setBrowserLogLevel(level)
      return true
    } else {
      return `Valid levels are: ${browserLogLevels.join(" | ")}`
    }
  }
}
