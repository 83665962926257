// TODO: make a class that handles time

export const nanoToMilli = (nanosecond: number | string): number => {
  const timestamp =
    typeof nanosecond === "string" ? Number.parseInt(nanosecond) : nanosecond

  return Math.floor(timestamp / 1e6)
}

export const milliToNano = (milliseconds: number): number => {
  return Math.floor(milliseconds * 1e6)
}
