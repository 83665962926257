import List, { type ListProps } from "@mui/joy/List"
import ListDivider from "@mui/joy/ListDivider"
import ListItem from "@mui/joy/ListItem"
import ListItemButton from "@mui/joy/ListItemButton"
import ListItemContent from "@mui/joy/ListItemContent"
import ListItemDecorator from "@mui/joy/ListItemDecorator"
import { Fragment, type PropsWithChildren } from "react"
import { useTheme } from "@mui/joy/styles"
import useMediaQuery from "@mui/system/useMediaQuery"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useStore } from "zustand"

import type { RouteItem } from "@common/routes/types"
import { track } from "@perps/analytics/track"
import { Event } from "@perps/analytics/events"
import { RoutePath } from "@perps/routes"
import { EarnNavItemOnboard } from "@future/pages/tradeMarket/onboard"
import { renderRoute } from "@future/router/Router"
import type { ContextStoreProp } from "@future/context/store"

export interface HeaderNavListProps
  extends Omit<ListProps, "children">,
    ContextStoreProp<"initial"> {
  items: RouteItem[]
  onClose?: () => void
}

const HeaderNavList = (props: HeaderNavListProps) => {
  const { contextStore, ...otherProps } = props
  const { navItems, isMediumScreen } = useController(props)

  return (
    <List {...otherProps}>
      {navItems.map((item, index) => (
        <Fragment key={item.id}>
          {index > 0 && props.orientation !== "horizontal" && (
            <ListDivider inset="gutter" />
          )}
          <OnboardDecider id={item.id}>
            <ListItem>
              {typeof item.path === "string" ? (
                <ListItemButton
                  component="a"
                  onClick={() => {
                    trackAnalytics(item.id)
                    props.onClose?.()
                  }}
                  href={item.path}
                  target="_blank"
                  rel="noopener"
                  aria-label={`nav ${item.title.toLowerCase()}`}
                >
                  {item.Icon && (
                    <ListItemDecorator>
                      <item.Icon color="neutral" fontSize="xl2" />
                    </ListItemDecorator>
                  )}
                  <ListItemContent>{item.title}</ListItemContent>
                  {isMediumScreen && item.decorator}
                </ListItemButton>
              ) : (
                <ListItemButton
                  color={item.selected ? "primary" : "neutral"}
                  onClick={() => {
                    trackAnalytics(item.id)
                    item.onClick()
                    props.onClose?.()
                  }}
                  aria-label={`nav ${item.title.toLowerCase()}`}
                >
                  {item.Icon && (
                    <ListItemDecorator>
                      <item.Icon
                        color={item.selected ? "primary" : "neutral"}
                        fontSize="xl2"
                      />
                    </ListItemDecorator>
                  )}
                  <ListItemContent>{item.title}</ListItemContent>
                  {isMediumScreen && item.decorator}
                </ListItemButton>
              )}
            </ListItem>
          </OnboardDecider>
        </Fragment>
      ))}
    </List>
  )
}

export const useController = (props: HeaderNavListProps) => {
  const { items } = props
  const navigate = useNavigate()
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  const routeRootId = useStore(
    props.contextStore,
    (state) => state.route.rootId,
  )

  const navItems = useMemo(() => {
    return items.map((props) => {
      const { path } = props
      const renderedPath = typeof path === "string" ? path : renderRoute(path)

      const selected = typeof path !== "string" && path.route === routeRootId
      const onClick = () => {
        navigate(renderedPath)
      }

      return {
        ...props,
        selected,
        onClick,
      }
    })
  }, [items, navigate, routeRootId])

  return {
    navItems,
    isMediumScreen,
  }
}

const OnboardDecider = (props: PropsWithChildren<{ id: string }>) => {
  if (props.id === RoutePath.earn) {
    return <EarnNavItemOnboard>{props.children}</EarnNavItemOnboard>
  } else {
    return props.children
  }
}

const trackAnalytics = (id: string) => {
  switch (id) {
    case "trade":
    case "earn":
    case "history":
    case "stats":
    case "learn":
      track(Event.viewPage(id))
      break
  }
}

export default HeaderNavList
