import { queryOptions } from "@tanstack/react-query"

import { httpGet } from "@future/utils/http"
import { AppError } from "@future/libs/error/AppError"
import { indexerUrl } from "@future/target/domain"
import { Amount } from "@future/numerics/amount"

export const lvnCirculatingQueryOptions = () => {
  return queryOptions({
    queryKey: ["lvnCirculating"],
    retry: true,
    queryFn: async (context) => {
      const endpoint = `${indexerUrl}/coingecko/lvn_circulating_supply`
      const response = await httpGet<string>(endpoint, {
        signal: context.signal,
      })

      if (response.status !== 200) {
        throw AppError.fromResponse(response, {
          text: "Failed to fetch LVN circulating supply",
        })
      }

      return new Amount(response.data)
    },
  })
}
