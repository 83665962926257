import { getSupportedWallets, WalletType } from "@levana/chain/wallet"
import { useConnect } from "@levana/chain/account"
import type { NavigationModalControlProps } from "@levana/theme/NavigationModalDialog"

import ConnectList from "./ConnectList"
import ConnectInputContent from "./ConnectInputContent"

interface ConnectContentProps {
  modal: NavigationModalControlProps
}

const ConnectContent = (props: ConnectContentProps) => {
  const { connect } = useConnect({
    onSuccess: () => props.modal.close(),
  })

  const supportedWallets = getSupportedWallets()

  const handleConnect = (walletType: WalletType) => {
    if (walletType === WalletType.viewing) {
      props.modal.push(ConnectInputContent.name)
    } else {
      connect({ walletType })
    }
  }

  return (
    <ConnectList
      walletTypes={supportedWallets}
      onConnectorClick={handleConnect}
    />
  )
}

export default ConnectContent
