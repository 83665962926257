import { useTheme } from "@mui/joy/styles";
import useMediaQuery from "@mui/system/useMediaQuery";
import { useEffect } from "react";
import { create } from "zustand";
const breakpoints = (() => {
    // Ensure we have type safety for using all breakpoints
    const breakpointRecord = {
        xs: 0,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 4,
    };
    const keys = Object.keys(breakpointRecord);
    return keys.sort((a, b) => breakpointRecord[a] - breakpointRecord[b]);
})();
export const useBreakpointDetectionStore = create((_set, get) => ({
    breakpoint: "xs",
    isGreater: (breakpoint) => {
        return (breakpoints.indexOf(get().breakpoint) > breakpoints.indexOf(breakpoint));
    },
    isGreaterOrEqual: (breakpoint) => {
        return (breakpoints.indexOf(get().breakpoint) >= breakpoints.indexOf(breakpoint));
    },
}));
const BreakpointDetection = () => {
    const theme = useTheme();
    for (const breakpoint of theme.breakpoints.keys) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const isMediaQuery = useMediaQuery(theme.breakpoints.only(breakpoint));
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            if (isMediaQuery) {
                useBreakpointDetectionStore.setState({ breakpoint });
            }
        }, [isMediaQuery]);
    }
    return null;
};
export default BreakpointDetection;
