import { queryOptions } from "@tanstack/react-query"

import { SECOND } from "@common/utils/date"
import { queryQuerier } from "@perps/sdk/client/query"

interface AnnouncementsResponse {
  global: string | null
}

export const announcementsQueryOptions = () => {
  return queryOptions({
    queryKey: ["announcements"],
    refetchInterval: SECOND * 30,
    queryFn: async (context) => {
      return queryQuerier<AnnouncementsResponse>(
        "/v1/perps/announcements",
        "Unable to fetch announcements",
        context.signal,
        {},
        { disablePresentation: true },
      )
    },
  })
}
