import PromiseThrottle from "promise-throttle"

import { indexerUrl, querierUrl } from "@future/target/domain"

const querierThrottle = new PromiseThrottle({
  requestsPerSecond: 20,
})

const indexerThrottle = new PromiseThrottle({
  requestsPerSecond: 10,
})

// abstracted into its own little wrapper so that logging can be added
// precisely at the time when the throttled promise is created
export const throttleQuerier = <T>(fn: () => Promise<T>): Promise<T> => {
  return querierThrottle.add(() => {
    //console.log("firing querier!")
    return fn()
  })
}

export const throttleIndexer = <T>(fn: () => Promise<T>): Promise<T> => {
  return indexerThrottle.add(() => {
    return fn()
  })
}

const isQuerier = (url: string) => url.includes(querierUrl)
const isIndexer = (url: string) => url.includes(indexerUrl)

export const throttleRequest = <T>(url: string, fn: () => Promise<T>) => {
  if (isQuerier(url)) {
    return throttleQuerier(fn)
  } else if (isIndexer(url)) {
    return throttleIndexer(fn)
  } else {
    return fn()
  }
}
