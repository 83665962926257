import { WalletErrorType } from "../../shared/error";
import { metaMaskWallet } from "../metaMask";
import { base64Image } from "./icon";
const walletInfo = Object.assign(Object.assign({}, metaMaskWallet.info), { name: "MetaMask", icon: base64Image, isChainSupported: (chainId) => {
        switch (chainId) {
            case "injective-1":
            case "injective-888":
                return false;
            default:
                return true;
        }
    }, errors: Object.assign(Object.assign({}, metaMaskWallet.info.errors), { generic: [
            ...metaMaskWallet.info.errors.generic,
            {
                message: "User rejected the request.",
                type: WalletErrorType.rejected,
            },
        ] }) });
export const leapMetaMaskWallet = {
    provider: () => {
        const installed = typeof window.ethereum !== "undefined";
        if (!installed) {
            return { installed };
        }
        return {
            installed,
        };
    },
    info: walletInfo,
};
