var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getConnectResult } from "../shared/account";
import { useChainStore } from "../shared/store";
import { updateEthereumChainIfNeeded, walletTypeFromInjective, walletTypeToInjective, } from "./wallet";
import { injectiveSessionDefaultValues, useInjectiveSessionStore, useInjectiveStore, } from "./store";
import { hexToUint8Array } from "./utils";
import { getWallet } from "../wallet";
import { injectiveSigning } from "./signing";
import { ErrorMessage } from "../shared/error";
export const injectiveAccount = () => {
    var _a;
    return (_a = useInjectiveSessionStore.getState().accounts) === null || _a === void 0 ? void 0 : _a[useChainStore.getState().chainId];
};
const connect = (chainId) => __awaiter(void 0, void 0, void 0, function* () {
    const { walletStrategy } = useInjectiveStore.getState();
    if (!walletStrategy) {
        throw new Error("Missing WalletStrategy for connect");
    }
    const addresses = yield walletStrategy.getAddresses();
    const address = addresses === null || addresses === void 0 ? void 0 : addresses.at(0);
    if (!address) {
        throw new Error(ErrorMessage.noAccount);
    }
    const walletType = yield walletTypeFromInjective(walletStrategy.wallet);
    const wallet = getWallet(walletType);
    const { getKey } = wallet.provider();
    if (getKey) {
        try {
            const account = yield getKey(chainId);
            return account;
        }
        catch (_a) { }
    }
    const { getInjectiveAddress, getEthereumAddress } = yield import("@injectivelabs/sdk-ts");
    const isInjectiveAddress = address.startsWith("inj1");
    const bech32Address = isInjectiveAddress
        ? address
        : getInjectiveAddress(address);
    const ethereumHexAddress = isInjectiveAddress
        ? getEthereumAddress(address)
        : address;
    const pubKey = yield (() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            return yield walletStrategy.getPubKey();
        }
        catch (error) {
            if (error instanceof Error) {
                console.warn(error.message);
            }
            return "";
        }
    }))();
    return {
        name: "",
        algo: "",
        pubKey: hexToUint8Array(pubKey),
        address: hexToUint8Array(bech32Address),
        bech32Address,
        ethereumHexAddress,
        isNanoLedger: false,
        isKeystone: false,
    };
});
export const injectiveConnect = (account) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const chainId = useChainStore.getState().chainId;
    const walletType = account.walletType;
    const injectiveWalletType = yield walletTypeToInjective(walletType);
    (_b = useInjectiveStore.getState().walletStrategy) === null || _b === void 0 ? void 0 : _b.setWallet(injectiveWalletType);
    const key = yield connect(chainId);
    updateEthereumChainIfNeeded(chainId);
    useInjectiveSessionStore.setState((state) => ({
        accounts: Object.assign(Object.assign({}, (state.accounts || {})), { [chainId]: key }),
    }));
    const signing = () => __awaiter(void 0, void 0, void 0, function* () { return injectiveSigning(chainId); });
    return getConnectResult({ account: key, walletType }, chainId, signing);
});
export const injectiveDisconnect = () => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    useInjectiveSessionStore.setState(injectiveSessionDefaultValues);
    return (_c = useInjectiveStore.getState().walletStrategy) === null || _c === void 0 ? void 0 : _c.disconnect();
});
