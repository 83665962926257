import { useTranslation } from "react-i18next"
import List from "@mui/joy/List"
import ListDivider from "@mui/joy/ListDivider"
import ListItem from "@mui/joy/ListItem"
import ListItemButton from "@mui/joy/ListItemButton"
import ListItemContent from "@mui/joy/ListItemContent"
import ListItemDecorator from "@mui/joy/ListItemDecorator"
import Radio from "@mui/joy/Radio"
import Stack from "@mui/joy/Stack"
import { useNavigate as useRouterNavigate } from "react-router-dom"
import { useStore } from "zustand"
import NavigationModal, {
  NavigationModalDynamicItem,
} from "@levana/theme/NavigationModal"

import { type PerpsRoute, renderRoute } from "@future/router/Router"
import { Event } from "@perps/analytics/events"
import { EventKey } from "@perps/analytics/constants"
import { track } from "@perps/analytics/track"
import NetworkIcon from "@common/icons/NetworkIcon"
import { domainConfig } from "@future/target/domain"
import type { ContextStoreProp } from "@future/context/store"
import type { MarketSlug } from "@future/market/config/constants"

import ChainLogo from "../ChainLogo"

export interface ChooseNetworkModalProps extends ContextStoreProp<"initial"> {
  isOpen: boolean
  close: () => void
}

const ChooseNetworkModal = (props: ChooseNetworkModalProps) => {
  const { t } = useTranslation("common")
  const routerNavigate = useRouterNavigate()

  const route = useStore(props.contextStore, (state) => state.route)

  return (
    <NavigationModal rootId={ChooseNetworkModal.name}>
      <NavigationModalDynamicItem
        id={ChooseNetworkModal.name}
        title={t("menu.general.network")}
        icon={<NetworkIcon sx={{ color: "text.secondary" }} />}
        canClose
      >
        <Stack component={List} divider={<ListDivider inset="gutter" />}>
          {domainConfig.availableTargets
            .filter((targetInfo) => !targetInfo.hideInNetworkList)
            .sort((a, b) => {
              const aFirst = a.name.at(0)
              const bFirst = b.name.at(0)
              const aLower = (aFirst ?? a.name).toLowerCase()
              const bLower = (bFirst ?? b.name).toLowerCase()
              const lowerCaseComparison = aLower.localeCompare(bLower)

              if (lowerCaseComparison !== 0) {
                return lowerCaseComparison
              }

              const isACapitalized = aFirst === aFirst?.toUpperCase()
              const isBCapitalized = bFirst === bFirst?.toUpperCase()

              if (isACapitalized && !isBCapitalized) {
                return -1
              }
              if (!isACapitalized && isBCapitalized) {
                return 1
              }
              return 0
            })
            .map((targetInfo) => (
              <ListItem
                key={targetInfo.slug}
                slotProps={{
                  root: { sx: { backgroundColor: "transparent" } },
                  endAction: { sx: { pointerEvents: "none" } },
                }}
                endAction={
                  <Radio
                    size="lg"
                    checked={route.targetSlug === targetInfo.slug}
                    sx={{ pointerEvents: "none" }}
                    slotProps={{
                      radio: {
                        sx: ({ vars }) => ({
                          borderColor: vars.palette.primary[500],
                          color: vars.palette.primary[500],
                          backgroundColor: "transparent",
                        }),
                      },
                    }}
                  />
                }
              >
                <ListItemButton
                  onClick={() => {
                    if (route.targetSlug !== targetInfo.slug) {
                      // TODO: the routing types should be improved
                      const newRoute: PerpsRoute & {
                        marketSlug: MarketSlug
                      } = {
                        targetSlug: targetInfo.slug,
                        route: route.rootId ?? route.fallbackRootId,
                        marketSlug:
                          route.marketSlug ?? route.fallbackMarketSlug,
                      }

                      track(
                        Event.networkSelected({
                          [EventKey.CHAIN]: targetInfo.id,
                          [EventKey.NETWORK]: targetInfo.slug,
                        }),
                      )

                      routerNavigate(renderRoute(newRoute))
                      props.close()
                    }
                  }}
                  aria-label={targetInfo.slug}
                >
                  <ListItemDecorator sx={{ mr: 1.5 }}>
                    <ChainLogo
                      size={40}
                      icon={targetInfo.icon}
                      display={targetInfo.name}
                    />
                  </ListItemDecorator>
                  <ListItemContent>{targetInfo.name}</ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
        </Stack>
      </NavigationModalDynamicItem>
    </NavigationModal>
  )
}

export default ChooseNetworkModal
