import type { MarketId } from "@perps/sdk/types"
import { createSymbolInfo } from "@common/tradingView/symbolsInfo"
import { Amount } from "@future/numerics/amount"

import type { MarketDisplayConfig } from "./constants"

interface Market
  extends Omit<
    MarketDisplayConfig,
    "collateral" | "notional" | "makeTradingViewSymbolInfo" | "earnPageTitle"
  > {
  description: string
}

export class MarketDisplayConfigMap {
  private configMap: Map<MarketId, MarketDisplayConfig>
  private iconMap: Map<string, string>

  constructor(markets: Market[]) {
    this.configMap = new Map()
    this.iconMap = new Map()

    for (const market of markets) {
      const { id, base, quote, type, title, description } = market

      if (this.configMap.has(id)) {
        throw new Error(`Duplicated ID in MarketDisplayConfig: ${id}`)
      }

      const [collateral, notional] =
        type === "collateral_is_base" ? [base, quote] : [quote, base]

      this.configMap.set(market.id, {
        ...market,
        collateral,
        notional,
        earnPageTitle: type === "collateral_is_base" ? base : title,
        makeTradingViewSymbolInfo: () =>
          createSymbolInfo({
            name: id,
            full_name: title,
            description,
            pricescale: new Amount(10)
              .pow(market?.quoteDecimalPlaces ?? 6)
              .toNumber(),
          }),
      })

      this.iconMap.set(collateral.toLowerCase(), market.collateralIcon)
    }
  }

  has(marketId: MarketId) {
    return this.configMap.has(marketId)
  }

  get(marketId: MarketId) {
    return this.configMap.get(marketId)
  }

  /**
   * Find a collateral icon
   *
   * The `collateralId` is case-insensitive.
   */
  getCollateralIcon(collateralId: string) {
    return this.iconMap.get(collateralId.toLowerCase())
  }
}
