var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from "zustand";
/**
 * Lazy load data in a store
 *
 * This allows for the same data to be reused anywhere it's called.
 *
 * @example
 * const useClient = lazyLoadStore(async () => await import("./lib"))
 * // Rerender once the data loads
 * const client = useClient()
 * // Wait for the data to load
 * const client = await useClient.getState()
 */
export const lazyLoadStore = (loadData) => {
    const store = create((set, get) => ({
        data: undefined,
        load: () => __awaiter(void 0, void 0, void 0, function* () {
            if (!get().data) {
                set({ data: yield loadData() });
            }
        }),
    }));
    const caller = () => {
        const s = store();
        s.load();
        return s.data;
    };
    caller.getState = () => __awaiter(void 0, void 0, void 0, function* () {
        yield store.getState().load();
        return store.getState().data;
    });
    return caller;
};
export const lazyLoad = (lazyImport) => __awaiter(void 0, void 0, void 0, function* () {
    const module = yield lazyImport();
    return module.default;
});
