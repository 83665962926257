import Box from "@mui/joy/Box"
import Typography from "@mui/joy/Typography"
import Skeleton from "@mui/joy/Skeleton"
import { useTranslation } from "react-i18next"

import { useReferralStatsQuery } from "@future/network/referralStats"
import type { ContextStoreProp } from "@future/context/store"

const ReferralCountSection = (props: ContextStoreProp<"standard">) => {
  const { t } = useTranslation("perpsCommon")
  const referralStatsQuery = useReferralStatsQuery(props)

  return (
    <Box>
      <Typography level="body-sm" textColor="text.secondary">
        {t("referral.referralCount.title")}
      </Typography>
      <Typography level="h4" textColor="text.primary">
        <Skeleton
          loading={!referralStatsQuery.data && referralStatsQuery.isFetching}
          variant="rectangular"
          height={30}
        >
          {referralStatsQuery.data?.refereesCount.toFormat({ comma: true })}
        </Skeleton>
      </Typography>
    </Box>
  )
}

export default ReferralCountSection
