import { useEffect } from "react"
import { useRouteError } from "react-router-dom"

import InvalidRootLayout, {
  type InvalidRootContent,
} from "@future/root/InvalidRootContent"
import { Report } from "@future/libs/error/report"
import NotFoundPage from "@app/not-found"
import { AppError } from "@future/libs/error/AppError"
import type { ContextStoreProp } from "@future/context/store"

export class RouteNotFoundError extends Error {}
export class TestErrorPage extends Error {
  page: string
  constructor(page: string) {
    super("Testing error pages")
    this.page = page
  }
}

const RouteRootError = (props: ContextStoreProp<"initial">) => {
  const error = useRouteError()

  useEffect(() => {
    if (error instanceof RouteNotFoundError) {
      return
    }

    Report.error(AppError.fromError(error, { text: "Route error" }))
  }, [error])

  if (error instanceof RouteNotFoundError) {
    return <NotFoundPage contextStore={props.contextStore} />
  }
  if (error instanceof TestErrorPage) {
    const content: InvalidRootContent = (() => {
      switch (error.page) {
        case "geoblocked":
          return { type: "geoblocked" }
        case "loading":
          return { type: "loading", desc: ["Fake data 1", "Fake data 2"] }
        case "not-found":
          return { type: "not-found" }
        case "maintenance":
          return { type: "maintenance", message: "general" }
        case "network-data-error":
          return {
            type: "network-data-error",
            desc: "Some fake data",
            error: new Error("Simulated failure on network data"),
          }
        case "root-error":
          return {
            type: "root-error",
            error: new Error("Simulated root failure"),
          }
        default:
          return {
            type: "root-error",
            error: new Error(
              `Supplied unknown page: ${error.page}. Expected one of: geoblocked, loading, not-found, maintenance, network-data-error, root-error`,
            ),
          }
      }
    })()
    return (
      <InvalidRootLayout content={content} contextStore={props.contextStore} />
    )
  }

  const content: InvalidRootContent = {
    type: "root-error",
    error,
  }

  return (
    <InvalidRootLayout content={content} contextStore={props.contextStore} />
  )
}

export default RouteRootError
