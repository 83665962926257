import {
  createContext,
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useState,
} from "react"

import { useContextOrFail } from "@common/context"

import BannerLayout from "../BannerLayout"
import SystemBannerLayout from "../SystemBannerLayout"

export interface BannerContextProps {
  system: {
    show: (key: string, message: ReactNode, endDecorator?: JSX.Element) => void
    hide: (key: string) => void
  }
  common: {
    show: (key: string, content: ReactNode, path?: RegExp | string) => void
    hide: (key: string) => void
  }
}

const BannerContext = createContext<BannerContextProps | undefined>(undefined)

export const useBannerContext = () => useContextOrFail(BannerContext)

const BannerProvider = (props: PropsWithChildren) => {
  const [systemBanners, setSystemBanners] = useState<
    Record<string, { message: ReactNode; endDecorator?: JSX.Element }>
  >({})
  const systemBanner = Object.values(systemBanners).at(0)

  const [commonBanners, setCommonBanners] = useState<
    Record<string, { content: ReactNode; path?: RegExp | string }>
  >({})

  const contextValue: BannerContextProps = {
    system: {
      show: useCallback((key, message, endDecorator) => {
        setSystemBanners((previousMessages) => {
          if (!previousMessages[key]) {
            console.info(`Show system banner '${key}'`)
          }
          previousMessages[key] = { message, endDecorator }
          return { ...previousMessages }
        })
      }, []),
      hide: useCallback((key) => {
        setSystemBanners((previousMessages) => {
          if (previousMessages[key]) {
            console.info(`Hide system banner '${key}'`)
          }
          delete previousMessages[key]
          return { ...previousMessages }
        })
      }, []),
    },
    common: {
      show: useCallback((key, content, path) => {
        setCommonBanners((previousMessages) => {
          previousMessages[key] = { content, path }
          return { ...previousMessages }
        })
      }, []),
      hide: useCallback((key) => {
        setCommonBanners((previousMessages) => {
          delete previousMessages[key]
          return { ...previousMessages }
        })
      }, []),
    },
  }

  return (
    <BannerContext.Provider value={contextValue}>
      {systemBanner && (
        <SystemBannerLayout
          message={systemBanner.message}
          endDecorator={systemBanner.endDecorator}
        />
      )}
      <BannerLayout
        bannerItems={Object.entries(commonBanners).map(([key, value]) => ({
          id: key,
          ...value,
        }))}
      />
      {props.children}
    </BannerContext.Provider>
  )
}

export default BannerProvider
