import { isInjectiveChainId } from "../injective/chain";
import { useChainStore } from "../shared/store";
export const decideChainAction = (args) => {
    var _a;
    const chainId = (_a = args.chainId) !== null && _a !== void 0 ? _a : useChainStore.getState().chainId;
    return isInjectiveChainId(chainId) ? args.injective : args.cosmos;
};
export const useDecideChainAction = (args) => {
    var _a;
    const storeChainId = useChainStore((state) => state.chainId);
    const chainId = (_a = args.chainId) !== null && _a !== void 0 ? _a : storeChainId;
    return isInjectiveChainId(chainId) ? args.injective : args.cosmos;
};
