import { Buffer } from "buffer"
import React from "react"
import ReactDOM from "react-dom/client"

import { initSentry } from "@future/sentry"
import { initI18n } from "@future/i18n"
import RootLayout from "@future/root/RootLayout"
import { initConsole } from "@future/libs/debug/console"
import { initMonkeyPatch } from "@future/utils/monkeyPatch"

initSentry()
initI18n()
initConsole()
initMonkeyPatch()

globalThis.Buffer = Buffer

const outlet = document.getElementById("root")

if (outlet) {
  ReactDOM.createRoot(outlet).render(
    <React.StrictMode>
      <RootLayout />
    </React.StrictMode>,
  )
}
