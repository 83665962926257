import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"

import { useCanUpdateVersion } from "@future/update/updateVersion"
import { updateConfigQueryOptions } from "@future/network/updateConfig"

import { Report } from "./report"

const ReportEnabledDecider = () => {
  const updateConfigResult = useQuery(updateConfigQueryOptions())
  const { canUpdate } = useCanUpdateVersion({
    updateConfigCommitHashShort: updateConfigResult.data?.commitHashShort,
  })

  useEffect(() => {
    if (canUpdate) {
      Report.disableReporting()
    } else {
      Report.enableReporting()
    }
  }, [canUpdate])

  return null
}

export default ReportEnabledDecider
