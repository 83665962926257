export const uint8ArrayToObject = (array) => {
    return Array.from(array).reduce((accumulator, byte, index) => {
        accumulator[index] = byte;
        return accumulator;
    }, {});
};
export const objectToUint8Array = (obj) => {
    const keys = Object.keys(obj);
    const array = new Uint8Array(keys.length);
    for (const key of keys) {
        const index = Number.parseInt(key, 10);
        array[index] = obj[index];
    }
    return array;
};
