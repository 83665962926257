import { useEffect, useRef } from "react"

import {
  useCreateOnboardCategory,
  useOnboardStepLayout,
} from "@future/utils/onboard"
import { useTradeFeeOnboard } from "@future/pages/tradeMarket/onboard"

type NotifiOnboardStep = "notifiNaviItem"

const steps: NotifiOnboardStep[] = ["notifiNaviItem"]

export const useNotifiOnboard = () => {
  return useCreateOnboardCategory("Notifi", steps)
}

export const useNotifiNavItemOnboard = () => {
  const onboard = useNotifiOnboard()
  const tradeFeeOnboard = useTradeFeeOnboard()
  const ref = useRef<HTMLElement>()
  const { updateOnboardStepLayout } = useOnboardStepLayout()

  useEffect(() => {
    onboard.setup()
  }, [onboard.setup])

  useEffect(() => {
    if (tradeFeeOnboard.done) {
      // Create a delay to prevent touch events dismissing onboard steps
      setTimeout(onboard.start, 0)
    }
  }, [onboard.start, tradeFeeOnboard.done])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    updateOnboardStepLayout(ref.current, onboard.enabled, true)
  }, [onboard.enabled, updateOnboardStepLayout])

  return { ...onboard, ref }
}
