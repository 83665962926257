import Box from "@mui/joy/Box"
import CircularProgress from "@mui/joy/CircularProgress"
import type { ColorPaletteProp } from "@mui/joy/styles"
import type { PropsWithChildren } from "react"

interface NotificationSymbolProps extends PropsWithChildren {
  color: ColorPaletteProp
  progress?: boolean
}

const NotificationSymbol = (props: NotificationSymbolProps) => {
  const { color, progress, children } = props
  const borderSize = 2.5

  return (
    <Box
      sx={{
        "--width": "3rem",

        width: "var(--width)",
        height: "var(--width)",
        borderRadius: "calc(var(--width) / 2)",
        p: "var(--padding)",

        ...(progress && {
          "--padding": "calc(var(--width) / 12)",
          backgroundColor: "var(--palette-background-body)",
        }),

        ...(!progress && {
          "--padding": "calc(var(--width) / 8)",
          backgroundColor: `rgba(var(--palette-${color}-mainChannel) / 1)`,
        }),
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",

          ...(!progress && {
            borderRadius: "calc((var(--width) - var(--padding) * 2) / 2)",
            border: `${borderSize}px solid rgba(255 255 255 / 0.5)`,
          }),
        }}
      >
        {progress && (
          <CircularProgress
            color="success"
            variant="plain"
            thickness={borderSize}
            value={50}
            sx={{
              "--CircularProgress-size": "100%",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
          />
        )}
        {children}
      </Box>
    </Box>
  )
}

export default NotificationSymbol
