import { AppError } from "@future/libs/error/AppError"
import { querierUrl } from "@future/target/domain"
import { httpGet } from "@future/utils/http"

export const queryQuerier = async <T>(
  path: string,
  desc: string,
  signal: AbortSignal,
  params?: Record<string, string>,
  errorOptions?: { disablePresentation: boolean },
): Promise<T> => {
  const queryString = params ? `?${new URLSearchParams(params)}` : ""
  const url = `${querierUrl}${path}${queryString}`

  try {
    const response = await httpGet(url, { signal })

    if (response.status !== 200) {
      const appError = AppError.fromQuerierResponse(response, desc)
      if (response.status === 429) {
        throw AppError.fromError(appError, {
          text: "Too many requests. Please try again later",
          presentable: true,
        })
      }
      throw appError
    }

    return response.data
  } catch (e) {
    if (e instanceof AppError) {
      throw e
    } else {
      throw AppError.fromError(e, { ...errorOptions, text: desc })
    }
  }
}
