import { queryOptions } from "@tanstack/react-query"

import { httpGet } from "@future/utils/http"
import { AppError } from "@future/libs/error/AppError"
import { Report } from "@future/libs/error/report"
import type { FullNodeStatusResp } from "@future/systemHealth/types"
import { SECOND } from "@common/utils/date"

export const nodeStatusQueryOptions = (rpcUrl: string) => {
  return queryOptions({
    queryKey: ["nodeStatus", rpcUrl],
    refetchInterval: SECOND * 30,
    queryFn: async (context) => {
      const endpoint = `${rpcUrl}/status`
      const response = await httpGet(endpoint, { signal: context.signal })

      const nodeStatus: FullNodeStatusResp | undefined = (() => {
        if (response.status !== 200) {
          return undefined
        }
        if (response.data.result) {
          return response.data.result
        } else {
          return response.data
        }
      })()

      if (
        nodeStatus &&
        "sync_info" in nodeStatus &&
        "latest_block_time" in nodeStatus.sync_info
      ) {
        return nodeStatus as FullNodeStatusResp
      } else {
        Report.addBreadcrumb({
          level: "debug",
          type: "query",
          message: "Response shape",
          data: response.data,
        })

        throw AppError.fromText("Invalid response shape", {
          disablePresentation: true,
        })
      }
    },
  })
}
