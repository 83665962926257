var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/joy/Box";
import ModalDialog from "@mui/joy/ModalDialog";
import { useTheme } from "@mui/joy/styles";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import useMediaQuery from "@mui/system/useMediaQuery";
import { styled, useThemeProps } from "@mui/joy/styles";
import useSlot from "@mui/joy/utils/useSlot";
import { Fragment, useCallback, forwardRef } from "react";
import { useModal } from "@levana/utils/modal";
import HelperButton from "@levana/theme/HelperButton";
import NavigationModalHeader from "./NavigationModalHeader";
import PreventUnmounting from "./PreventUnmounting";
const name = "LevanaNavigationModalDialog";
const NavigationModalDialogRoot = styled(ModalDialog, {
    name,
    slot: "root",
})(({ theme, ownerState }) => ({
    "--ModalContent-horizontalPadding": `max(
      var(--ModalContent-padding), 
      calc((100vw - var(--ModalContent-maxWidth)) / 2)
    )`,
    [theme.breakpoints.up(ownerState.centerBreakpoint)]: {
        "--ModalContent-horizontalPadding": "calc((var(--ModalDialog-maxWidth) - var(--ModalContent-maxWidth)) / 2)",
    },
}));
const NavigationModalDialogHeader = styled(NavigationModalHeader, {
    name,
    slot: "header",
})({});
const NavigationModalDialogContent = styled(Box, {
    name,
    slot: "content",
})({
    marginTop: "var(--NavigationModal-contentTop, 0.5rem)",
});
const NavigationModalDialog = forwardRef(function NavigationModalDialog(inProps, ref) {
    const props = useThemeProps({ props: inProps, name });
    const { rootId, topItem, stack, setStack, onDismiss, centerBreakpoint = "sm", children } = props, otherProps = __rest(props, ["rootId", "topItem", "stack", "setStack", "onDismiss", "centerBreakpoint", "children"]);
    const ownerState = Object.assign(Object.assign({}, props), { centerBreakpoint });
    const externalForwardedProps = Object.assign({}, otherProps);
    const [SlotRoot, rootProps] = useSlot("root", {
        ref,
        className: "root",
        elementType: NavigationModalDialogRoot,
        ownerState,
        externalForwardedProps,
    });
    const [SlotHeader, headerProps] = useSlot("header", {
        ref,
        className: "header",
        elementType: NavigationModalDialogHeader,
        ownerState,
        externalForwardedProps,
    });
    const [SlotContent, contentProps] = useSlot("content", {
        ref,
        className: "content",
        elementType: NavigationModalDialogContent,
        ownerState,
        externalForwardedProps,
    });
    const { dismiss } = useModal();
    const handlePop = useCallback(() => {
        setStack((stack) => (stack.length > 1 ? stack.slice(0, -1) : stack));
    }, [setStack]);
    const handleNavigationBarPop = (topItem === null || topItem === void 0 ? void 0 : topItem.canPop) && stack.length > 1 ? handlePop : undefined;
    const handlePush = useCallback((next) => {
        setStack((stack) => {
            if (typeof next === "string") {
                return [...stack, { type: "dynamic", id: next }];
            }
            else {
                return [...stack, Object.assign({ type: "static" }, next)];
            }
        });
    }, [setStack]);
    const handleDismiss = () => {
        var _a;
        dismiss();
        (_a = props.onDismiss) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const theme = useTheme();
    const isCenterBreakpoint = useMediaQuery(theme.breakpoints.up(centerBreakpoint));
    return (_jsxs(SlotRoot, Object.assign({}, rootProps, { size: "lg", layout: isCenterBreakpoint ? "center" : "fullscreen", children: [_jsxs(SlotHeader, Object.assign({}, headerProps, { onBack: handleNavigationBarPop, canClose: topItem === null || topItem === void 0 ? void 0 : topItem.canClose, children: [(topItem === null || topItem === void 0 ? void 0 : topItem.subtitle) && (_jsx(Typography, { level: "body-xs", sx: {
                            textTransform: "uppercase",
                            height: "var(--ModalHeader-height, calc(var(--IconButton-size) * 0.66))",
                        }, children: topItem.subtitle })), _jsxs(ModalTitle, { children: [(topItem === null || topItem === void 0 ? void 0 : topItem.icon) && (_jsx(Box, { sx: { display: "flex", justifyContent: "center", mb: 1 }, children: typeof topItem.icon === "function" ? (_jsx(topItem.icon, { sx: { color: "text.secondary" } })) : (_jsx(_Fragment, { children: topItem.icon })) })), (topItem === null || topItem === void 0 ? void 0 : topItem.helper) ? (_jsxs(Stack, { direction: "row", columnGap: 1, alignItems: "center", justifyContent: "center", children: [topItem.title, _jsx(HelperButton, Object.assign({}, topItem.helper, { "aria-label": `modal ${topItem.title} helper` }))] })) : (topItem === null || topItem === void 0 ? void 0 : topItem.title)] })] })), _jsx(Box, { sx: {
                    width: "100%",
                    alignSelf: "center",
                    overflow: "auto",
                    px: "var(--ModalContent-horizontalPadding)",
                    pb: "var(--ModalContent-padding)",
                }, children: _jsxs(SlotContent, Object.assign({}, contentProps, { children: [stack.map((item) => (_jsx(Fragment, { children: item.type === "static" && (_jsx(PreventUnmounting, { show: (topItem === null || topItem === void 0 ? void 0 : topItem.id) === item.id, children: _jsx(item.view, { push: handlePush, pop: handlePop, close: handleDismiss }) })) }, item.id))), typeof children === "function"
                            ? children({
                                push: handlePush,
                                pop: handlePop,
                                close: handleDismiss,
                            })
                            : children] })) })] })));
});
const ModalTitle = (props) => {
    const { sx, children } = props, joyProps = __rest(props, ["sx", "children"]);
    return (_jsx(Typography, Object.assign({}, joyProps, { level: "h3", sx: Object.assign(Object.assign({}, sx), { textAlign: "center" }), children: children })));
};
export default NavigationModalDialog;
