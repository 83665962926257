import { chainConfigMap } from "@common/target/constants"

export type ElementsChainName = keyof typeof elementsAssetTokenRecord
export type ElementsAssetToken<C extends ElementsChainName> =
  keyof (typeof elementsAssetTokenRecord)[C]["token"]

export const elementsAssetTokenRecord = {
  ethereum: {
    chainId: "1",
    token: {
      eth: "ethereum-native",
      usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    },
  },
  osmosis: {
    chainId: chainConfigMap["osmosis-mainnet"].chainId,
    token: {
      // These values come from the market config
    },
  },
} satisfies Record<string, { chainId: string; token: Record<string, string> }>
