import { Report } from "@future/libs/error/report"
import type {
  MarketInfoResponse,
  SpotPriceConfig,
  StatusResp,
} from "@perps/sdk/types"
import {
  type MarketConfig,
  marketConfigFromResp,
} from "@future/market/config/types"
import type { MarketDisplayConfig } from "@future/market/config/constants"
import { mockPythPriceSource } from "@future/market/price/mock"
import type { TargetId } from "@common/target/types"

import type { TargetInfo } from "./info"

export const updateTargetDataInReports = (
  targetId?: TargetId,
  factoryAddress?: string,
) => {
  Report.setTag("target", targetId)
  Report.setTag("factory", factoryAddress)

  Report.addBreadcrumb({
    level: "info",
    type: "info",
    category: "target",
    data: {
      target: targetId,
      factory: factoryAddress,
    },
  })
}

export const makeMarketConfig = (
  targetInfo: TargetInfo,
  rawStatus: StatusResp,
  marketDisplayConfig: MarketDisplayConfig,
  info: MarketInfoResponse,
  inWinddown: boolean,
): MarketConfig => {
  const marketId = rawStatus.market_id

  let priceSourceOverride: "contractOnly" | "mockPyth" | undefined

  if (targetInfo.directPriceUpdates) {
    priceSourceOverride = "contractOnly"
  }

  const spotPrice = ((): SpotPriceConfig => {
    if (priceSourceOverride === "contractOnly") {
      return {
        manual: {
          admin: "contractOnly",
        },
      }
    }

    if (priceSourceOverride === "mockPyth") {
      return {
        oracle: {
          feeds: mockPythPriceSource(marketId).feeds.map((feed) => {
            return {
              data: {
                pyth: {
                  id: feed.id,
                  age_tolerance_seconds: 120,
                },
              },
              inverted: feed.inverted,
            }
          }),
          feeds_usd: [],
          pyth: null,
          stride: null,
        },
      }
    }

    return rawStatus.config.spot_price
  })()

  return marketConfigFromResp(
    marketDisplayConfig,
    rawStatus,
    info,
    spotPrice,
    inWinddown,
  )
}
