import { useColorScheme } from "@mui/joy/styles";
export const useThemeMode = () => {
    const { mode, systemMode } = useColorScheme();
    switch (mode) {
        case "system":
            return systemMode === "light" ? "light" : "dark";
        case "light":
            return "light";
        case "dark":
        case undefined:
            return "dark";
    }
};
