import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const XIcon = (props: SvgIconProps) => {
  const Svg = () => (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 0.292893C0.683417 -0.0976309 1.31658 -0.0976309 1.70711 0.292893L9.70711 8.29289C10.0976 8.68342 10.0976 9.31658 9.70711 9.70711C9.31658 10.0976 8.68342 10.0976 8.29289 9.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 9.70711C0.683417 10.0976 1.31658 10.0976 1.70711 9.70711L9.70711 1.70711C10.0976 1.31658 10.0976 0.683418 9.70711 0.292894C9.31658 -0.0976312 8.68342 -0.0976312 8.29289 0.292894L0.292893 8.29289C-0.0976311 8.68342 -0.0976311 9.31658 0.292893 9.70711Z"
      />
    </g>
  )

  return (
    <SvgIcon {...props} viewBox="0 0 10 10">
      <Svg />
    </SvgIcon>
  )
}

export default XIcon
