var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import IconButton from "@mui/joy/IconButton";
import { forwardRef } from "react";
import { lazyLoad } from "@levana/utils/lazyLoad";
import { useModal } from "@levana/utils/modal";
import QuestionMarkIcon from "./icons/QuestionMarkIcon";
import { muiMergeSx } from "./utils/mui";
const HelperButton = forwardRef((props, ref) => {
    const { modal, onClick, sx } = props, joyProps = __rest(props, ["modal", "onClick", "sx"]);
    const { present } = useModal();
    return (_jsx(IconButton, Object.assign({ color: "neutral", variant: "soft", "aria-label": "help" }, joyProps, { ref: ref, onClick: (event) => __awaiter(void 0, void 0, void 0, function* () {
            onClick === null || onClick === void 0 ? void 0 : onClick(event);
            if (modal) {
                const HelperModal = yield lazyLoad(() => import("./HelperModal"));
                present(_jsx(HelperModal, { title: modal.title, message: modal.message }));
            }
        }), sx: [
            {
                "--IconButton-size": "1rem",
                "--Icon-fontSize": "calc(var(--IconButton-size) / 1.2)",
                color: "text.secondary",
                paddingInline: 0,
            },
            (theme) => muiMergeSx(theme, sx),
        ], children: _jsx(QuestionMarkIcon, {}) })));
});
export default HelperButton;
