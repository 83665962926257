import { useTranslation } from "react-i18next"
import { useStore } from "zustand"

import type { RouteItem } from "@common/routes/types"
import type { ContextStoreProp } from "@future/context/store"
import { govUrl } from "@future/target/domain"

import DaoIcon from "./header/icons/DaoIcon"
import EarnIcon from "./header/icons/EarnIcon"
import HistoryIcon from "./header/icons/HistoryIcon"
import MarketsIcon from "./header/icons/MarketsIcon"
import StatsIcon from "./header/icons/StatsIcon"
import TradeIcon from "./header/icons/TradeIcon"

export enum RoutePath {
  markets = "markets",
  trade = "trade",
  earn = "earn",
  history = "history",
  learn = "learn",
  leaderboard = "leaderboard",
  stats = "stats",
  dao = "dao",
  referral = "referral",
}

export const useRoutes = (props: ContextStoreProp<"initial">) => {
  const { t } = useTranslation("perpsCommon")

  const targetSlug = useStore(
    props.contextStore,
    (state) => state.route.targetSlug,
  )
  const marketSlug = useStore(
    props.contextStore,
    (state) => state.route.marketSlug ?? state.route.fallbackMarketSlug,
  )

  const list: RouteItem[] = [
    {
      id: RoutePath.trade,
      title: t("nav.trade"),
      path: {
        route: "trade",
        targetSlug,
        marketSlug,
      },
      Icon: TradeIcon,
    },
    {
      id: RoutePath.markets,
      title: t("nav.markets"),
      path: {
        route: "markets",
        targetSlug,
      },
      Icon: MarketsIcon,
    },
    {
      id: RoutePath.earn,
      title: t("nav.earn"),
      path: {
        route: "earnList",
        targetSlug,
      },
      Icon: EarnIcon,
    },
    {
      id: RoutePath.history,
      title: t("nav.history"),
      path: {
        route: "history",
        targetSlug,
      },
      Icon: HistoryIcon,
    },
    {
      id: RoutePath.stats,
      title: t("nav.stats"),
      path: { route: "stats" },
      Icon: StatsIcon,
    },
    {
      id: RoutePath.dao,
      title: t("nav.dao"),
      path: govUrl,
      Icon: DaoIcon,
    },
  ]

  return {
    list,
  }
}
