import { useCallback, useEffect, useRef } from "react"

import { localStorageStateBuilder } from "@common/storage/localStorage"
import { commitHashShort } from "@future/utils/build"

export interface UseCanUpdateVersionProps {
  updateConfigCommitHashShort: string | undefined
}

export const useCanUpdateVersion = (props: UseCanUpdateVersionProps) => {
  const canUpdate = props.updateConfigCommitHashShort
    ? props.updateConfigCommitHashShort !== commitHashShortStorage.get()
    : false

  const updateStoredVersion = useCallback(() => {
    if (props.updateConfigCommitHashShort) {
      commitHashShortStorage.set(props.updateConfigCommitHashShort)
    }
  }, [props.updateConfigCommitHashShort])

  return {
    canUpdate,
    updateStoredVersion,
  }
}

export const useUpdateVersionOnAppLoad = (
  ready: boolean,
  updateStoredVersion: () => void,
) => {
  const updateStoredVersionRef = useRef(updateStoredVersion)
  const finishedRef = useRef(false)

  useEffect(() => {
    updateStoredVersionRef.current = updateStoredVersion
  }, [updateStoredVersion])

  useEffect(() => {
    if (ready && !finishedRef.current) {
      updateStoredVersionRef.current()
      finishedRef.current = true
    }
  }, [ready])
}

const commitHashShortStorage = localStorageStateBuilder(
  "LevanaUpdateCommitHash",
  commitHashShort,
)
