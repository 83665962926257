import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const FaucetIcon = (props: SvgIconProps) => {
  const Svg = () => (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5007 4.00476H13.5007H11.5007H8.50073V6.00476H11.5007V8.08772C9.33238 8.4515 7.55839 9.9783 6.84214 12.0048H4.50073C2.29159 12.0048 0.500732 13.7956 0.500732 16.0048V18.5048H4.50073V16.0048H6.84214C7.66582 18.3351 9.8883 20.0048 12.5007 20.0048C15.1132 20.0048 17.3356 18.3351 18.1593 16.0048H23.5007V12.0048H18.1593C17.4431 9.9783 15.6691 8.4515 13.5007 8.08772V6.00476H16.5007V4.00476ZM15.9656 12.0048C15.274 10.8092 13.9813 10.0048 12.5007 10.0048C11.0202 10.0048 9.72748 10.8092 9.03586 12.0048C8.69552 12.5931 8.50073 13.2762 8.50073 14.0048C8.50073 14.7333 8.69552 15.4164 9.03586 16.0048C9.72748 17.2004 11.0202 18.0048 12.5007 18.0048C13.9813 18.0048 15.274 17.2004 15.9656 16.0048C16.3059 15.4164 16.5007 14.7333 16.5007 14.0048C16.5007 13.2762 16.3059 12.5931 15.9656 12.0048Z"
    />
  )

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <Svg />
    </SvgIcon>
  )
}

export default FaucetIcon
