var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { GrazProvider, subscribeAccounts as grazSubscribeAccounts, configureGraz, } from "graz";
import { useEffect, useRef } from "react";
import { useChainStore, useAccountStore } from "../shared/store";
import { WalletType } from "../shared/wallet";
import { queryClient } from "../shared/queryClient";
import { getAccount, useAccount, useConnect } from "./account";
import { setInjectiveWalletStrategy } from "../injective/wallet";
import { isInjectiveChainId } from "../injective/chain";
import { getWallet } from "../wallet";
import { decideChainAction } from "./chain";
import { injectiveAccount } from "../injective/account";
import { grazAccount } from "../graz/account";
export const ChainProvider = (props) => {
    useEffect(() => {
        useChainStore.setState((prev) => {
            var _a, _b;
            return ({
                chains: (_a = props.grazOptions.chains) !== null && _a !== void 0 ? _a : prev.chains,
                chainsConfig: (_b = props.grazOptions.chainsConfig) !== null && _b !== void 0 ? _b : prev.chainsConfig,
            });
        });
    }, [props.grazOptions]);
    return (_jsxs(GrazProvider, { grazOptions: props.grazOptions, client: queryClient, children: [(props.onConnected || props.onDisconnected || props.onConnectError) && (_jsx(ConnectCallback, Object.assign({}, props))), _jsx(ChainDependantComponents, {}), props.children] }));
};
export const setChainInfo = (args) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const chainId = (_a = args.chainId) !== null && _a !== void 0 ? _a : useChainStore.getState().chainId;
    if (args.rpc) {
        const chains = [...useChainStore.getState().chains];
        const index = chains.findIndex((chain) => chain.chainId === chainId);
        if (index > -1 && chains[index].rpc !== args.rpc) {
            chains[index] = Object.assign(Object.assign({}, chains[index]), { rpc: args.rpc });
            useChainStore.setState({ chains });
            configureGraz({ chains });
        }
    }
    if (args.chainId) {
        yield setInjectiveWalletStrategy(args.chainId);
        // Must be set last
        useChainStore.setState({ chainId: args.chainId });
    }
});
const ConnectCallback = (props) => {
    const propsRef = useRef(props);
    useEffect(() => {
        propsRef.current = props;
    }, [props]);
    useEffect(() => {
        return useAccountStore.subscribe((state, previousState) => {
            if (propsRef.current.onConnected &&
                state.status === "connected" &&
                previousState.status !== "connected") {
                const account = getAccount();
                if (account) {
                    propsRef.current.onConnected({
                        account,
                        walletType: state.walletType,
                    });
                }
            }
            if (propsRef.current.onDisconnected &&
                state.status === "disconnected" &&
                previousState.status === "connected") {
                propsRef.current.onDisconnected();
            }
            if (propsRef.current.onConnectError &&
                state.error &&
                state.error !== previousState.error) {
                propsRef.current.onConnectError(state.error);
            }
        });
    }, []);
    return null;
};
/**
 * The `chainId` might be an empty string. In this case, related components
 * should wait to load until there's a valid value.
 */
const ChainDependantComponents = () => {
    const chainId = useChainStore((state) => state.chainId);
    if (chainId.length === 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(InitialConnectStatus, {}), _jsx(ChangeAccountAutoConnect, {}), _jsx(CrossChainAutoConnect, {})] }));
};
const InitialConnectStatus = () => {
    useEffect(() => {
        const account = decideChainAction({
            cosmos: grazAccount,
            injective: injectiveAccount,
        })();
        if (account) {
            useAccountStore.setState({ status: "connected" });
        }
    }, []);
    return null;
};
const ChangeAccountAutoConnect = () => {
    const chainId = useChainStore((state) => state.chainId);
    const walletType = useAccountStore((state) => state.walletType);
    const { connect } = useConnect();
    useEffect(() => {
        var _a;
        if (walletType === WalletType.viewing) {
            useAccountStore.setState({ status: "connected" });
            return;
        }
        const provider = getWallet(walletType).provider();
        // This design causes the app to auto connect if the app is disconnected and
        // the user changes the default wallet account, which is Keplr. This can be
        // fixed by allowing `useAccountStore.walletType` to be undefined.
        return (_a = provider.subscription) === null || _a === void 0 ? void 0 : _a.call(provider, () => {
            const { chainId } = useChainStore.getState();
            if (isInjectiveChainId(chainId)) {
                connect({ walletType });
            }
            else {
                // TODO: handle account change connecting instead of Graz.
                // Since Graz handles this, an error exists while being connected to the
                // viewing wallet. Reproduce steps:
                // 1. Connect to a viewing wallet
                // 2. Open the wallet extension and switch accounts
                // 3. The viewing wallet state will remain but with the changed address
                // Graz is handling the auto connect, so update the status independently
                useAccountStore.setState({ status: "connecting" });
            }
        });
    }, [walletType, connect]);
    useEffect(() => {
        if (isInjectiveChainId(chainId)) {
            return;
        }
        // Graz is handling the auto connect, so update the status independently
        return grazSubscribeAccounts((accounts) => {
            if (accounts) {
                useAccountStore.setState({ status: "connected" });
            }
        });
    }, [chainId]);
    return null;
};
const CrossChainAutoConnect = () => {
    const { data: account } = useAccount();
    const { connect } = useConnect();
    const previousChainAccountRef = useRef({
        chainId: useChainStore.getState().chainId,
        account,
    });
    useEffect(() => {
        const { chainId } = useChainStore.getState();
        const { walletType } = useAccountStore.getState();
        if (!account &&
            previousChainAccountRef.current.account &&
            chainId !== previousChainAccountRef.current.chainId &&
            walletType !== WalletType.viewing) {
            connect({ walletType });
        }
        previousChainAccountRef.current = { account, chainId };
    }, [account, connect]);
    return null;
};
