type ReferralHandle = string
type ReferralAddress = string

export class ReferralMap {
  private addressToHandle: Record<ReferralAddress, ReferralHandle> = {}
  private caseInsensitiveHandleToAddresses: Record<string, ReferralAddress[]> =
    {}

  constructor(referrals: Record<ReferralHandle, ReferralAddress[]>) {
    for (const handle in referrals) {
      const normalizedHandle = this.normalizeHandle(handle)
      const addresses = referrals[handle]

      for (const address of addresses) {
        this.addressToHandle[address] = handle
      }

      this.caseInsensitiveHandleToAddresses[normalizedHandle] = addresses
    }
  }

  private normalizeHandle(handle: ReferralHandle) {
    return handle.toLowerCase()
  }

  getHandleToAddress(
    handle: ReferralHandle,
    addressPrefix: string,
  ): ReferralAddress | undefined {
    const normalizedHandle = this.normalizeHandle(handle)
    const addresses = this.caseInsensitiveHandleToAddresses[normalizedHandle]

    if (!addresses) {
      return
    }

    for (const address of addresses) {
      if (address.startsWith(addressPrefix)) {
        return address
      }
    }
  }

  getAddressToHandle(address: ReferralAddress): ReferralHandle | undefined {
    return this.addressToHandle[address]
  }
}
