import { useEffect } from "react"

import { type Breadcrumb, Report } from "@future/libs/error/report"

const networkStatusBreadcrumb = (message: string): Breadcrumb => ({
  level: "debug",
  type: "http",
  category: "online",
  message,
})

const RootSetup = () => {
  useEffect(() => {
    // Investigating potential errors due to offline

    const offline = () =>
      Report.addBreadcrumb(networkStatusBreadcrumb("Network status is offline"))

    const online = () =>
      Report.addBreadcrumb(networkStatusBreadcrumb("Network status is online"))

    window.addEventListener("offline", offline)
    window.addEventListener("online", online)

    return () => {
      window.removeEventListener("offline", offline)
      window.removeEventListener("online", online)
    }
  }, [])

  return null
}

export default RootSetup
