import { Collateral, Percentage } from "@future/numerics"
import { Amount } from "@future/numerics/amount"
import type { ChangeValues } from "@future/utils/typeScript"
import type { MarketId } from "@perps/sdk/types"

export type EarnMarketMap = Map<MarketId, EarnMarket>

export interface EarnMarketResp {
  apr: AprResp
  apr7: AprResp
  roi: AprResp
  roi7: AprResp
  roi30: AprResp
  sharpe: {
    annualized_roi30_rate: AprResp
    annualized_volatility: AprResp
  }
  withdrawn_collateral: string | null
  charts: EarnChartResp & {
    last_24_hours: EarnChartDailyResp
  }
}

interface EarnChartRespBase {
  delta_neutralities: {
    ratio: string
    date: string
  }[]
  pool_sizes: {
    locked_usd: string
    unlocked_usd: string
    date: string
  }[]
  utilization_ratios: {
    ratio: string
    date: string
  }[]
  collateral_per_token: {
    value: string
    date: string
  }[]
}

interface EarnChartResp extends EarnChartRespBase {
  aprs: AprChartResp[]
  aprs7: AprChartResp[]
}

export interface EarnChartAnnualResp extends EarnChartRespBase {
  daily_aprs: AprChartResp[]
  weekly_aprs: AprChartResp[]
}

interface EarnChartDailyResp {
  delta_neutralities: (EarnChartDailyTimestamp & {
    ratio: string
  })[]
  pool_sizes: (EarnChartDailyTimestamp & {
    locked_usd: string
    unlocked_usd: string
  })[]
  utilization_ratios: (EarnChartDailyTimestamp & {
    ratio: string
  })[]
  collateral_per_token: (EarnChartDailyTimestamp & {
    value: string
  })[]
  aprs: (EarnChartDailyTimestamp & AprResp)[]
  aprs7: (EarnChartDailyTimestamp & AprResp)[]
}

interface EarnChartDailyTimestamp {
  timestamp: string
}

interface AprResp {
  lp: string
  xlp: string
}

interface AprChartResp extends AprResp {
  date: string
}

export interface EarnMarket {
  aprDaily: Apr
  aprWeekly: Apr
  roiDaily: Apr
  roiWeekly: Apr
  roiMonthly: Apr
  sharpe: {
    annualizedRoiMonthlyRate: ChangeValues<Apr, Amount>
    annualizedVolatility: ChangeValues<Apr, Amount>
  }
  withdrawnCollateral: Collateral
  chartDaily: EarnChart
  chartMonthly: EarnChart
}

export interface EarnChart {
  aprDaily: AprChart[]
  aprWeekly: AprChart[]
  deltaNeutrality: {
    twa: Amount
    date: string
  }[]
  poolSize: {
    locked: Amount
    unlocked: Amount
    date: string
  }[]
  utilizationRatio: {
    twa: Amount
    date: string
  }[]
  lpTokenValue: {
    tokenValue: Amount
    date: string
  }[]
}

export interface Apr {
  lp: Percentage
  xlp: Percentage
}

export interface AprChart extends Apr {
  date: string
}

export const earnMarketFromResp = (
  earnMarketResp: EarnMarketResp,
): EarnMarket => {
  return {
    aprDaily: {
      lp: new Percentage(earnMarketResp.apr.lp),
      xlp: new Percentage(earnMarketResp.apr.xlp),
    },
    aprWeekly: {
      lp: new Percentage(earnMarketResp.apr7.lp),
      xlp: new Percentage(earnMarketResp.apr7.xlp),
    },
    roiDaily: {
      lp: new Percentage(earnMarketResp.roi.lp),
      xlp: new Percentage(earnMarketResp.roi.xlp),
    },
    roiWeekly: {
      lp: new Percentage(earnMarketResp.roi7.lp),
      xlp: new Percentage(earnMarketResp.roi7.xlp),
    },
    roiMonthly: {
      lp: new Percentage(earnMarketResp.roi30.lp),
      xlp: new Percentage(earnMarketResp.roi30.xlp),
    },
    sharpe: {
      annualizedRoiMonthlyRate: {
        lp: new Amount(earnMarketResp.sharpe.annualized_roi30_rate.lp),
        xlp: new Amount(earnMarketResp.sharpe.annualized_roi30_rate.xlp),
      },
      annualizedVolatility: {
        lp: new Amount(earnMarketResp.sharpe.annualized_volatility.lp),
        xlp: new Amount(earnMarketResp.sharpe.annualized_volatility.xlp),
      },
    },
    withdrawnCollateral: new Collateral(
      earnMarketResp.withdrawn_collateral ?? 0,
    ),
    chartDaily: earnChartDailyFromResp(earnMarketResp.charts.last_24_hours),
    chartMonthly: earnChartFromResp(earnMarketResp.charts),
  }
}

export const earnChartFromResp = (
  earnChartResp: EarnChartResp | EarnChartAnnualResp,
): EarnChart => {
  const aprDaily =
    "daily_aprs" in earnChartResp
      ? earnChartResp.daily_aprs
      : earnChartResp.aprs
  const aprWeekly =
    "weekly_aprs" in earnChartResp
      ? earnChartResp.weekly_aprs
      : earnChartResp.aprs7

  return {
    aprDaily: aprDaily.map(({ lp, xlp, date }) => ({
      lp: new Percentage(lp),
      xlp: new Percentage(xlp),
      date,
    })),
    aprWeekly: aprWeekly.map(({ lp, xlp, date }) => ({
      lp: new Percentage(lp),
      xlp: new Percentage(xlp),
      date,
    })),
    deltaNeutrality: earnChartResp.delta_neutralities.map(
      ({ ratio, date }) => ({
        twa: new Amount(ratio),
        date,
      }),
    ),
    poolSize: earnChartResp.pool_sizes.map(
      ({ locked_usd, unlocked_usd, date }) => ({
        locked: new Amount(locked_usd),
        unlocked: new Amount(unlocked_usd),
        date,
      }),
    ),
    utilizationRatio: earnChartResp.utilization_ratios.map(
      ({ ratio, date }) => ({
        twa: new Amount(ratio),
        date,
      }),
    ),
    lpTokenValue: earnChartResp.collateral_per_token.map(({ value, date }) => ({
      tokenValue: new Amount(value),
      date,
    })),
  }
}

const earnChartDailyFromResp = (
  earnChartResp: EarnChartDailyResp,
): EarnChart => {
  return {
    aprDaily: earnChartResp.aprs.map(({ lp, xlp, timestamp }) => ({
      lp: new Percentage(lp),
      xlp: new Percentage(xlp),
      date: timestamp,
    })),
    aprWeekly: earnChartResp.aprs7.map(({ lp, xlp, timestamp }) => ({
      lp: new Percentage(lp),
      xlp: new Percentage(xlp),
      date: timestamp,
    })),
    deltaNeutrality: earnChartResp.delta_neutralities.map(
      ({ ratio, timestamp }) => ({
        twa: new Amount(ratio),
        date: timestamp,
      }),
    ),
    poolSize: earnChartResp.pool_sizes.map(
      ({ locked_usd, unlocked_usd, timestamp }) => ({
        locked: new Amount(locked_usd),
        unlocked: new Amount(unlocked_usd),
        date: timestamp,
      }),
    ),
    utilizationRatio: earnChartResp.utilization_ratios.map(
      ({ ratio, timestamp }) => ({
        twa: new Amount(ratio),
        date: timestamp,
      }),
    ),
    lpTokenValue: earnChartResp.collateral_per_token.map(
      ({ value, timestamp }) => ({
        tokenValue: new Amount(value),
        date: timestamp,
      }),
    ),
  }
}

export const emptyEarnChart: EarnChart = {
  aprDaily: [],
  aprWeekly: [],
  deltaNeutrality: [],
  poolSize: [],
  utilizationRatio: [],
  lpTokenValue: [],
}
