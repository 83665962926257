import {
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from "@sentry/react"
import React from "react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

import { AppError } from "./libs/error/AppError"

const dynamicallyLimitedErrorMessages = new Set<string>()

export const initSentry = () => {
  init({
    environment: process.env.DEPLOY_STAGE,
    dsn: process.env.SENTRY_DSN,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.2,
    normalizeDepth: 6,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
      }),
    ],
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category === "fetch") {
        if (hint?.response.ok) {
          // Block unhelpful breadcrumbs to preserve relevant data from the limit, `maxBreadcrumbs`
          return null
        }

        const item = hint?.input.find(
          (item: Record<string, unknown>) =>
            typeof item === "object" && "body" in item,
        )

        if (item && breadcrumb.data) {
          // TODO: the below code might resolve this - https://phobosfinance.atlassian.net/browse/PERP-1145
          try {
            const body = JSON.parse(item.body)

            if (body.params?.data) {
              body.params.data = hexToString(body.params.data)
            }

            breadcrumb.data.body = body
          } catch {
            breadcrumb.data.body = item.body
          }
        }
      } else if (breadcrumb.category === "xhr") {
        if (hint?.xhr.status === 200) {
          // Block unhelpful breadcrumbs to preserve relevant data from the limit, `maxBreadcrumbs`
          return null
        }
      } else if (breadcrumb.category === "ui.click") {
        const ariaLabel = (
          target: Element,
          ancestor: number,
        ): string | undefined => {
          if (target.ariaLabel) {
            if (ancestor === 0) {
              return target.ariaLabel
            } else {
              // The higher the `ancestor`, the less likely its related
              return `${target.ariaLabel}; in ancestry ${ancestor} levels up`
            }
          } else if (target.parentElement) {
            return ariaLabel(target.parentElement, ancestor + 1)
          }
        }

        const message = ariaLabel(hint?.event.target, 0)

        if (message) {
          breadcrumb.message = message
        }
      } else if (breadcrumb.category === "console") {
        // Remove `breadcrumb.message` since it's redundant in `breadcrumb.data`
        breadcrumb.message = undefined
      }

      return breadcrumb
    },
    beforeSend: (event, hint) => {
      if (hint.originalException instanceof AppError) {
        const error = hint.originalException
        const rootCause = error.rootError.cause

        // These errors are not shown to the user and are safe to rate limit
        if (error.disablePresentation && rootCause instanceof Error) {
          if (dynamicallyLimitedErrorMessages.has(rootCause.message)) {
            return null
          } else {
            dynamicallyLimitedErrorMessages.add(rootCause.message)
            return event
          }
        }
      }

      return event
    },

    // https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      "Script error.",
      // Avast extension error
      "_avast_submit",
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  })
}

const hexToString = (hex: string) => {
  const match = hex.match(/[0-9A-Fa-f]+/)

  if (match && match[0].length === hex.length) {
    let string = ""

    for (let i = 0; i < hex.length; i += 2) {
      string += String.fromCharCode(Number.parseInt(hex.substr(i, 2), 16))
    }

    return string
  }

  return hex
}
