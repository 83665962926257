import List from "@mui/joy/List"
import ListDivider from "@mui/joy/ListDivider"
import ListItem from "@mui/joy/ListItem"
import ListItemButton, {
  type ListItemButtonProps,
} from "@mui/joy/ListItemButton"
import ListItemContent from "@mui/joy/ListItemContent"
import ListItemDecorator from "@mui/joy/ListItemDecorator"
import Stack from "@mui/joy/Stack"
import type { SvgIconProps } from "@mui/joy/SvgIcon"
import Typography from "@mui/joy/Typography"
import type { PropsWithChildren } from "react"

export interface HeaderMenuListProps {
  items: HeaderMenuListItem[]
  onClose: () => void
}

type OnboardWrapperType = (props: PropsWithChildren) => JSX.Element

export interface HeaderMenuListItem {
  title: string
  subtitle?: string
  Icon: React.JSXElementConstructor<SvgIconProps>
  OnboardWrapper?: OnboardWrapperType
  action?: () => void
  actionComponent?: HeaderMenuActionComponent
}

export type HeaderMenuActionProps = Omit<ListItemButtonProps, "ref">

export type HeaderMenuActionComponent = React.ForwardRefExoticComponent<
  HeaderMenuActionProps & React.RefAttributes<HTMLDivElement>
>

const HeaderMenuList = (props: HeaderMenuListProps) => {
  const items = props.items.map((item) => {
    const { action } = item
    const modifiedItem = { ...item }

    if (action) {
      modifiedItem.action = () => {
        action()
        props.onClose()
      }
    }

    return modifiedItem
  })

  return (
    <Stack component={List} divider={<ListDivider inset="gutter" />}>
      {items.map((item) => (
        <WithOnboardWrapper
          OnboardWrapper={item.OnboardWrapper}
          key={item.title}
        >
          <ListItem>
            {item.actionComponent ? (
              <ListItemButton
                slots={{ root: item.actionComponent }}
                onClick={props.onClose}
                aria-label={item.title.toLowerCase()}
              >
                <ItemContent item={item} />
              </ListItemButton>
            ) : item.action ? (
              <ListItemButton
                onClick={item.action}
                aria-label={item.title.toLowerCase()}
              >
                <ItemContent item={item} />
              </ListItemButton>
            ) : (
              <ItemContent item={item} />
            )}
          </ListItem>
        </WithOnboardWrapper>
      ))}
    </Stack>
  )
}

const ItemContent = (props: { item: HeaderMenuListItem }) => (
  <>
    <ListItemDecorator>
      <props.item.Icon fontSize="xl2" />
    </ListItemDecorator>
    <ListItemContent>
      <Typography level="body-md">{props.item.title}</Typography>
      {props.item.subtitle && (
        <Typography level="body-sm">{props.item.subtitle}</Typography>
      )}
    </ListItemContent>
  </>
)

const WithOnboardWrapper = ({
  children,
  OnboardWrapper,
}: PropsWithChildren<{ OnboardWrapper?: OnboardWrapperType }>) => {
  if (OnboardWrapper) {
    return <OnboardWrapper>{children}</OnboardWrapper>
  } else {
    return children
  }
}

export default HeaderMenuList
