import { useTranslation } from "react-i18next"
import { useAccount } from "@levana/chain/account"
import { useModal } from "@levana/utils/modal"

import FaucetIcon from "@common/icons/FaucetIcon"
import type { TargetConfigCw20 } from "@future/target/config"
import type CosmosClient from "@perps/sdk/client/CosmosClient"
import type { TapEligibleResponse } from "@perps/sdk/types"
import { track } from "@perps/analytics/track"
import { Event } from "@perps/analytics/events"
import {
  isStandardContextStore,
  type ContextStoreProp,
} from "@future/context/store"

import type { HeaderMenuListItem } from "./HeaderMenuList"
import HeaderFaucetModal, {
  type HeaderFaucetModalProps,
} from "./HeaderFaucetModal"

/**
 * Throws an exception if anything fails
 */
const tryCanTapFaucet = async (props: {
  contractClient: CosmosClient
  faucetAddress?: string
  cw20s: TargetConfigCw20[]
  walletAddress: string
}): Promise<string | undefined> => {
  const { contractClient, faucetAddress, cw20s, walletAddress } = props
  if (faucetAddress === undefined) {
    throw new Error("No faucet address found")
  }

  const cw20Addresses = cw20s.map((cw20) => cw20.address)
  const abortController = new AbortController()

  const canTapResponse: TapEligibleResponse =
    await contractClient.queryCanTapFaucet({
      faucetAddress,
      walletAddress,
      cw20Addresses,
      abortSignal: abortController.signal,
    })

  return "ineligible" in canTapResponse
    ? canTapResponse.ineligible.message
    : undefined
}

export type UseHeaderFaucetProps = Partial<
  Pick<HeaderFaucetModalProps, "marketId">
> &
  ContextStoreProp<"initial">

export const useHeaderFaucet = (props: UseHeaderFaucetProps) => {
  const { marketId, contextStore } = props
  const { t } = useTranslation("faucet")
  const { present } = useModal()

  const { data: account } = useAccount()

  const items: HeaderMenuListItem[] = []

  if (isStandardContextStore(contextStore)) {
    const storeState = contextStore.getState()

    if (account && storeState.chain.config.network === "testnet") {
      const handleOpen = async () => {
        track(Event.initiateTapFaucet())
        // If we encounter any errors when checking, fall back to just opening up
        // the faucet captcha. The user will end up getting an error message later
        // if they aren't eligible.
        const errorMessage = await (async () => {
          try {
            const ineligibleReason = await tryCanTapFaucet({
              contractClient: storeState.chain.client,
              faucetAddress: storeState.chain.faucet,
              walletAddress: account.bech32Address,
              cw20s: storeState.chain.cw20s,
            })

            return ineligibleReason
          } catch (e) {
            console.error(e)
            return undefined
          }
        })()

        present(
          <HeaderFaucetModal
            errorMessage={errorMessage}
            contextStore={contextStore}
            marketId={marketId}
          />,
        )
      }

      items.push({
        title: t("menu.faucet"),
        Icon: FaucetIcon,
        action: handleOpen,
      })
    }
  }

  return {
    items,
  }
}
