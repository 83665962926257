import { WalletErrorType, getWalletErrorType } from "@levana/chain/error"

import type { AppErrorKeyOptions } from "@future/libs/error/AppError"

export const walletErrorToKeyOptions = (
  message: string,
): AppErrorKeyOptions | null => {
  switch (getWalletErrorType(message)) {
    case WalletErrorType.insufficientFunds:
      return {
        key: "wallet.insufficientFunds",
        presentable: true,
        level: "suppress",
      }
    case WalletErrorType.invalidCurrencies:
      return {
        key: "wallet.invalidCurrencies",
        presentable: true,
        level: "fatal",
      }
    case WalletErrorType.noAccount:
      return {
        key: "wallet.noAccount",
        presentable: true,
        level: "suppress",
      }
    case WalletErrorType.outOfSyncAccount:
      return {
        key: "wallet.outOfSyncAccount",
        presentable: true,
        level: "suppress",
      }
    case WalletErrorType.rejected:
      return {
        key: "wallet.rejected",
        disablePresentation: true,
        level: "suppress",
      }
    case WalletErrorType.unknown:
      return null
  }
}
