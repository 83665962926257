import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const InstallIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00008 10.5858V2H11.0001V10.5858L12.293 9.29289L13.7072 10.7071L10.0001 14.4142L6.29297 10.7071L7.70718 9.29289L9.00008 10.5858Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7H16V15C16 15.5523 15.5523 16 15 16H5C4.44772 16 4 15.5523 4 15V7H6V5H4C2.89543 5 2 5.89543 2 7V15C2 16.6569 3.34315 18 5 18H15C16.6569 18 18 16.6569 18 15V7C18 5.89543 17.1046 5 16 5H14V7Z"
      />
    </SvgIcon>
  )
}

export default InstallIcon
