import { Amount } from "@future/numerics/amount"
import { Collateral, Leverage, Usd } from "@future/numerics"
import type {
  MarketInfoResponse,
  SpotPriceConfig,
  StatusResp,
  Token,
} from "@perps/sdk/types"
import { AppError } from "@future/libs/error/AppError"

import type { MarketDisplayConfig } from "./constants"

export interface MarketConfig extends MarketDisplayConfig {
  /**
   * Address of the LP liquidity token
   */
  liquidityTokenLpAddress: string
  /**
   * Address of the xLP liquidity token
   */
  liquidityTokenXlpAddress: string
  /**
   * Address of the market
   */
  marketAddress: string
  /**
   * Address of the position token
   */
  positionTokenAddress: string
  /**
   * Token type for collateral
   */
  collateralToken: Token

  /// from marketConfig
  /**
   * The maximum annualized rate for borrow fee payments
   */
  borrowFeeRateMaxAnnualized: Amount
  /**
   * The minimum annualized rate for borrow fee payments
   */
  borrowFeeRateMinAnnualized: Amount
  /**
   * Borrow fee sensitivity parameter.
   *
   * See [section 5.5 of the whitepaper](https://www.notion.so/levana-protocol/Levana-Well-funded-Perpetuals-Whitepaper-9805a6eba56d429b839f5551dbb65c40#295f9f2689e74ccab16ca28177eb32cb).
   */
  borrowFeeSensitivity: Amount
  /**
   * Needed to ensure financial model is balanced
   *
   * Must be at most 1 less than the [Config::max_leverage]
   */
  carryLeverage: Amount
  /**
   * default number of crank executions to do when none specified
   */
  crankExecs: number
  /**
   * The crank fee to be paid into the system, in collateral
   */
  crankFeeCharged: Usd
  /**
   * The crank fee to be sent to crankers, in collateral
   */
  crankFeeReward: Usd
  /**
   * Delta neutrality fee cap parameter, given as a percentage
   */
  deltaNeutralityFeeCap: Amount
  /**
   * Delta neutrality fee sensitivity parameter.
   */
  deltaNeutralityFeeSensitivity: Amount
  /**
   * Proportion of delta neutrality inflows that are sent to the protocol.
   */
  deltaNeutralityFeeTax: Amount
  /**
   * Disable the ability to proxy CW721 execution messages for positions. Even if this is true, queries will still work as usual.
   */
  disablePositionNftExec?: boolean
  /**
   * The maximum annualized rate for a funding payment
   */
  fundingRateMaxAnnualized: Amount
  /**
   * Impacts how much the funding rate changes in response to net notional changes.
   */
  fundingRateSensitivity: Amount
  /**
   * The liquifunding delay fuzz factor, in seconds.
   *
   * Up to how many seconds will we perform a liquifunding early. This will be part of a semi-randomly generated value and will allow us to schedule liquifundings arbitrarily to smooth out spikes in traffic.
   */
  liquifundingDelayFuzzSeconds?: number
  /**
   * Delay between liquifundings, in seconds
   */
  liquifundingDelaySeconds: number
  /**
   * The maximum allowed leverage when opening a position
   */
  maxLeverage: Leverage
  /**
   * The liquidation margin ratio of the notional size set aside to settle price exposure over liquidation price.
   */
  exposureMarginRatio: Amount
  /**
   * The maximum amount of liquidity that can be deposited into the market.
   */
  maxLiquidity?:
    | "unlimited"
    | {
        /**
         * Amount in USD
         */
        usd: Amount
      }
  /**
   * Maximum multiplier for xLP versus LP borrow fee shares.
   *
   * For example, if this number is 5, then as liquidity in the protocol approaches 100% in LP and 0% in xLP, any xLP token will receive 5x the rewards of an LP token.
   */
  maxXlpRewardsMultiplier: Amount
  /**
   * Minimum counterpoint to [Config::max_xlp_rewards_multiplier]
   */
  minXlpRewardsMultiplier: Amount
  /**
   * Minimum deposit collateral, given in USD
   */
  minimumDepositUsd: Usd
  /**
   * Do not emit events (default is false, events *will* be emitted)
   */
  muteEvents: boolean
  /**
   * How old must the latest price update be to trigger the protocol to lock?
   */
  priceUpdateTooOldSeconds?: number
  /**
   * The percentage of fees that are taken for the protocol
   */
  protocolTax: Amount
  /**
   * How far behind must the position liquifunding process be to consider the protocol stale?
   */
  stalenessSeconds?: number
  /**
   * Target utilization ratio liquidity, given as a ratio. (Must be between 0 and 1).
   */
  targetUtilization: Amount
  /**
   * The fee to open a position, as a percentage of the counter-side collateral
   */
  tradingFeeCounterCollateral: Amount
  /**
   * The fee to open a position, as a percentage of the notional size
   */
  tradingFeeNotionalSize: Amount
  /**
   * How many positions can sit in "unpend" before we disable new open/update positions for congestion.
   */
  unpendLimit?: number
  /**
   * How long it takes to unstake xLP tokens into LP tokens, in seconds
   */
  unstakePeriodSeconds: number
  liquidityCooldownSeconds?: number

  spotPrice: SpotPriceConfig

  /**
   * Is the market currently in a wind down or kill switch mode?
   */
  inWinddown: boolean
  /**
   * The crank surcharge charged for every 10 items in the deferred execution queue.
   *
   * This is intended to create backpressure in times of high congestion.
   *
   * For every 10 items in the deferred execution queue, this amount is added to the crank fee charged on performing a deferred execution message.
   *
   * This is only charged while adding new items to the queue, not when performing ongoing tasks like liquifunding or liquidations.
   */
  crankFeeSurcharge: Usd
  /**
   * Just for historical reasons/migrations
   */
  limitOrderFee?: Collateral
}

export const marketConfigFromResp = (
  display: MarketDisplayConfig,
  status: StatusResp,
  info: MarketInfoResponse,
  spotPrice: SpotPriceConfig,
  inWinddown: boolean,
): MarketConfig => {
  const contract = status.config

  if (!contract.crank_fee_surcharge) {
    throw AppError.fromText("crank_fee_surcharge has no value")
  }

  return {
    ...display,
    borrowFeeRateMaxAnnualized: new Amount(
      contract.borrow_fee_rate_max_annualized,
    ),
    borrowFeeRateMinAnnualized: new Amount(
      contract.borrow_fee_rate_min_annualized,
    ),
    borrowFeeSensitivity: new Amount(contract.borrow_fee_sensitivity),
    carryLeverage: new Amount(contract.carry_leverage),
    crankExecs: contract.crank_execs,
    crankFeeCharged: new Usd(contract.crank_fee_charged),
    crankFeeReward: new Usd(contract.crank_fee_reward),
    deltaNeutralityFeeCap: new Amount(contract.delta_neutrality_fee_cap),
    deltaNeutralityFeeSensitivity: new Amount(
      contract.delta_neutrality_fee_sensitivity,
    ),
    deltaNeutralityFeeTax: new Amount(contract.delta_neutrality_fee_tax),
    disablePositionNftExec: contract.disable_position_nft_exec,
    fundingRateMaxAnnualized: new Amount(contract.funding_rate_max_annualized),
    fundingRateSensitivity: new Amount(contract.funding_rate_sensitivity),
    liquifundingDelayFuzzSeconds: contract.liquifunding_delay_fuzz_seconds,
    liquifundingDelaySeconds: contract.liquifunding_delay_seconds,
    maxLeverage: new Leverage(contract.max_leverage),
    exposureMarginRatio: new Amount(contract.exposure_margin_ratio ?? 0),
    maxLiquidity:
      contract.max_liquidity !== undefined
        ? "usd" in contract.max_liquidity
          ? { usd: new Amount(contract.max_liquidity.usd.amount) }
          : "unlimited"
        : undefined,
    maxXlpRewardsMultiplier: new Amount(contract.max_xlp_rewards_multiplier),
    minXlpRewardsMultiplier: new Amount(contract.min_xlp_rewards_multiplier),
    minimumDepositUsd: new Usd(contract.minimum_deposit_usd),
    muteEvents: contract.mute_events,
    priceUpdateTooOldSeconds:
      contract.price_update_too_old_seconds ?? undefined,
    protocolTax: new Amount(contract.protocol_tax),
    stalenessSeconds: contract.staleness_seconds ?? undefined,
    targetUtilization: new Amount(contract.target_utilization),
    tradingFeeCounterCollateral: new Amount(
      contract.trading_fee_counter_collateral,
    ),
    tradingFeeNotionalSize: new Amount(contract.trading_fee_notional_size),
    unpendLimit: contract.unpend_limit ?? undefined,
    unstakePeriodSeconds: contract.unstake_period_seconds,
    liquidityCooldownSeconds: contract.liquidity_cooldown_seconds,
    crankFeeSurcharge: new Usd(contract.crank_fee_surcharge),
    limitOrderFee: contract.limit_order_fee
      ? new Collateral(contract.limit_order_fee)
      : undefined,
    type: status.market_type,
    collateralToken: status.collateral,
    collateral:
      status.market_type === "collateral_is_base"
        ? display.base
        : display.quote,
    notional:
      status.market_type === "collateral_is_base"
        ? display.quote
        : display.base,
    liquidityTokenLpAddress: info.liquidity_token_lp,
    liquidityTokenXlpAddress: info.liquidity_token_xlp,
    marketAddress: info.market_addr,
    positionTokenAddress: info.position_token,
    // taken from MarketConfigResp so that the frontend can decide to use mock or manual oracles for dev purposes
    // in the typical case (i.e. without overrides), this will just be
    // the contract SpotPriceConfig passed through untouched
    // TODO: maybe map here so we get feedsUsd instead of feeds_usd
    spotPrice,
    inWinddown,
  }
}
