import { ChainProvider, type ChainProviderProps } from "@levana/chain/provider"
import { WalletErrorType, getWalletErrorType } from "@levana/chain/error"
import { setUser as SentrySetUser } from "@sentry/react"
import { useMemo, type PropsWithChildren } from "react"
import i18next from "i18next"

import { Report } from "@future/libs/error/report"
import { extractFailureReason } from "@future/libs/error/utils"
import { appDescription, appTitle, appUrl } from "@perps/constants"
import { Event } from "@perps/analytics/events"
import { track } from "@perps/analytics/track"
import { notification } from "@future/notification"
import { AppError } from "@future/libs/error/AppError"

import { chainInfos } from "./utils"
import { walletErrorToKeyOptions } from "./error"

/**
 * Chain Container
 *
 * Currently there is a bug of setting a state while rendering in GrazProvider
 * with the GrazEvents component. This will happen when the GrazProvider
 * rerenders. To prevent it, this component should be called above the router.
 */
const ChainContainer = (props: PropsWithChildren) => {
  const grazOptions = useMemo<ChainProviderProps["grazOptions"]>(() => {
    return {
      /**
       * All chains need to be supported up front for proper multi-chain
       * support. For instance, connecting to Osmosis, closing the tab and
       * opening a new tab on Neutron.
       */
      chains: chainInfos,
      chainsConfig: chainInfos.reduce(
        (chainsConfig, chainInfo) => {
          const feeCurrencie = chainInfo.feeCurrencies.at(0)

          if (feeCurrencie?.gasPriceStep) {
            chainsConfig[chainInfo.chainId] = {
              gas: {
                price: feeCurrencie.gasPriceStep.low.toString(),
                denom: feeCurrencie.coinMinimalDenom,
              },
            }
          }

          return chainsConfig
        },
        {} as NonNullable<ChainProviderProps["grazOptions"]["chainsConfig"]>,
      ),
      ...(process.env.WALLET_CONNECT_ID && {
        walletConnect: {
          options: {
            projectId: process.env.WALLET_CONNECT_ID,
            metadata: {
              name: appTitle,
              description: appDescription,
              url: appUrl,
              icons: [
                "https://static.levana.finance/icons/levana-silhouette-round.svg",
              ],
            },
          },
        },
      }),
    }
  }, [])

  return (
    <ChainProvider
      grazOptions={grazOptions}
      onConnected={handleConnected}
      onDisconnected={handleDisconnected}
      onConnectError={handleError}
    >
      {props.children}
    </ChainProvider>
  )
}

const identifyUser = (walletAddress: string | null | undefined) => {
  if (walletAddress) {
    SentrySetUser({ id: walletAddress })
    gtag("event", "login", { address: walletAddress })
  } else {
    SentrySetUser(null)
  }
}

const handleConnected: ChainProviderProps["onConnected"] = (connected) => {
  identifyUser(connected.account.bech32Address)

  Report.setTag("connector.id", connected.walletType)

  Report.addBreadcrumb({
    level: "info",
    type: "user",
    category: "wallet",
    message: "Wallet connected",
    data: { address: connected.account.bech32Address },
  })

  track(
    Event.connectWallet.succeeded({
      wallet: connected.walletType,
    }),
  )
}

const handleDisconnected = () => {
  identifyUser(null)

  Report.addBreadcrumb({
    level: "info",
    type: "user",
    category: "wallet",
    message: "Wallet disconnected",
  })
}

const handleError: ChainProviderProps["onConnectError"] = (error) => {
  const errorType = getWalletErrorType(error.message)

  if (errorType === WalletErrorType.rejected) {
    Report.addBreadcrumb({
      level: "info",
      type: "user",
      category: "wallet",
      message: "Wallet rejected",
    })
  }

  const errorOptions = walletErrorToKeyOptions(errorType) ?? {
    key: "wallet.connectingFailed",
  }

  const appError = AppError.fromError(error, errorOptions)

  if (appError.level === "warning") {
    const t = i18next.getFixedT(null, "error")
    notification.warning(appError.toString(t))
  } else {
    notification.error(appError)
  }

  if (!appError.disablePresentation) {
    track(Event.connectWallet.failed({}, extractFailureReason(appError)))
  }
}

export default ChainContainer
