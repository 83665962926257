var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getChain } from "./chain";
import { truncateAddress, WalletType } from "./wallet";
import { SigningClientType } from "./signing";
import { objectToUint8Array, uint8ArrayToObject } from "./utils";
export const serializeAccount = (account) => {
    return Object.assign(Object.assign({}, account), { pubKey: uint8ArrayToObject(account.pubKey), address: uint8ArrayToObject(account.address) });
};
export const deserializeAccount = (account) => {
    return Object.assign(Object.assign({}, account), { pubKey: objectToUint8Array(account.pubKey), address: objectToUint8Array(account.address) });
};
export const mutateAccounts = (accounts, handler) => {
    return Object.fromEntries(Object.entries(accounts).map(([key, account]) => [key, handler(account)]));
};
export const getConnectResult = (args, chainId, getSigning) => __awaiter(void 0, void 0, void 0, function* () {
    const { chain, account, walletType } = (() => {
        if ("walletAddress" in args && args.walletType === WalletType.viewing) {
            return {
                chain: getChain(chainId),
                account: createViewingAccount(args.walletAddress),
                walletType: args.walletType,
            };
        }
        return {
            chain: getChain(chainId),
            account: args.account,
            walletType: args.walletType,
        };
    })();
    const formatedAccount = (() => {
        if (account.address instanceof Uint8Array &&
            account.pubKey instanceof Uint8Array) {
            return account;
        }
        if (typeof account.address === "object" &&
            typeof account.pubKey === "object") {
            // This is needed for graz since storing the Uint8Array class inside the
            // zustand store will serialize it to an object. An example of how to fix
            // this can be seen with the `useInjectiveSessionStore`.
            return deserializeAccount(account);
        }
        return account;
    })();
    const signing = walletType === WalletType.viewing
        ? { type: SigningClientType.viewing }
        : yield getSigning();
    return {
        account: formatedAccount,
        walletType,
        chain,
        signing,
        truncateAddress: (startIndex, endIndex) => {
            return truncateAddress(account.bech32Address, startIndex, endIndex);
        },
        truncateEthereumAddress: (startIndex, endIndex) => {
            if (account.ethereumHexAddress) {
                return truncateAddress(account.ethereumHexAddress.toLowerCase(), startIndex, endIndex);
            }
        },
    };
});
export const createViewingAccount = (walletAddress) => {
    return {
        name: "Viewing",
        algo: "",
        pubKey: new Uint8Array(0),
        address: new Uint8Array(0),
        bech32Address: walletAddress,
        ethereumHexAddress: "",
        isNanoLedger: false,
        isKeystone: false,
    };
};
