import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const StatsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M11.6719 4.50007C11.6719 4.08585 12.0083 3.74668 12.421 3.78265C13.8626 3.90832 15.2538 4.39522 16.4637 5.20364C17.882 6.15137 18.9875 7.49841 19.6403 9.07442C20.2931 10.6504 20.4639 12.3846 20.1311 14.0577C19.8473 15.4849 19.2078 16.8129 18.2773 17.9211C18.011 18.2384 17.5332 18.2364 17.2403 17.9435L12.9899 13.6931C12.146 12.8492 11.6719 11.7046 11.6719 10.5111V4.50007Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1719 4.79249C10.1719 4.31724 9.74142 3.95539 9.28473 4.08693C8.70493 4.25392 8.14214 4.4819 7.60608 4.76843C6.35517 5.43705 5.28847 6.40386 4.50045 7.58321C3.71244 8.76255 3.22744 10.118 3.08841 11.5296C2.94938 12.9412 3.16062 14.3652 3.70342 15.6756C4.24621 16.9861 5.1038 18.1424 6.20024 19.0422C7.29667 19.942 8.59809 20.5576 9.98922 20.8343C11.3804 21.111 12.8183 21.0403 14.1756 20.6286C14.7572 20.4522 15.3164 20.2154 15.8445 19.9235C16.2604 19.6936 16.3089 19.1334 15.9729 18.7973C15.7182 18.5427 15.3224 18.5044 15.0041 18.6728C14.6019 18.8856 14.1788 19.0602 13.7402 19.1932C12.6189 19.5333 11.4311 19.5917 10.2819 19.3631C9.13266 19.1345 8.05757 18.626 7.15183 17.8827C6.24608 17.1394 5.53763 16.1841 5.08924 15.1016C4.64084 14.0191 4.46634 12.8427 4.58119 11.6766C4.69603 10.5106 5.09669 9.39081 5.74766 8.41656C6.39863 7.44232 7.27982 6.64365 8.31318 6.09131C8.71739 5.87526 9.14 5.6995 9.57489 5.56557C9.91903 5.4596 10.1719 5.15258 10.1719 4.79249ZM11.6719 3.75V10.511L11.6719 5.25L11.6719 3.75Z"
      />
    </SvgIcon>
  )
}

export default StatsIcon
