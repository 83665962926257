import type HCaptcha from "@hcaptcha/react-hcaptcha"

import type { FormCaptchaProps } from "."

type HCaptchaProps = Required<ConstructorParameters<typeof HCaptcha>[0]>

export const useController = (props: FormCaptchaProps) => {
  const { state } = props

  const handleVerify: HCaptchaProps["onVerify"] = async (token) => {
    state.setValue(token)
  }

  const handleExpire: HCaptchaProps["onExpire"] = async () => {
    state.setValue("")
  }

  return {
    captcha: {
      onVerify: handleVerify,
      onExpire: handleExpire,
    },
  }
}
