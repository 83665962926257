import type { ChainInfo, Currency } from "@keplr-wallet/types"
import { Bech32Address } from "@keplr-wallet/cosmos"

import type { ChainNetworkConfig } from "@common/target/types"

import type { ChainConfig } from "../chain"

export const createChainInfo = (
  config: ChainConfig | ChainNetworkConfig,
): ChainInfo => {
  const currency: Currency = {
    coinDenom: config.asset.symbol,
    coinMinimalDenom: config.asset.base,
    coinDecimals: 6,
  }

  return {
    chainId: config.chainId,
    chainName: config.chainPrettyName,
    rpc: config.rpcUrl,
    rest: config.restUrl,
    bip44: {
      coinType: 118,
    },
    bech32Config: Bech32Address.defaultBech32Config(config.addressPrefix),
    currencies: [currency],
    feeCurrencies: [
      {
        ...currency,
        gasPriceStep: {
          low: Number(config.lowGasPrice),
          average: Number(config.averageGasPrice),
          high: Number(config.highGasPrice),
        },
      },
    ],
    stakeCurrency: currency,
  }
}
