export var ErrorMessage;
(function (ErrorMessage) {
    ErrorMessage["noAccount"] = "The wallet does not have an account";
})(ErrorMessage || (ErrorMessage = {}));
export var WalletErrorType;
(function (WalletErrorType) {
    WalletErrorType["noAccount"] = "noAccount";
    WalletErrorType["outOfSyncAccount"] = "outOfSyncAccount";
    WalletErrorType["insufficientFunds"] = "insufficientFunds";
    WalletErrorType["invalidCurrencies"] = "invalidCurrencies";
    WalletErrorType["rejected"] = "rejected";
    WalletErrorType["unknown"] = "unknown";
})(WalletErrorType || (WalletErrorType = {}));
