import Button from "@mui/joy/Button"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"

import { useBannerContext } from "@perps/banner/BannerContext"
import {
  useCanUpdateVersion,
  useUpdateVersionOnAppLoad,
} from "@future/update/updateVersion"
import { updateConfigQueryOptions } from "@future/network/updateConfig"

const UpdatesBanner = () => {
  const bannerContext = useBannerContext()
  const { show, hide } = bannerContext.system
  const { t } = useTranslation("common")

  const updateConfigResult = useQuery(updateConfigQueryOptions())

  const { canUpdate, updateStoredVersion } = useCanUpdateVersion({
    updateConfigCommitHashShort: updateConfigResult.data?.commitHashShort,
  })

  useUpdateVersionOnAppLoad(
    updateConfigResult.data?.commitHashShort !== undefined,
    updateStoredVersion,
  )

  useEffect(() => {
    if (canUpdate) {
      const key = "update.version.banner"

      show(
        key,
        t("banner.update.version.title"),
        <Button
          size="sm"
          onClick={() => {
            updateStoredVersion()
            window.location.reload()
          }}
          aria-label="banner update"
        >
          {t("banner.update.version.button")}
        </Button>,
      )
      return () => hide(key)
    }
  }, [show, hide, canUpdate, updateStoredVersion, t])

  return null
}

export default UpdatesBanner
