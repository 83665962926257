import { toast as controller } from "react-hot-toast"
import i18next from "i18next"

import type { AppError } from "@future/libs/error/AppError"
import { Report } from "@future/libs/error/report"
import { track } from "@perps/analytics/track"
import { Event } from "@perps/analytics/events"
import { EventKey } from "@perps/analytics/constants"

import ErrorNotification from "./ErrorNotification"
import SuccessNotification from "./SuccessNotification"
import WarningNotification from "./WarningNotification"
import type { NotificationOptions } from "./utils"

const success = (message: string, options?: NotificationOptions) => {
  controller.custom(
    (toast) => (
      <SuccessNotification toast={toast} message={message} {...options} />
    ),
    { id: message, duration: 5 * 1000 },
  )
}

const warning = (message: string, options?: NotificationOptions) => {
  controller.custom(
    (toast) => (
      <WarningNotification toast={toast} message={message} {...options} />
    ),
    { id: message, duration: 5 * 1000 },
  )
}

const error = (error: AppError) => {
  const t = i18next.getFixedT(null, "error")

  const message = error.presentationError.toString(t)

  track(
    Event.errorOccurred({
      [EventKey.MESSAGE_TO_USER]: message,
      [EventKey.RAW_ERROR_MESSAGE]: error.toContexts(t).join("\n"),
      ...(error.presentationError.context.messageVariant === "key" && {
        [EventKey.FRONTEND_ERROR_ID]: error.presentationError.context.message,
      }),
      ...error.analytics,
    }),
  )

  Report.error(error, t)

  if (error.disablePresentation) {
    return
  }

  controller.custom(
    (toast) => (
      <ErrorNotification toast={toast} message={message} error={error} />
    ),
    { id: message, duration: 30 * 1000 },
  )
}

const transactionSuccess = (options?: NotificationOptions) => {
  const t = i18next.getFixedT(null, "common")
  const message = t("notification.transactionSuccess.message")

  controller.custom(
    (toast) => (
      <SuccessNotification toast={toast} message={message} {...options} />
    ),
    { id: message, duration: 5 * 1000 },
  )
}

export const notification = {
  success,
  warning,
  error,
  transactionSuccess,
}
