import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const DaoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M20.6571 6.73857L12.285 3.0433C12.1118 2.98557 11.9386 2.98557 11.7654 3.0433L3.39331 6.73857C3.16235 6.85405 3.04688 7.085 3.04688 7.31596V8.93264C3.04688 9.27907 3.33557 9.56776 3.682 9.56776H20.4262C20.7726 9.56776 21.0613 9.27907 21.0613 8.93264V7.31596C21.0613 7.085 20.8881 6.85405 20.6571 6.73857ZM12.0541 7.95108C11.188 7.95108 10.4952 7.25822 10.4952 6.39214C10.4952 5.52606 11.188 4.8332 12.0541 4.8332C12.9202 4.8332 13.613 5.52606 13.613 6.39214C13.613 7.31596 12.9202 7.95108 12.0541 7.95108Z" />
      <path d="M19.5601 18.6904H4.54808C3.73974 18.6904 3.04688 19.3833 3.04688 20.1916V20.7113C3.04688 20.8845 3.16235 21 3.33557 21H20.7726C20.8881 21 21.0613 20.8845 21.0613 20.7113V20.1916C21.0613 19.3833 20.3684 18.6904 19.5601 18.6904Z" />
      <rect x="5.04688" y="10" width="2" height="8.1" />
      <rect x="9.04688" y="10" width="2" height="8.1" />
      <rect x="13.0469" y="10" width="2" height="8.1" />
      <rect x="17.0469" y="10" width="2" height="8.1" />
    </SvgIcon>
  )
}

export default DaoIcon
