import type { JSONValue } from "@common/json/types"

import { type BrowserLogLevel, useDebugStore } from "./store"

export interface ConsoleLog {
  level: "log" | "info" | "warn" | "error" | "debug"
  items: JSONValue[]
}

export const initConsole = () => {
  const initialLog = console.log.bind(console)
  console.log = (...args) => {
    useDebugStore.getState().addConsoleLog({
      level: "log",
      items: Array.from(args),
    })
    if (isBrowserLogLevelMatching(["debug"])) {
      initialLog.apply(console, args)
    }
  }

  const initialInfo = console.log.bind(console)
  console.info = (...args) => {
    useDebugStore.getState().addConsoleLog({
      level: "info",
      items: Array.from(args),
    })
    if (isBrowserLogLevelMatching(["debug"])) {
      initialInfo.apply(console, args)
    }
  }

  const initialWarn = console.warn.bind(console)
  console.warn = (...args) => {
    useDebugStore.getState().addConsoleLog({
      level: "warn",
      items: Array.from(args),
    })
    if (isBrowserLogLevelMatching(["debug", "warning"])) {
      initialWarn.apply(console, args)
    }
  }

  const initialError = console.error.bind(console)
  console.error = (...args) => {
    useDebugStore.getState().addConsoleLog({
      level: "error",
      items: Array.from(args),
    })
    if (isBrowserLogLevelMatching(["debug", "warning", "error"])) {
      initialError.apply(console, args)
    }
  }

  const initialDebug = console.debug.bind(console)
  console.debug = (...args) => {
    useDebugStore.getState().addConsoleLog({
      level: "debug",
      items: Array.from(args),
    })
    if (isBrowserLogLevelMatching(["debug"])) {
      initialDebug.apply(console, args)
    }
  }
}

const isBrowserLogLevelMatching = (
  matchingLevels: BrowserLogLevel[],
): boolean => {
  const { browserLogLevel } = useDebugStore.getState()
  return matchingLevels.includes(browserLogLevel)
}
