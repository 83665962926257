import { useQuery } from "@tanstack/react-query"
import { useStore } from "zustand"

import { queryQuerier } from "@perps/sdk/client/query"
import { milliToNano } from "@perps/sdk/utils"
import { Amount } from "@future/numerics/amount"
import type { ContextStoreProp } from "@future/context/store"
import type { ChainNetworkId } from "@common/target/types"

interface GovGlobalInfoResponse {
  global_info: {
    config: {
      denom: string
      title_range: Range
      constraints: {
        quorum: string
        threshold: string
        execution_window: number
        description_range: Range
        duration_range: Range
      }
    }
    buckets: {
      multiplier: string
      duration: string
    }[]
    lvn: {
      locked: string
      staked: string
      unstaking: string
      vested: string
      vested_reward_power: string
      vested_voting_power: string
    }
    voting_power: ResponsePower
    reward_power: ResponsePower
  }
}

interface ResponsePower {
  staked_plus_vested: string
  locked: string
}

interface GovGlobalInfo {
  lockedLvn: Amount
}

export const useGovGlobalInfo = (props: ContextStoreProp<"standard">) => {
  const chainNetworkId = useStore(
    props.contextStore,
    (state): ChainNetworkId => {
      return state.chain.config.network === "mainnet"
        ? "osmosis-mainnet"
        : "osmosis-testnet"
    },
  )
  const contractAddress = useStore(props.contextStore, (state) => {
    return state.chain.config.network === "mainnet"
      ? "osmo1r4zttdjswycfcmsm7vxw60kvn6ejw40yld6cspedy5daltml0zxqhpg5xn"
      : "osmo1cu5extksk3ut9vt0jlelyg0z4k06zh7z0r4vntn4l30xjgf8k65sv5ycwt"
  })

  return useQuery({
    queryKey: ["govGlobalInfo", chainNetworkId, contractAddress],
    queryFn: async (context) => {
      const globalInfo = await queryQuerier<GovGlobalInfoResponse>(
        "/v1/governance/global-info",
        "Gov global info",
        context.signal,
        {
          network: chainNetworkId,
          contract: contractAddress,
          at: milliToNano(Date.now()).toString(),
        },
      )

      return globalInfo
    },
    select: (data): GovGlobalInfo => {
      const ulvn = new Amount(data.global_info.lvn.staked)
        .plus(data.global_info.lvn.locked)
        .plus(data.global_info.lvn.vested)
        .plus(data.global_info.lvn.unstaking)

      return {
        lockedLvn: Amount.denominatedToNominated(ulvn, 6),
      }
    },
  })
}
